import humanImg from "../../assets/images/shape-human.svg";
import shape from "../../assets/images/shape-prism.png";
import hand from "../../assets/images/shape-hand.png";
import cube from "../../assets/images/shape-cube.png";

export default function Testimonials() {
  return (
    <section
      className="lqd-section testimonials bg-tarnsparent pb-50 pt-50 lg:pt-0"
      id="testimonials"
      style={{ backgroundImage: "linear-gradient(180deg, #FFEEDE 50%, #fff 100%)" }}
    >
      <div className="lqd-shape lqd-shape-top" data-negative="false">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1000 100"
          preserveAspectRatio="none"
        >
          <path
            className="lqd-shape-fill"
            d="M738,99l262-93V0H0v5.6L738,99z"
            fill="#fff"
          />
        </svg>
      </div>
      <div className="container module-slider">
        <div className="row">
          <div className="col col-12 text-center">
            <div className="carousel-container relative carousel-nav-center carousel-nav-lg carousel-nav-circle carousel-nav-center carousel-nav-floated carousel-nav-middle carousel-nav-middlecarousel-dots-mobile-outside carousel-dots-mobile-center">
              <div
                className="carousel-items relative"
                data-lqd-flickity='{"prevNextButtons": true, "wrapAround": true, "groupCells": true, "cellAlign": "left", "buttonsAppendTo": "self", "addSlideNumbersToArrows": false, "pageDots": false}'
              >
                <div className="flickity-viewport relative w-full overflow-hidden">
                  <div className="flickity-slider flex w-full h-full relative items-start">
                    <div className="carousel-item flex flex-col justify-center">
                      <div className="carousel-item-inner relative w-full">
                        <div className="text-20 font-medium leading-20 text-center carousel-item-content relative w-full">
                          <img
                            src="/images/testi-img.png"
                            alt="testimonial"
                            width={100}
                            height={121}
                          />
                          <p className="mt-2em text-black">
                            Love the easy and beautiful designed page builder and
                            the documentation. All in one landing and startup
                            solutions endless use-cases that make it highly.
                          </p>
                          <h6 className="text-purple-400 text-13">
                            Kate Roben, Game Designer at LA Studio
                          </h6>
                        </div>
                      </div>
                    </div>
                    <div className="carousel-item flex flex-col justify-center">
                      <div className="carousel-item-inner relative w-full">
                        <div className="text-20 font-medium leading-20 text-center carousel-item-content relative w-full">
                          <img
                            src="/images/testi-img.png"
                            alt="testimonial"
                            width={100}
                            height={121}
                          />
                          <p className="mt-2em text-black">
                            I think Hub is the best theme I ever seen this year.
                            Amazing design, easy to customize and a design quality
                            superlative account on its cloud platform for the
                            optimized performance
                          </p>
                          <h6 className="text-purple-400 text-13">
                            Marry Roben, Product Designer
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="absolute w-auto top-0 sm:hidden module-shape-1">
        <div className="lqd-imggrp-single block relative" data-float="ease">
          <div className="lqd-imggrp-img-container inline-flex relative items-center justify-center">
            <figure className="w-full relative">
              <img
                width={250}
                height={276}
                src="/images/shape-human.svg"
                alt="3D shape human"
              />
            </figure>
          </div>
        </div>
      </div>
      <div
        className="absolute w-100 top-50percent sm:hidden module-shape-2"
        data-parallax="true"
        data-parallax-options='{"ease":["linear"],"start":"top bottom","end":"top center"}'
        data-parallax-from='{"x":"-152px","y":"-213px","rotationZ":-200}'
        data-parallax-to='{"x":"0px","y":"0px","rotationZ":50}'
      >
        <div className="lqd-imggrp-single block relative">
          <div className="lqd-imggrp-img-container inline-flex relative items-center justify-center">
            <figure className="w-full relative">
              <img
                width={100}
                height={424}
                src={shape}
                alt="3D shape"
              />
            </figure>
          </div>
        </div>
      </div>
      <div
        className="absolute w-70 top-60percent sm:hidden module-shape-3"
        data-parallax="true"
        data-parallax-options='{"ease":["linear"],"start":"top bottom","end":"top center"}'
        data-parallax-from='{"x":"140px","y":"73px"}'
        data-parallax-to='{"x":"0px","y":"0px"}'
      >
        <div className="lqd-imggrp-single block relative" data-float="ease">
          <div className="lqd-imggrp-img-container inline-flex relative items-center justify-center">
            <figure className="w-full relative">
              <img
                width={70}
                height={70}
                src={hand}
                alt="3D shape"
              />
            </figure>
          </div>
        </div>
      </div>
      <div
        className="absolute w-100 top-60 ltr-right-0 sm:hidden module-shape-4"
        data-parallax="true"
        data-parallax-options='{"ease":["linear"],"start":"top bottom","end":"top center"}'
        data-parallax-from='{"x":"116px","y":"-220px","rotationZ":132}'
        data-parallax-to='{"x":"0px","y":"159px","rotationZ":0}'
      >
        <div className="lqd-imggrp-single block relative">
          <div className="lqd-imggrp-img-container inline-flex relative items-center justify-center">
            <figure className="w-full relative">
              <img
                width={100}
                height={100}
                src={cube}
                alt="3D shape"
              />
            </figure>
          </div>
        </div>
      </div>
      <section className="lqd-section w-full mt-100 lg:hidden">
        <div className="container">
          <div className="row items-center text-center">
            <div className="col col-2">
              <div className="lqd-imggrp-single block relative">
                <div className="lqd-imggrp-img-container inline-flex relative items-center justify-center">
                  <figure className="w-full relative">
                    <img
                      width={111}
                      height={33}
                      src="/images/client-spotify-3.svg"
                      alt="client"
                    />
                  </figure>
                </div>
              </div>
            </div>
            <div className="col col-2">
              <div className="lqd-imggrp-single block relative">
                <div className="lqd-imggrp-img-container inline-flex relative items-center justify-center">
                  <figure className="w-full relative">
                    <img
                      width={65}
                      height={23}
                      src="/images/client-nike-2.svg"
                      alt="client"
                    />
                  </figure>
                </div>
              </div>
            </div>
            <div className="col col-2">
              <div className="lqd-imggrp-single block relative">
                <div className="lqd-imggrp-img-container inline-flex relative items-center justify-center">
                  <figure className="w-full relative">
                    <img
                      width={75}
                      height={17}
                      src="/images/client-amd-logo-2.svg"
                      alt="client"
                    />
                  </figure>
                </div>
              </div>
            </div>
            <div className="col col-2">
              <div className="lqd-imggrp-single block relative">
                <div className="lqd-imggrp-img-container inline-flex relative items-center justify-center">
                  <figure className="w-full relative">
                    <img
                      width={74}
                      height={59}
                      src="/images/client-apper.svg"
                      alt="client"
                    />
                  </figure>
                </div>
              </div>
            </div>
            <div className="col col-2">
              <div className="lqd-imggrp-single block relative">
                <div className="lqd-imggrp-img-container inline-flex relative items-center justify-center">
                  <figure className="w-full relative">
                    <img
                      width={85}
                      height={25}
                      src="/images/client-logitech-2.svg"
                      alt="client"
                    />
                  </figure>
                </div>
              </div>
            </div>
            <div className="col col-2">
              <div className="lqd-imggrp-single block relative">
                <div className="lqd-imggrp-img-container inline-flex relative items-center justify-center">
                  <figure className="w-full relative">
                    <img
                      width={74}
                      height={36}
                      src="/images/client-levis.svg"
                      alt="client"
                    />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
  );
}
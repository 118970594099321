export default function Subscribe() {
  return (
    <section
      className="lqd-section subscribe transition-all bg-transparent pb-120"
      style={{ backgroundImage: "linear-gradient(180deg, #DBE4FE 0%, #fff 100%)" }}
    >
      <div className="lqd-shape lqd-shape-top" data-negative="false">
        <svg
          className="h-60"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1000 100"
          preserveAspectRatio="none"
        >
          <path
            className="lqd-shape-fill"
            d="M738,99l262-93V0H0v5.6L738,99z"
            fill="#fff"
          />
        </svg>
      </div>
      <div className="container">
        <div className="row">
          <div
            className="col col-12 p-0"
            data-parallax="true"
            data-parallax-options='{"start": "top bottom", "end": "bottom+=0px top"}'
            data-parallax-from='{"y": "100px", "scaleX" : 1, "scaleY" : 1}'
            data-parallax-to='{"y": "-100px", "scaleX" : 1.1, "scaleY" : 1.1}'
          >
            <div
              className="py-40 px-70 flex flex-wrap transition-all box-shadow-bottom w-full bg-no-repeat bg-center bg-white bg-contain relative rounded-10 rounded-bottom-0 module-section"
              style={{
                backgroundImage:
                  'url("/images/subscribe/shape.svg")'
              }}
            >
              <div className="w-60percent relative flex p-10 sm:w-full">
                <p className="text-18 text-accent ld-fh-element mb-0/5em inline-block relative">
                From Zero to Sales Hero: Transform Your E- commerce and Social Media Game. Ready to Go Viral? Your First Step Starts Here{" "}
                </p>
              </div>
              <div className="w-40percent relative flex justify-end items-center p-10 sm:w-full sm:justify-center">
                <a
                  href="#contact-modal"
                  className="btn btn-solid btn-md btn-icon-right btn-hover-swp text-15 rounded-4 bg-primary text-white sm:w-full"
                  data-lity="#contact-modal"
                >
                  <span className="btn-txt" data-text="Sign up free">
                    Get Started
                  </span>
                  <span className="btn-icon text-1em">
                    <i
                      aria-hidden="true"
                      className="lqd-icn-ess icon-md-arrow-forward"
                    />
                  </span>
                  <span className="btn-icon mr-10 text-1em">
                    <i
                      aria-hidden="true"
                      className="lqd-icn-ess icon-md-arrow-forward"
                    />
                  </span>
                </a>
              </div>
            </div>
            <div className="w-full relative flex items-center mb-0 module-lines">
              <div className="divider w-25percent py-15 relative">
                <span className="divider-separator w-full flex m-0" />
              </div>
              <div className="divider w-45percent py-15 relative">
                <span className="divider-separator w-full flex m-0" />
              </div>
              <div className="divider w-20percent py-15 relative">
                <span className="divider-separator w-full flex m-0" />
              </div>
              <div className="divider w-10percent py-15 relative">
                <span className="divider-separator w-full flex m-0" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  )
}
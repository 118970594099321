import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom';

import './assets/css/lqd-essentials/lqd-essentials.css';
import './assets/css/themes.css';
import './assets/css/utility.css';
import './assets/css/base.css';
import './assets/css/start-hub-3.css';
import About from './abou';
import PrivacyPolicy from "./components/Policy/PrivacyPolicy"
import TermsOfService from './components/Policy/TermsOfService';
import RefundPolicy from './components/Policy/RefundPolicy';
import Header from './components/layout/header';
import Footer from './components/layout/footer';
import CustomCursor from './components/layout/customCursor';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <>
    <Header/>
    <Router>
        <Routes>
            <Route path="/" element={<App />} />
            <Route path="/about" element={<About />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/refund-policy" element={<RefundPolicy />} />
            <Route path="/terms-of-service" element={<TermsOfService />} />
        </Routes>
    </Router>
    <CustomCursor />
    <Footer/>
    </>
);

import ballons from "../../assets/images/shape-ballon.png";
export default function Pricing() {
  return (
    <section
      className="lqd-section pricing bg-tarnsparent transition-all pt-100 pb-120"
      id="pricing"
      style={{ backgroundImage: "linear-gradient(180deg, #DBFEF1 0%, #fff 25%)" }}
    >
      <div className="lqd-shape lqd-shape-top" data-negative="false">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1000 100"
          preserveAspectRatio="none"
        >
          <path
            className="lqd-shape-fill"
            d="M738,99l262-93V0H0v5.6L738,99z"
            fill="#fff"
          />
        </svg>
      </div>
      <div className="flex flex-col relative w-full">
        <div
          className="absolute w-auto -top-100 sm:hidden module-shape-1"
          data-parallax="true"
          data-parallax-options='{"start": "top bottom", "end": "bottom+=0px top"}'
          data-parallax-from='{"x": "-40px", "y": "-50px", "rotationZ" : 0}'
          data-parallax-to='{"x": "0px", "y": "20px", "rotationZ" : 360}'
        >
          <div className="lqd-imggrp-single block relative">
            <div className="lqd-imggrp-img-container inline-flex relative items-center justify-center">
              <figure className="w-full relative">
                <img
                  width={95}
                  height={95}
                  src="/images/shape-Image-760.svg"
                  alt="3D shape"
                />
              </figure>
            </div>
          </div>
        </div>
        <div
          className="absolute w-45 bottom-0 lg:hidden module-shape-2"
          data-parallax="true"
          data-parallax-options='{"start": "top bottom", "end": "bottom+=0px top"}'
          data-parallax-from='{"y": "0px"}'
          data-parallax-to='{"y": "-1000px"}'
        >
          <div className="lqd-imggrp-single block relative">
            <div className="lqd-imggrp-img-container inline-flex relative items-center justify-center">
              <figure className="w-full relative">
                <img
                  width={86}
                  height={558}
                  src={ballons}
                  alt="ballon 3D shape"
                />
              </figure>
            </div>
          </div>
        </div>
        <div className="mb-50">
          <div className="container">
            <div className="row">
              <div className="w-10percent lg:w-15percent sm:w-full text-start">
                <div className="iconbox flex flex-grow-1 relative flex-wrap items-center iconbox-inline iconbox-icon-shaped iconbox-circle">
                  <div className="iconbox-icon-wrap">
                    <div className="iconbox-icon-container inline-flex relative z-1 rounded-full">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        id="Credit-card"
                        width={49}
                        height={49}
                        viewBox="0 0 49 49"
                      >
                        <rect id="bound-1" width={49} height={49} fill="none" />
                        <rect
                          id="Combined_Shape-27"
                          data-name="Combined Shape"
                          width={41}
                          height={27}
                          rx={2}
                          transform="translate(4 11)"
                          fill="#00cb99"
                          opacity="0.3"
                        />
                        <rect
                          id="Rectangle_59"
                          data-name="Rectangle 59"
                          width={41}
                          height={7}
                          transform="translate(4 16)"
                          fill="#00cb99"
                        />
                        <rect
                          id="Rectangle_59_Copy"
                          data-name="Rectangle 59 Copy"
                          width={8}
                          height={4}
                          rx={1}
                          transform="translate(33 29)"
                          fill="#00cb99"
                          opacity="0.3"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-90percent lg:w-85percent sm:w-full">
                <div className="ld-fancy-heading relative">
                  <h2 className="text-44 mb-10 ld-fh-element relative">
                    Simple Pricing
                  </h2>
                </div>
                <div className="ld-fancy-heading relative">
                  <p className="text-18 ld-fh-element mb-0/5em inline-block relative">
                    Use customer data to build great and solid product experiences
                    that convert.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container relative">
          <div className="row">
            <div className="col col-12">
              <div className="absolute w-90 -top-170 -right-100 module-save lg:hidden">
                <div className="lqd-imggrp-single block relative" data-float="ease">
                  <div className="lqd-imggrp-img-container inline-flex relative items-center justify-center">
                    <figure className="w-full relative">
                      <img
                        width={174}
                        height={111}
                        src="/images/shape-save@x21.png"
                        alt="save 30%"
                      />
                    </figure>
                  </div>
                </div>
              </div>
              <div
                className="-mt-130 lqd-tabs lqd-tabs-style-10 lqd-tabs-nav-items-not-expanded lqd-nav-underline-"
                data-tabs-options='{"trigger": "click"}'
              >
                <nav className="lqd-tabs-nav-wrap mb-2/25em">
                  <ul
                    className="link-15 reset-ul lqd-tabs-nav flex flex-wrap items-end justify-end relative"
                    role="tablist"
                  >
                    <li
                      data-controls="lqd-tab-price-monthly"
                      role="presentation"
                      className="font-medium"
                    >
                      <a
                        className="flex items-center active"
                        href="#lqd-tab-price-monthly"
                        aria-expanded="true"
                        aria-controls="lqd-tab-price-monthly"
                        role="tab"
                        data-bs-toggle="tab"
                      >
                        <span className="lqd-tabs-nav-txt">Monthly</span>
                      </a>
                    </li>
                    <li
                      data-controls="lqd-tab-price-annually"
                      role="presentation"
                      className="font-medium"
                    >
                      <a
                        className="flex items-center"
                        href="#lqd-tab-price-annually"
                        aria-expanded="false"
                        aria-controls="lqd-tab-price-annually"
                        role="tab"
                        data-bs-toggle="tab"
                      >
                        <span className="lqd-tabs-nav-txt">Annually</span>
                      </a>
                    </li>
                  </ul>
                </nav>
                <div className="pt-80 lqd-tabs-content relative">
                  <div
                    id="lqd-tab-price-monthly"
                    role="tabpanel"
                    className="lqd-tabs-pane fade active"
                    data-custom-animations="true"
                    data-ca-options='{"animationTarget": ".animation-element", "ease": "power4.out", "initValues": {"x": "35px", "opacity" : 0} , "animations": {"x": "0px", "opacity" : 1}}'
                  >
                    <div className="container animation-element">
                      <div className="row text-center">
                        <div className="col col-12 col-md-4 col-xl-3 p-0 lg:hidden animation-element">
                          <div className="flex flex-col py-50 px-20 text-start w-full">
                            <div className="lqd-imggrp-single block relative">
                              <div className="lqd-imggrp-img-container inline-flex relative items-center justify-center">
                                <figure className="w-full relative">
                                  <img
                                    width={272}
                                    height={192}
                                    src="/images/shape-rocket.svg"
                                    alt="rocket"
                                  />
                                </figure>
                              </div>
                            </div>
                            <div className="w-full mt-65">
                              <ul className="reset-ul icon-list-items">
                                <li className="flex items-center pb-15 icon-list-item">
                                  <span className="icon-list-icon flex">
                                    <svg
                                      className="mr-5 w-25 h-25"
                                      xmlns="http://www.w3.org/2000/svg"
                                      id="Hidden-1"
                                      width={25}
                                      height={25}
                                      viewBox="0 0 25 25"
                                    >
                                      <rect
                                        id="bound-2"
                                        width={25}
                                        height={25}
                                        fill="none"
                                      />
                                      <path
                                        id="Combined_Shape-1"
                                        data-name="Combined Shape"
                                        d="M.942,8.491C.625,8.491.308,8.477,0,8.45L8.45,0a19.584,19.584,0,0,1,1.867,2.242l0,.006A20.748,20.748,0,0,1,7.548,5.366C5.992,6.792,3.584,8.491.942,8.491Z"
                                        transform="translate(11.558 10.259)"
                                        fill="#737588"
                                      />
                                      <path
                                        id="Combined_Shape-2"
                                        data-name="Combined Shape"
                                        d="M2.632,9.868h0A11.464,11.464,0,0,1,0,6.25,11.459,11.459,0,0,1,2.131,3.125,10.232,10.232,0,0,1,5.034.977,9.8,9.8,0,0,1,9.375,0a7.251,7.251,0,0,1,2.609.515L9.375,3.125A3.129,3.129,0,0,0,6.25,6.25L2.633,9.868Z"
                                        transform="translate(3.125 6.25)"
                                        fill="#737588"
                                      />
                                      <rect
                                        id="Rectangle-3"
                                        width="19.792"
                                        height="2.083"
                                        transform="translate(5.313 19.203) rotate(-45)"
                                        fill="#737588"
                                        opacity="0.3"
                                      />
                                    </svg>
                                  </span>
                                  <span className="pl-15 icon-list-text">
                                    Unlimited Photos and Videos
                                  </span>
                                </li>
                                <li className="flex items-center pb-15 mt-15 icon-list-item">
                                  <span className="icon-list-icon flex">
                                    <svg
                                      className="mr-5 w-25 h-25"
                                      xmlns="http://www.w3.org/2000/svg"
                                      id="Chat_1"
                                      data-name="Chat#5"
                                      width={22}
                                      height={22}
                                      viewBox="0 0 22 22"
                                    >
                                      <rect
                                        id="bound-3"
                                        width={22}
                                        height={22}
                                        fill="none"
                                      />
                                      <path
                                        id="Combined_Shape-3"
                                        data-name="Combined Shape"
                                        d="M15.875,15.852l-2.1-2.1H3a3,3,0,0,1-3-3V3A3,3,0,0,1,3,0H14.417a3,3,0,0,1,3,3v7.75q0,.133-.011.264L17.439,11v4.2a.916.916,0,0,1-.923.918A.9.9,0,0,1,15.875,15.852Z"
                                        transform="translate(2.75 2.75)"
                                        fill="#737588"
                                        opacity="0.3"
                                      />
                                      <path
                                        id="Combined_Shape-4"
                                        data-name="Combined Shape"
                                        d="M10.616,3A1.447,1.447,0,0,1,9.231,1.5,1.448,1.448,0,0,1,10.616,0,1.447,1.447,0,0,1,12,1.5,1.447,1.447,0,0,1,10.616,3ZM6,3A1.447,1.447,0,0,1,4.616,1.5,1.447,1.447,0,0,1,6,0,1.448,1.448,0,0,1,7.385,1.5,1.447,1.447,0,0,1,6,3ZM1.385,3A1.447,1.447,0,0,1,0,1.5,1.448,1.448,0,0,1,1.385,0,1.447,1.447,0,0,1,2.769,1.5,1.447,1.447,0,0,1,1.385,3Z"
                                        transform="translate(5 8)"
                                        fill="#737588"
                                        opacity="0.3"
                                      />
                                    </svg>
                                  </span>
                                  <span className="pl-15 icon-list-text">
                                    99.9 Uptime Guarantee
                                  </span>
                                </li>
                                <li className="flex items-center pb-15 mt-15 icon-list-item">
                                  <span className="icon-list-icon flex">
                                    <svg
                                      className="mr-5 w-25 h-25"
                                      xmlns="http://www.w3.org/2000/svg"
                                      width={18}
                                      height="18.01"
                                      viewBox="0 0 18 18.01"
                                    >
                                      <g
                                        id="Outgoing-box-1"
                                        transform="translate(0 0.01)"
                                      >
                                        <rect
                                          id="bound-4"
                                          width={18}
                                          height={18}
                                          fill="none"
                                        />
                                        <path
                                          id="Combined_Shape-5"
                                          data-name="Combined Shape"
                                          d="M15,0V3a1.5,1.5,0,0,1-1.5,1.5H1.5A1.5,1.5,0,0,1,0,3V0H3.209l.408,1.224A1.5,1.5,0,0,0,5.041,2.25h5a1.5,1.5,0,0,0,1.342-.829L12.089,0Z"
                                          transform="translate(1.5 12.75)"
                                          fill="#737588"
                                        />
                                        <path
                                          id="Path-3"
                                          d="M0,5.25,2.523.765A1.5,1.5,0,0,1,3.83,0h6.5a1.5,1.5,0,0,1,1.307.765L14.156,5.25h-2.49a1.5,1.5,0,0,0-1.342.829L9.615,7.5h-5L4.211,6.276A1.5,1.5,0,0,0,2.788,5.25Z"
                                          transform="translate(1.922 6)"
                                          fill="#737588"
                                          opacity="0.3"
                                        />
                                        <path
                                          id="Shape-8"
                                          d="M2.5.09a.375.375,0,0,0-.617.286V1.843H.375A.375.375,0,0,0,0,2.218v.767a.375.375,0,0,0,.375.375H1.881V4.828a.375.375,0,0,0,.617.286L5.127,2.888a.375.375,0,0,0,0-.572Z"
                                          transform="translate(6.398 5.25) rotate(-90)"
                                          fill="#737588"
                                        />
                                      </g>
                                    </svg>
                                  </span>
                                  <span className="pl-15 icon-list-text">
                                    Automate All Contracts
                                  </span>
                                </li>
                                <li className="flex items-center pb-15 mt-15 icon-list-item">
                                  <span className="icon-list-icon flex">
                                    <svg
                                      className="mr-5 w-25 h-25"
                                      xmlns="http://www.w3.org/2000/svg"
                                      id="Hidden-2"
                                      width={25}
                                      height={25}
                                      viewBox="0 0 25 25"
                                    >
                                      <rect
                                        id="bound-5"
                                        width={25}
                                        height={25}
                                        fill="none"
                                      />
                                      <path
                                        id="Combined_Shape-6"
                                        data-name="Combined Shape"
                                        d="M.942,8.491C.625,8.491.308,8.477,0,8.45L8.45,0a19.584,19.584,0,0,1,1.867,2.242l0,.006A20.748,20.748,0,0,1,7.548,5.366C5.992,6.792,3.584,8.491.942,8.491Z"
                                        transform="translate(11.558 10.259)"
                                        fill="#737588"
                                      />
                                      <path
                                        id="Combined_Shape-7"
                                        data-name="Combined Shape"
                                        d="M2.632,9.868h0A11.464,11.464,0,0,1,0,6.25,11.459,11.459,0,0,1,2.131,3.125,10.232,10.232,0,0,1,5.034.977,9.8,9.8,0,0,1,9.375,0a7.251,7.251,0,0,1,2.609.515L9.375,3.125A3.129,3.129,0,0,0,6.25,6.25L2.633,9.868Z"
                                        transform="translate(3.125 6.25)"
                                        fill="#737588"
                                      />
                                      <rect
                                        id="Rectangle-4"
                                        width="19.792"
                                        height="2.083"
                                        transform="translate(5.313 19.203) rotate(-45)"
                                        fill="#737588"
                                        opacity="0.3"
                                      />
                                    </svg>
                                  </span>
                                  <span className="pl-15 icon-list-text">
                                    Extended Documentation
                                  </span>
                                </li>
                                <li className="flex items-center pb-15 mt-15 icon-list-item">
                                  <span className="icon-list-icon flex">
                                    <svg
                                      className="mr-5 w-25 h-25"
                                      xmlns="http://www.w3.org/2000/svg"
                                      id="Chat_2"
                                      data-name="Chat#5"
                                      width={22}
                                      height={22}
                                      viewBox="0 0 22 22"
                                    >
                                      <rect
                                        id="bound-6"
                                        width={22}
                                        height={22}
                                        fill="none"
                                      />
                                      <path
                                        id="Combined_Shape-8"
                                        data-name="Combined Shape"
                                        d="M15.875,15.852l-2.1-2.1H3a3,3,0,0,1-3-3V3A3,3,0,0,1,3,0H14.417a3,3,0,0,1,3,3v7.75q0,.133-.011.264L17.439,11v4.2a.916.916,0,0,1-.923.918A.9.9,0,0,1,15.875,15.852Z"
                                        transform="translate(2.75 2.75)"
                                        fill="#737588"
                                        opacity="0.3"
                                      />
                                      <path
                                        id="Combined_Shape-9"
                                        data-name="Combined Shape"
                                        d="M10.616,3A1.447,1.447,0,0,1,9.231,1.5,1.448,1.448,0,0,1,10.616,0,1.447,1.447,0,0,1,12,1.5,1.447,1.447,0,0,1,10.616,3ZM6,3A1.447,1.447,0,0,1,4.616,1.5,1.447,1.447,0,0,1,6,0,1.448,1.448,0,0,1,7.385,1.5,1.447,1.447,0,0,1,6,3ZM1.385,3A1.447,1.447,0,0,1,0,1.5,1.448,1.448,0,0,1,1.385,0,1.447,1.447,0,0,1,2.769,1.5,1.447,1.447,0,0,1,1.385,3Z"
                                        transform="translate(5 8)"
                                        fill="#737588"
                                        opacity="0.3"
                                      />
                                    </svg>
                                  </span>
                                  <span className="pl-15 icon-list-text">
                                    Full Service Payroll
                                  </span>
                                </li>
                                <li className="flex items-center pb-15 mt-15 icon-list-item">
                                  <span className="icon-list-icon flex">
                                    <svg
                                      className="mr-5 w-25 h-25"
                                      xmlns="http://www.w3.org/2000/svg"
                                      width={18}
                                      height="18.01"
                                      viewBox="0 0 18 18.01"
                                    >
                                      <g
                                        id="Outgoing-box-2"
                                        transform="translate(0 0.01)"
                                      >
                                        <rect
                                          id="bound-7"
                                          width={18}
                                          height={18}
                                          fill="none"
                                        />
                                        <path
                                          id="Combined_Shape-10"
                                          data-name="Combined Shape"
                                          d="M15,0V3a1.5,1.5,0,0,1-1.5,1.5H1.5A1.5,1.5,0,0,1,0,3V0H3.209l.408,1.224A1.5,1.5,0,0,0,5.041,2.25h5a1.5,1.5,0,0,0,1.342-.829L12.089,0Z"
                                          transform="translate(1.5 12.75)"
                                          fill="#737588"
                                        />
                                        <path
                                          id="Path-4"
                                          d="M0,5.25,2.523.765A1.5,1.5,0,0,1,3.83,0h6.5a1.5,1.5,0,0,1,1.307.765L14.156,5.25h-2.49a1.5,1.5,0,0,0-1.342.829L9.615,7.5h-5L4.211,6.276A1.5,1.5,0,0,0,2.788,5.25Z"
                                          transform="translate(1.922 6)"
                                          fill="#737588"
                                          opacity="0.3"
                                        />
                                        <path
                                          id="Shape-9"
                                          d="M2.5.09a.375.375,0,0,0-.617.286V1.843H.375A.375.375,0,0,0,0,2.218v.767a.375.375,0,0,0,.375.375H1.881V4.828a.375.375,0,0,0,.617.286L5.127,2.888a.375.375,0,0,0,0-.572Z"
                                          transform="translate(6.398 5.25) rotate(-90)"
                                          fill="#737588"
                                        />
                                      </g>
                                    </svg>
                                  </span>
                                  <span className="pl-15 icon-list-text">
                                    Self Service Support
                                  </span>
                                </li>
                                <li className="flex items-center mt-15 icon-list-item">
                                  <span className="icon-list-icon flex">
                                    <svg
                                      className="mr-5 w-25 h-25"
                                      xmlns="http://www.w3.org/2000/svg"
                                      id="Hidden-3"
                                      width={25}
                                      height={25}
                                      viewBox="0 0 25 25"
                                    >
                                      <rect
                                        id="bound-8"
                                        width={25}
                                        height={25}
                                        fill="none"
                                      />
                                      <path
                                        id="Combined_Shape-11"
                                        data-name="Combined Shape"
                                        d="M.942,8.491C.625,8.491.308,8.477,0,8.45L8.45,0a19.584,19.584,0,0,1,1.867,2.242l0,.006A20.748,20.748,0,0,1,7.548,5.366C5.992,6.792,3.584,8.491.942,8.491Z"
                                        transform="translate(11.558 10.259)"
                                        fill="#737588"
                                      />
                                      <path
                                        id="Combined_Shape-12"
                                        data-name="Combined Shape"
                                        d="M2.632,9.868h0A11.464,11.464,0,0,1,0,6.25,11.459,11.459,0,0,1,2.131,3.125,10.232,10.232,0,0,1,5.034.977,9.8,9.8,0,0,1,9.375,0a7.251,7.251,0,0,1,2.609.515L9.375,3.125A3.129,3.129,0,0,0,6.25,6.25L2.633,9.868Z"
                                        transform="translate(3.125 6.25)"
                                        fill="#737588"
                                      />
                                      <rect
                                        id="Rectangle-5"
                                        width="19.792"
                                        height="2.083"
                                        transform="translate(5.313 19.203) rotate(-45)"
                                        fill="#737588"
                                        opacity="0.3"
                                      />
                                    </svg>
                                  </span>
                                  <span className="pl-15 icon-list-text">
                                    Encryption for messages
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="col col-12 col-md-4 col-xl-3 p-0 animation-element">
                          <div className="flex flex-col items-center w-full py-50 px-20">
                            <div className="mb-20 rounded-100 bg-black-10 w-auto ld-fancy-heading relative">
                              <p className="text-10 font-semibold tracking-1 m-0 px-15 text-black uppercase ld-fh-element relative">
                                Simple plan
                              </p>
                            </div>
                            <div className="ld-fancy-heading relative">
                              <h2 className="m-0 ld-fh-element relative">
                                Free<span className="text-14 font-normal">/mo</span>
                              </h2>
                            </div>
                            <div className="py-15 w-full divider">
                              <span className="divider-separator" />
                            </div>
                            <div className="ld-fancy-heading relative">
                              <p className="text-14 ld-fh-element mb-0/5em inline-block relative">
                                Use customer data to build great and solid product
                                experiences that convert.
                              </p>
                            </div>
                            <div className="w-full text-center mt-55 mb-40 lg:hidden">
                              <ul className="icon-list-items reset-ul">
                                <li className="pb-20 flex justify-center text-green-500 icon-list-item">
                                  <span className="icon-list-icon flex">
                                    <i
                                      aria-hidden="true"
                                      className="lqd-icn-ess icon-ion-ios-checkmark text-15"
                                    />
                                  </span>
                                  <span className="icon-list-text" />
                                </li>
                                <li className="pb-20 mt-20 flex justify-center text-green-500 icon-list-item">
                                  <span className="icon-list-icon flex">
                                    <i
                                      aria-hidden="true"
                                      className="lqd-icn-ess icon-ion-ios-checkmark text-15"
                                    />
                                  </span>
                                  <span className="icon-list-text" />
                                </li>
                                <li className="pb-20 mt-20 text-black icon-list-item">
                                  <span className="icon-list-text">
                                    100GB Storage
                                  </span>
                                </li>
                                <li className="pb-20 mt-20 flex justify-center text-green-500 icon-list-item">
                                  <span className="icon-list-icon flex">
                                    <i
                                      aria-hidden="true"
                                      className="lqd-icn-ess icon-ion-ios-checkmark text-15"
                                    />
                                  </span>
                                  <span className="icon-list-text" />
                                </li>
                                <li className="pb-20 mt-20 flex justify-center text-green-500 icon-list-item">
                                  <span className="icon-list-icon flex">
                                    <i
                                      aria-hidden="true"
                                      className="lqd-icn-ess icon-ion-ios-checkmark text-15"
                                    />
                                  </span>
                                  <span className="icon-list-text" />
                                </li>
                                <li className="pb-20 mt-20 flex justify-center text-green-500 icon-list-item">
                                  <span className="icon-list-icon flex">
                                    <i
                                      aria-hidden="true"
                                      className="lqd-icn-ess icon-ion-ios-checkmark text-15"
                                    />
                                  </span>
                                  <span className="icon-list-text" />
                                </li>
                                <li className="mt-20 flex justify-center text-green-500 icon-list-item">
                                  <span className="icon-list-icon flex">
                                    <i
                                      aria-hidden="true"
                                      className="lqd-icn-ess icon-ion-ios-checkmark text-15"
                                    />
                                  </span>
                                  <span className="icon-list-text" />
                                </li>
                              </ul>
                            </div>
                            <div className="mt-55 mb-40 hidden lg:block">
                              <ul className="icon-list-items reset-ul">
                                <li className="pb-20 text-black icon-list-item">
                                  <span className="icon-list-text">
                                    Unlimited Photos and Videos
                                  </span>
                                </li>
                                <li className="pb-20 mt-20 text-black icon-list-item">
                                  <span className="icon-list-text">
                                    99.9 Uptime Guarantee
                                  </span>
                                </li>
                                <li className="pb-20 mt-20 text-black icon-list-item">
                                  <span className="icon-list-text">
                                    100GB Storage
                                  </span>
                                </li>
                                <li className="pb-20 mt-20 text-black icon-list-item">
                                  <span className="icon-list-text">
                                    Extended Documentation
                                  </span>
                                </li>
                                <li className="pb-20 mt-20 text-black icon-list-item">
                                  <span className="icon-list-text">
                                    Full Service Payroll
                                  </span>
                                </li>
                                <li className="pb-20 mt-20 text-black icon-list-item">
                                  <span className="icon-list-text">
                                    Self Service Support
                                  </span>
                                </li>
                                <li className="mt-20 text-black icon-list-item">
                                  <span className="icon-list-text">
                                    Encryption for messages
                                  </span>
                                </li>
                              </ul>
                            </div>
                            <a
                              href="./page-start-hub-3-pricing.html"
                              className="btn btn-underlined border-thin text-green-500 text-13 module-btn-1"
                              target="_blank"
                            >
                              <span
                                className="btn-txt"
                                data-text="Select Free Plan"
                              >
                                Select Free Plan
                              </span>
                            </a>
                          </div>
                        </div>
                        <div className="col col-12 col-md-4 col-xl-3 p-0 animation-element">
                          <div className="flex flex-col items-center relative py-50 px-20 bg-lightgray transition-all rounded-12">
                            <div className="badge-lqd-shape absolute z-0 -top-70 lg:hidden">
                              <div className="lqd-imggrp-single block relative">
                                <div className="lqd-imggrp-img-container inline-flex relative items-center justify-center">
                                  <figure className="w-full relative">
                                    <img
                                      width={62}
                                      height={62}
                                      src="/images/shape-badge.svg"
                                      alt="badge shape"
                                    />
                                  </figure>
                                </div>
                              </div>
                            </div>
                            <div className="badge-text absolute -top-55 sm:hidden">
                              <div className="ld-fancy-heading relative">
                                <span className="text-white text-14 leading-1em m-0 ld-fh-element relative">
                                  20%
                                  <br />
                                  OFF
                                </span>
                              </div>
                            </div>
                            <div className="mb-20 rounded-100 bg-black-10 w-auto ld-fancy-heading relative">
                              <p className="text-10 font-semibold tracking-1 m-0 px-15 text-black uppercase ld-fh-element relative">
                                Popular plan
                              </p>
                            </div>
                            <div className="ld-fancy-heading relative">
                              <h2 className="m-0 ld-fh-element relative">
                                €85
                                <span
                                  style={{ fontSize: 15, fontWeight: "normal" }}
                                >
                                  /mo
                                </span>
                              </h2>
                            </div>
                            <div className="py-15 w-full divider">
                              <span className="divider-separator" />
                            </div>
                            <div className="ld-fancy-heading relative">
                              <p className="text-14 ld-fh-element mb-0/5em inline-block relative">
                                Use customer data to build great and solid product
                                experiences that convert.
                              </p>
                            </div>
                            <div className="w-full text-center mt-55 mb-40 lg:hidden">
                              <ul className="icon-list-items reset-ul">
                                <li className="pb-20 flex justify-center text-green-500 icon-list-item">
                                  <span className="icon-list-icon flex">
                                    <i
                                      aria-hidden="true"
                                      className="lqd-icn-ess icon-ion-ios-checkmark text-15"
                                    />
                                  </span>
                                  <span className="icon-list-text" />
                                </li>
                                <li className="pb-20 mt-20 flex justify-center text-green-500 icon-list-item">
                                  <span className="icon-list-icon flex">
                                    <i
                                      aria-hidden="true"
                                      className="lqd-icn-ess icon-ion-ios-checkmark text-15"
                                    />
                                  </span>
                                  <span className="icon-list-text" />
                                </li>
                                <li className="pb-20 mt-20 text-black icon-list-item">
                                  <span className="icon-list-text">
                                    1TB Storage
                                  </span>
                                </li>
                                <li className="pb-20 mt-20 flex justify-center text-green-500 icon-list-item">
                                  <span className="icon-list-icon flex">
                                    <i
                                      aria-hidden="true"
                                      className="lqd-icn-ess icon-ion-ios-checkmark text-15"
                                    />
                                  </span>
                                  <span className="icon-list-text" />
                                </li>
                                <li className="pb-20 mt-20 flex justify-center text-green-500 icon-list-item">
                                  <span className="icon-list-icon flex">
                                    <i
                                      aria-hidden="true"
                                      className="lqd-icn-ess icon-ion-ios-checkmark text-15"
                                    />
                                  </span>
                                  <span className="icon-list-text" />
                                </li>
                                <li className="pb-20 mt-20 flex justify-center text-green-500 icon-list-item">
                                  <span className="icon-list-icon flex">
                                    <i
                                      aria-hidden="true"
                                      className="lqd-icn-ess icon-ion-ios-checkmark text-15"
                                    />
                                  </span>
                                  <span className="icon-list-text" />
                                </li>
                                <li className="mt-20 flex justify-center text-green-500 icon-list-item">
                                  <span className="icon-list-icon flex">
                                    <i
                                      aria-hidden="true"
                                      className="lqd-icn-ess icon-ion-ios-checkmark text-15"
                                    />
                                  </span>
                                  <span className="icon-list-text" />
                                </li>
                              </ul>
                            </div>
                            <div className="mt-55 mb-40 hidden lg:block">
                              <ul className="icon-list-items reset-ul">
                                <li className="pb-20 text-black icon-list-item">
                                  <span className="icon-list-text">
                                    Unlimited Photos and Videos
                                  </span>
                                </li>
                                <li className="pb-20 mt-20 text-black icon-list-item">
                                  <span className="icon-list-text">
                                    99.9 Uptime Guarantee
                                  </span>
                                </li>
                                <li className="pb-20 mt-20 text-black icon-list-item">
                                  <span className="icon-list-text">
                                    1TB Storage
                                  </span>
                                </li>
                                <li className="pb-20 mt-20 text-black icon-list-item">
                                  <span className="icon-list-text">
                                    Extended Documentation
                                  </span>
                                </li>
                                <li className="pb-20 mt-20 text-black icon-list-item">
                                  <span className="icon-list-text">
                                    Full Service Payroll
                                  </span>
                                </li>
                                <li className="pb-20 mt-20 text-black icon-list-item">
                                  <span className="icon-list-text">
                                    Self Service Support
                                  </span>
                                </li>
                                <li className="mt-20 text-black icon-list-item">
                                  <span className="icon-list-text">
                                    Encryption for messages
                                  </span>
                                </li>
                              </ul>
                            </div>
                            <a
                              href="./page-start-hub-3-pricing.html"
                              className="btn btn-underlined border-thin text-gradient-start text-13 module-btn-2"
                              target="_blank"
                            >
                              <span
                                className="btn-txt"
                                data-text="Select Popular Plan"
                              >
                                Select Popular Plan
                              </span>
                            </a>
                          </div>
                        </div>
                        <div className="col col-12 col-md-4 col-xl-3 p-0 animation-element">
                          <div className="flex flex-col items-center w-full py-50 px-20">
                            <div className="mb-20 rounded-100 bg-black-10 w-auto ld-fancy-heading relative">
                              <p className="text-10 font-semibold tracking-1 m-0 px-15 text-black uppercase ld-fh-element relative">
                                Premium plan
                              </p>
                            </div>
                            <div className="ld-fancy-heading relative">
                              <h2 className="m-0 ld-fh-element relative">
                                €23
                                <span
                                  style={{ fontSize: 15, fontWeight: "normal" }}
                                >
                                  /mo
                                </span>
                              </h2>
                            </div>
                            <div className="py-15 w-full divider">
                              <span className="divider-separator" />
                            </div>
                            <div className="ld-fancy-heading relative">
                              <p className="text-14 ld-fh-element mb-0/5em inline-block relative">
                                Use customer data to build great and solid product
                                experiences that convert.
                              </p>
                            </div>
                            <div className="w-full text-center mt-55 mb-40 lg:hidden">
                              <ul className="icon-list-items reset-ul">
                                <li className="pb-20 flex justify-center text-green-500 icon-list-item">
                                  <span className="icon-list-icon flex">
                                    <i
                                      aria-hidden="true"
                                      className="lqd-icn-ess icon-ion-ios-checkmark text-15"
                                    />
                                  </span>
                                  <span className="icon-list-text" />
                                </li>
                                <li className="pb-20 mt-20 flex justify-center text-green-500 icon-list-item">
                                  <span className="icon-list-icon flex">
                                    <i
                                      aria-hidden="true"
                                      className="lqd-icn-ess icon-ion-ios-checkmark text-15"
                                    />
                                  </span>
                                  <span className="icon-list-text" />
                                </li>
                                <li className="pb-20 mt-20 text-black icon-list-item">
                                  <span className="icon-list-text">
                                    Unlimited Storage
                                  </span>
                                </li>
                                <li className="pb-20 mt-20 flex justify-center text-green-500 icon-list-item">
                                  <span className="icon-list-icon flex">
                                    <i
                                      aria-hidden="true"
                                      className="lqd-icn-ess icon-ion-ios-checkmark text-15"
                                    />
                                  </span>
                                  <span className="icon-list-text" />
                                </li>
                                <li className="pb-20 mt-20 flex justify-center text-green-500 icon-list-item">
                                  <span className="icon-list-icon flex">
                                    <i
                                      aria-hidden="true"
                                      className="lqd-icn-ess icon-ion-ios-checkmark text-15"
                                    />
                                  </span>
                                  <span className="icon-list-text" />
                                </li>
                                <li className="pb-20 mt-20 flex justify-center text-green-500 icon-list-item">
                                  <span className="icon-list-icon flex">
                                    <i
                                      aria-hidden="true"
                                      className="lqd-icn-ess icon-ion-ios-checkmark text-15"
                                    />
                                  </span>
                                  <span className="icon-list-text" />
                                </li>
                                <li className="mt-20 flex justify-center text-green-500 icon-list-item">
                                  <span className="icon-list-icon flex">
                                    <i
                                      aria-hidden="true"
                                      className="lqd-icn-ess icon-ion-ios-checkmark text-15"
                                    />
                                  </span>
                                  <span className="icon-list-text" />
                                </li>
                              </ul>
                            </div>
                            <div className="mt-55 mb-40 hidden lg:block">
                              <ul className="icon-list-items reset-ul">
                                <li className="pb-20 text-black icon-list-item">
                                  <span className="icon-list-text">
                                    Unlimited Photos and Videos
                                  </span>
                                </li>
                                <li className="pb-20 mt-20 text-black icon-list-item">
                                  <span className="icon-list-text">
                                    99.9 Uptime Guarantee
                                  </span>
                                </li>
                                <li className="pb-20 mt-20 text-black icon-list-item">
                                  <span className="icon-list-text">
                                    Unlimited Storage
                                  </span>
                                </li>
                                <li className="pb-20 mt-20 text-black icon-list-item">
                                  <span className="icon-list-text">
                                    Extended Documentation
                                  </span>
                                </li>
                                <li className="pb-20 mt-20 text-black icon-list-item">
                                  <span className="icon-list-text">
                                    Full Service Payroll
                                  </span>
                                </li>
                                <li className="pb-20 mt-20 text-black icon-list-item">
                                  <span className="icon-list-text">
                                    Self Service Support
                                  </span>
                                </li>
                                <li className="mt-20 text-black icon-list-item">
                                  <span className="icon-list-text">
                                    Encryption for messages
                                  </span>
                                </li>
                              </ul>
                            </div>
                            <a
                              href="./page-start-hub-3-pricing.html"
                              className="btn btn-underlined border-thin text-darkpink text-13 module-btn-3"
                              target="_blank"
                            >
                              <span
                                className="btn-txt"
                                data-text="Select Premium Plan"
                              >
                                Select Premium Plan
                              </span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    id="lqd-tab-price-annually"
                    role="tabpanel"
                    className="lqd-tabs-pane fade"
                    data-custom-animations="true"
                    data-ca-options='{"animationTarget": ".animation-element", "ease": "power4.out", "initValues": {"x": "35px", "opacity" : 0} , "animations": {"x": "0px", "opacity" : 1}}'
                  >
                    <div className="container animation-element">
                      <div className="row text-center">
                        <div className="col col-12 col-md-4 col-xl-3 p-0 lg:hidden animation-element">
                          <div className="flex flex-col py-50 px-20 text-start w-full">
                            <div className="lqd-imggrp-single block relative">
                              <div className="lqd-imggrp-img-container inline-flex relative items-center justify-center">
                                <figure className="w-full relative">
                                  <img
                                    width={272}
                                    height={192}
                                    src="/images/shape-rocket.svg"
                                    alt="rocket"
                                  />
                                </figure>
                              </div>
                            </div>
                            <div className="w-full mt-65">
                              <ul className="reset-ul icon-list-items">
                                <li className="flex items-center pb-15 icon-list-item">
                                  <span className="icon-list-icon flex">
                                    <svg
                                      className="mr-5 w-25 h-25"
                                      xmlns="http://www.w3.org/2000/svg"
                                      id="Hidden-4"
                                      width={25}
                                      height={25}
                                      viewBox="0 0 25 25"
                                    >
                                      <rect
                                        id="bound-9"
                                        width={25}
                                        height={25}
                                        fill="none"
                                      />
                                      <path
                                        id="Combined_Shape-13"
                                        data-name="Combined Shape"
                                        d="M.942,8.491C.625,8.491.308,8.477,0,8.45L8.45,0a19.584,19.584,0,0,1,1.867,2.242l0,.006A20.748,20.748,0,0,1,7.548,5.366C5.992,6.792,3.584,8.491.942,8.491Z"
                                        transform="translate(11.558 10.259)"
                                        fill="#737588"
                                      />
                                      <path
                                        id="Combined_Shape-14"
                                        data-name="Combined Shape"
                                        d="M2.632,9.868h0A11.464,11.464,0,0,1,0,6.25,11.459,11.459,0,0,1,2.131,3.125,10.232,10.232,0,0,1,5.034.977,9.8,9.8,0,0,1,9.375,0a7.251,7.251,0,0,1,2.609.515L9.375,3.125A3.129,3.129,0,0,0,6.25,6.25L2.633,9.868Z"
                                        transform="translate(3.125 6.25)"
                                        fill="#737588"
                                      />
                                      <rect
                                        id="Rectangle-6"
                                        width="19.792"
                                        height="2.083"
                                        transform="translate(5.313 19.203) rotate(-45)"
                                        fill="#737588"
                                        opacity="0.3"
                                      />
                                    </svg>
                                  </span>
                                  <span className="pl-15 icon-list-text">
                                    Unlimited Photos and Videos
                                  </span>
                                </li>
                                <li className="flex items-center pb-15 mt-15 icon-list-item">
                                  <span className="icon-list-icon flex">
                                    <svg
                                      className="mr-5 w-25 h-25"
                                      xmlns="http://www.w3.org/2000/svg"
                                      id="Chat_3"
                                      data-name="Chat#5"
                                      width={22}
                                      height={22}
                                      viewBox="0 0 22 22"
                                    >
                                      <rect
                                        id="bound-10"
                                        width={22}
                                        height={22}
                                        fill="none"
                                      />
                                      <path
                                        id="Combined_Shape-15"
                                        data-name="Combined Shape"
                                        d="M15.875,15.852l-2.1-2.1H3a3,3,0,0,1-3-3V3A3,3,0,0,1,3,0H14.417a3,3,0,0,1,3,3v7.75q0,.133-.011.264L17.439,11v4.2a.916.916,0,0,1-.923.918A.9.9,0,0,1,15.875,15.852Z"
                                        transform="translate(2.75 2.75)"
                                        fill="#737588"
                                        opacity="0.3"
                                      />
                                      <path
                                        id="Combined_Shape-16"
                                        data-name="Combined Shape"
                                        d="M10.616,3A1.447,1.447,0,0,1,9.231,1.5,1.448,1.448,0,0,1,10.616,0,1.447,1.447,0,0,1,12,1.5,1.447,1.447,0,0,1,10.616,3ZM6,3A1.447,1.447,0,0,1,4.616,1.5,1.447,1.447,0,0,1,6,0,1.448,1.448,0,0,1,7.385,1.5,1.447,1.447,0,0,1,6,3ZM1.385,3A1.447,1.447,0,0,1,0,1.5,1.448,1.448,0,0,1,1.385,0,1.447,1.447,0,0,1,2.769,1.5,1.447,1.447,0,0,1,1.385,3Z"
                                        transform="translate(5 8)"
                                        fill="#737588"
                                        opacity="0.3"
                                      />
                                    </svg>
                                  </span>
                                  <span className="pl-15 icon-list-text">
                                    99.9 Uptime Guarantee
                                  </span>
                                </li>
                                <li className="flex items-center pb-15 mt-15 icon-list-item">
                                  <span className="icon-list-icon flex">
                                    <svg
                                      className="mr-5 w-25 h-25"
                                      xmlns="http://www.w3.org/2000/svg"
                                      width={18}
                                      height="18.01"
                                      viewBox="0 0 18 18.01"
                                    >
                                      <g
                                        id="Outgoing-box-3"
                                        transform="translate(0 0.01)"
                                      >
                                        <rect
                                          id="bound-11"
                                          width={18}
                                          height={18}
                                          fill="none"
                                        />
                                        <path
                                          id="Combined_Shape-17"
                                          data-name="Combined Shape"
                                          d="M15,0V3a1.5,1.5,0,0,1-1.5,1.5H1.5A1.5,1.5,0,0,1,0,3V0H3.209l.408,1.224A1.5,1.5,0,0,0,5.041,2.25h5a1.5,1.5,0,0,0,1.342-.829L12.089,0Z"
                                          transform="translate(1.5 12.75)"
                                          fill="#737588"
                                        />
                                        <path
                                          id="Path-1"
                                          d="M0,5.25,2.523.765A1.5,1.5,0,0,1,3.83,0h6.5a1.5,1.5,0,0,1,1.307.765L14.156,5.25h-2.49a1.5,1.5,0,0,0-1.342.829L9.615,7.5h-5L4.211,6.276A1.5,1.5,0,0,0,2.788,5.25Z"
                                          transform="translate(1.922 6)"
                                          fill="#737588"
                                          opacity="0.3"
                                        />
                                        <path
                                          id="Shape-10"
                                          d="M2.5.09a.375.375,0,0,0-.617.286V1.843H.375A.375.375,0,0,0,0,2.218v.767a.375.375,0,0,0,.375.375H1.881V4.828a.375.375,0,0,0,.617.286L5.127,2.888a.375.375,0,0,0,0-.572Z"
                                          transform="translate(6.398 5.25) rotate(-90)"
                                          fill="#737588"
                                        />
                                      </g>
                                    </svg>
                                  </span>
                                  <span className="pl-15 icon-list-text">
                                    Automate All Contracts
                                  </span>
                                </li>
                                <li className="flex items-center pb-15 mt-15 icon-list-item">
                                  <span className="icon-list-icon flex">
                                    <svg
                                      className="mr-5 w-25 h-25"
                                      xmlns="http://www.w3.org/2000/svg"
                                      id="Hidden-5"
                                      width={25}
                                      height={25}
                                      viewBox="0 0 25 25"
                                    >
                                      <rect
                                        id="bound-12"
                                        width={25}
                                        height={25}
                                        fill="none"
                                      />
                                      <path
                                        id="Combined_Shape-18"
                                        data-name="Combined Shape"
                                        d="M.942,8.491C.625,8.491.308,8.477,0,8.45L8.45,0a19.584,19.584,0,0,1,1.867,2.242l0,.006A20.748,20.748,0,0,1,7.548,5.366C5.992,6.792,3.584,8.491.942,8.491Z"
                                        transform="translate(11.558 10.259)"
                                        fill="#737588"
                                      />
                                      <path
                                        id="Combined_Shape-19"
                                        data-name="Combined Shape"
                                        d="M2.632,9.868h0A11.464,11.464,0,0,1,0,6.25,11.459,11.459,0,0,1,2.131,3.125,10.232,10.232,0,0,1,5.034.977,9.8,9.8,0,0,1,9.375,0a7.251,7.251,0,0,1,2.609.515L9.375,3.125A3.129,3.129,0,0,0,6.25,6.25L2.633,9.868Z"
                                        transform="translate(3.125 6.25)"
                                        fill="#737588"
                                      />
                                      <rect
                                        id="Rectangle-1"
                                        width="19.792"
                                        height="2.083"
                                        transform="translate(5.313 19.203) rotate(-45)"
                                        fill="#737588"
                                        opacity="0.3"
                                      />
                                    </svg>
                                  </span>
                                  <span className="pl-15 icon-list-text">
                                    Extended Documentation
                                  </span>
                                </li>
                                <li className="flex items-center pb-15 mt-15 icon-list-item">
                                  <span className="icon-list-icon flex">
                                    <svg
                                      className="mr-5 w-25 h-25"
                                      xmlns="http://www.w3.org/2000/svg"
                                      id="Chat_4"
                                      data-name="Chat#5"
                                      width={22}
                                      height={22}
                                      viewBox="0 0 22 22"
                                    >
                                      <rect
                                        id="bound-13"
                                        width={22}
                                        height={22}
                                        fill="none"
                                      />
                                      <path
                                        id="Combined_Shape-20"
                                        data-name="Combined Shape"
                                        d="M15.875,15.852l-2.1-2.1H3a3,3,0,0,1-3-3V3A3,3,0,0,1,3,0H14.417a3,3,0,0,1,3,3v7.75q0,.133-.011.264L17.439,11v4.2a.916.916,0,0,1-.923.918A.9.9,0,0,1,15.875,15.852Z"
                                        transform="translate(2.75 2.75)"
                                        fill="#737588"
                                        opacity="0.3"
                                      />
                                      <path
                                        id="Combined_Shape-21"
                                        data-name="Combined Shape"
                                        d="M10.616,3A1.447,1.447,0,0,1,9.231,1.5,1.448,1.448,0,0,1,10.616,0,1.447,1.447,0,0,1,12,1.5,1.447,1.447,0,0,1,10.616,3ZM6,3A1.447,1.447,0,0,1,4.616,1.5,1.447,1.447,0,0,1,6,0,1.448,1.448,0,0,1,7.385,1.5,1.447,1.447,0,0,1,6,3ZM1.385,3A1.447,1.447,0,0,1,0,1.5,1.448,1.448,0,0,1,1.385,0,1.447,1.447,0,0,1,2.769,1.5,1.447,1.447,0,0,1,1.385,3Z"
                                        transform="translate(5 8)"
                                        fill="#737588"
                                        opacity="0.3"
                                      />
                                    </svg>
                                  </span>
                                  <span className="pl-15 icon-list-text">
                                    Full Service Payroll
                                  </span>
                                </li>
                                <li className="flex items-center pb-15 mt-15 icon-list-item">
                                  <span className="icon-list-icon flex">
                                    <svg
                                      className="mr-5 w-25 h-25"
                                      xmlns="http://www.w3.org/2000/svg"
                                      width={18}
                                      height="18.01"
                                      viewBox="0 0 18 18.01"
                                    >
                                      <g
                                        id="Outgoing-box-4"
                                        transform="translate(0 0.01)"
                                      >
                                        <rect
                                          id="bound-14"
                                          width={18}
                                          height={18}
                                          fill="none"
                                        />
                                        <path
                                          id="Combined_Shape-22"
                                          data-name="Combined Shape"
                                          d="M15,0V3a1.5,1.5,0,0,1-1.5,1.5H1.5A1.5,1.5,0,0,1,0,3V0H3.209l.408,1.224A1.5,1.5,0,0,0,5.041,2.25h5a1.5,1.5,0,0,0,1.342-.829L12.089,0Z"
                                          transform="translate(1.5 12.75)"
                                          fill="#737588"
                                        />
                                        <path
                                          id="Path-2"
                                          d="M0,5.25,2.523.765A1.5,1.5,0,0,1,3.83,0h6.5a1.5,1.5,0,0,1,1.307.765L14.156,5.25h-2.49a1.5,1.5,0,0,0-1.342.829L9.615,7.5h-5L4.211,6.276A1.5,1.5,0,0,0,2.788,5.25Z"
                                          transform="translate(1.922 6)"
                                          fill="#737588"
                                          opacity="0.3"
                                        />
                                        <path
                                          id="Shape-11"
                                          d="M2.5.09a.375.375,0,0,0-.617.286V1.843H.375A.375.375,0,0,0,0,2.218v.767a.375.375,0,0,0,.375.375H1.881V4.828a.375.375,0,0,0,.617.286L5.127,2.888a.375.375,0,0,0,0-.572Z"
                                          transform="translate(6.398 5.25) rotate(-90)"
                                          fill="#737588"
                                        />
                                      </g>
                                    </svg>
                                  </span>
                                  <span className="pl-15 icon-list-text">
                                    Self Service Support
                                  </span>
                                </li>
                                <li className="flex items-center mt-15 icon-list-item">
                                  <span className="icon-list-icon flex">
                                    <svg
                                      className="mr-5 w-25 h-25"
                                      xmlns="http://www.w3.org/2000/svg"
                                      id="Hidden-6"
                                      width={25}
                                      height={25}
                                      viewBox="0 0 25 25"
                                    >
                                      <rect
                                        id="bound-15"
                                        width={25}
                                        height={25}
                                        fill="none"
                                      />
                                      <path
                                        id="Combined_Shape-23"
                                        data-name="Combined Shape"
                                        d="M.942,8.491C.625,8.491.308,8.477,0,8.45L8.45,0a19.584,19.584,0,0,1,1.867,2.242l0,.006A20.748,20.748,0,0,1,7.548,5.366C5.992,6.792,3.584,8.491.942,8.491Z"
                                        transform="translate(11.558 10.259)"
                                        fill="#737588"
                                      />
                                      <path
                                        id="Combined_Shape-24"
                                        data-name="Combined Shape"
                                        d="M2.632,9.868h0A11.464,11.464,0,0,1,0,6.25,11.459,11.459,0,0,1,2.131,3.125,10.232,10.232,0,0,1,5.034.977,9.8,9.8,0,0,1,9.375,0a7.251,7.251,0,0,1,2.609.515L9.375,3.125A3.129,3.129,0,0,0,6.25,6.25L2.633,9.868Z"
                                        transform="translate(3.125 6.25)"
                                        fill="#737588"
                                      />
                                      <rect
                                        id="Rectangle-2"
                                        width="19.792"
                                        height="2.083"
                                        transform="translate(5.313 19.203) rotate(-45)"
                                        fill="#737588"
                                        opacity="0.3"
                                      />
                                    </svg>
                                  </span>
                                  <span className="pl-15 icon-list-text">
                                    Encryption for messages
                                  </span>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                        <div className="col col-12 col-md-4 col-xl-3 p-0 animation-element">
                          <div className="flex flex-col items-center w-full py-50 px-20">
                            <div className="mb-20 rounded-100 bg-black-10 w-auto ld-fancy-heading relative">
                              <p className="text-10 font-semibold tracking-1 m-0 px-15 text-black uppercase ld-fh-element relative">
                                Simple plan
                              </p>
                            </div>
                            <div className="ld-fancy-heading relative">
                              <h2 className="m-0 ld-fh-element relative">
                                Free<span className="text-14 font-normal">/yr</span>
                              </h2>
                            </div>
                            <div className="py-15 w-full divider">
                              <span className="divider-separator" />
                            </div>
                            <div className="ld-fancy-heading relative">
                              <p className="text-14 ld-fh-element mb-0/5em inline-block relative">
                                Use customer data to build great and solid product
                                experiences that convert.
                              </p>
                            </div>
                            <div className="w-full text-center mt-55 mb-40 lg:hidden">
                              <ul className="icon-list-items reset-ul">
                                <li className="pb-20 flex justify-center text-green-500 icon-list-item">
                                  <span className="icon-list-icon flex">
                                    <i
                                      aria-hidden="true"
                                      className="lqd-icn-ess icon-ion-ios-checkmark text-15"
                                    />
                                  </span>
                                  <span className="icon-list-text" />
                                </li>
                                <li className="pb-20 mt-20 flex justify-center text-green-500 icon-list-item">
                                  <span className="icon-list-icon flex">
                                    <i
                                      aria-hidden="true"
                                      className="lqd-icn-ess icon-ion-ios-checkmark text-15"
                                    />
                                  </span>
                                  <span className="icon-list-text" />
                                </li>
                                <li className="pb-20 mt-20 text-black icon-list-item">
                                  <span className="icon-list-text">
                                    100GB Storage
                                  </span>
                                </li>
                                <li className="pb-20 mt-20 flex justify-center text-green-500 icon-list-item">
                                  <span className="icon-list-icon flex">
                                    <i
                                      aria-hidden="true"
                                      className="lqd-icn-ess icon-ion-ios-checkmark text-15"
                                    />
                                  </span>
                                  <span className="icon-list-text" />
                                </li>
                                <li className="pb-20 mt-20 flex justify-center text-green-500 icon-list-item">
                                  <span className="icon-list-icon flex">
                                    <i
                                      aria-hidden="true"
                                      className="lqd-icn-ess icon-ion-ios-checkmark text-15"
                                    />
                                  </span>
                                  <span className="icon-list-text" />
                                </li>
                                <li className="pb-20 mt-20 flex justify-center text-green-500 icon-list-item">
                                  <span className="icon-list-icon flex">
                                    <i
                                      aria-hidden="true"
                                      className="lqd-icn-ess icon-ion-ios-checkmark text-15"
                                    />
                                  </span>
                                  <span className="icon-list-text" />
                                </li>
                                <li className="mt-20 flex justify-center text-green-500 icon-list-item">
                                  <span className="icon-list-icon flex">
                                    <i
                                      aria-hidden="true"
                                      className="lqd-icn-ess icon-ion-ios-checkmark text-15"
                                    />
                                  </span>
                                  <span className="icon-list-text" />
                                </li>
                              </ul>
                            </div>
                            <div className="mt-55 mb-40 hidden lg:block">
                              <ul className="icon-list-items reset-ul">
                                <li className="pb-20 text-black icon-list-item">
                                  <span className="icon-list-text">
                                    Unlimited Photos and Videos
                                  </span>
                                </li>
                                <li className="pb-20 mt-20 text-black icon-list-item">
                                  <span className="icon-list-text">
                                    99.9 Uptime Guarantee
                                  </span>
                                </li>
                                <li className="pb-20 mt-20 text-black icon-list-item">
                                  <span className="icon-list-text">
                                    100GB Storage
                                  </span>
                                </li>
                                <li className="pb-20 mt-20 text-black icon-list-item">
                                  <span className="icon-list-text">
                                    Extended Documentation
                                  </span>
                                </li>
                                <li className="pb-20 mt-20 text-black icon-list-item">
                                  <span className="icon-list-text">
                                    Full Service Payroll
                                  </span>
                                </li>
                                <li className="pb-20 mt-20 text-black icon-list-item">
                                  <span className="icon-list-text">
                                    Self Service Support
                                  </span>
                                </li>
                                <li className="mt-20 text-black icon-list-item">
                                  <span className="icon-list-text">
                                    Encryption for messages
                                  </span>
                                </li>
                              </ul>
                            </div>
                            <a
                              href="./page-start-hub-3-pricing.html"
                              className="btn btn-underlined border-thin text-green-500 text-13 module-btn-1"
                              target="_blank"
                            >
                              <span
                                className="btn-txt"
                                data-text="Select Free Plan"
                              >
                                Select Free Plan
                              </span>
                            </a>
                          </div>
                        </div>
                        <div className="col col-12 col-md-4 col-xl-3 p-0 animation-element">
                          <div className="flex flex-col items-center relative py-50 px-20 bg-lightgray transition-all rounded-12">
                            <div className="badge-lqd-shape absolute z-0 -top-70 lg:hidden">
                              <div className="lqd-imggrp-single block relative">
                                <div className="lqd-imggrp-img-container inline-flex relative items-center justify-center">
                                  <figure className="w-full relative">
                                    <img
                                      width={62}
                                      height={62}
                                      src="/images/shape-badge.svg"
                                      alt="badge shape"
                                    />
                                  </figure>
                                </div>
                              </div>
                            </div>
                            <div className="badge-text absolute -top-55 sm:hidden">
                              <div className="ld-fancy-heading relative">
                                <span className="text-white text-14 leading-1em m-0 ld-fh-element relative">
                                  20%
                                  <br />
                                  OFF
                                </span>
                              </div>
                            </div>
                            <div className="mb-20 rounded-100 bg-black-10 w-auto ld-fancy-heading relative">
                              <p className="text-10 font-semibold tracking-1 m-0 px-15 text-black uppercase ld-fh-element relative">
                                Popular plan
                              </p>
                            </div>
                            <div className="ld-fancy-heading relative">
                              <h2 className="m-0 ld-fh-element relative">
                                €85
                                <span
                                  style={{ fontSize: 15, fontWeight: "normal" }}
                                >
                                  /yr
                                </span>
                              </h2>
                            </div>
                            <div className="py-15 w-full divider">
                              <span className="divider-separator" />
                            </div>
                            <div className="ld-fancy-heading relative">
                              <p className="text-14 ld-fh-element mb-0/5em inline-block relative">
                                Use customer data to build great and solid product
                                experiences that convert.
                              </p>
                            </div>
                            <div className="w-full text-center mt-55 mb-40 lg:hidden">
                              <ul className="icon-list-items reset-ul">
                                <li className="pb-20 flex justify-center text-green-500 icon-list-item">
                                  <span className="icon-list-icon flex">
                                    <i
                                      aria-hidden="true"
                                      className="lqd-icn-ess icon-ion-ios-checkmark text-15"
                                    />
                                  </span>
                                  <span className="icon-list-text" />
                                </li>
                                <li className="pb-20 mt-20 flex justify-center text-green-500 icon-list-item">
                                  <span className="icon-list-icon flex">
                                    <i
                                      aria-hidden="true"
                                      className="lqd-icn-ess icon-ion-ios-checkmark text-15"
                                    />
                                  </span>
                                  <span className="icon-list-text" />
                                </li>
                                <li className="pb-20 mt-20 text-black icon-list-item">
                                  <span className="icon-list-text">
                                    1TB Storage
                                  </span>
                                </li>
                                <li className="pb-20 mt-20 flex justify-center text-green-500 icon-list-item">
                                  <span className="icon-list-icon flex">
                                    <i
                                      aria-hidden="true"
                                      className="lqd-icn-ess icon-ion-ios-checkmark text-15"
                                    />
                                  </span>
                                  <span className="icon-list-text" />
                                </li>
                                <li className="pb-20 mt-20 flex justify-center text-green-500 icon-list-item">
                                  <span className="icon-list-icon flex">
                                    <i
                                      aria-hidden="true"
                                      className="lqd-icn-ess icon-ion-ios-checkmark text-15"
                                    />
                                  </span>
                                  <span className="icon-list-text" />
                                </li>
                                <li className="pb-20 mt-20 flex justify-center text-green-500 icon-list-item">
                                  <span className="icon-list-icon flex">
                                    <i
                                      aria-hidden="true"
                                      className="lqd-icn-ess icon-ion-ios-checkmark text-15"
                                    />
                                  </span>
                                  <span className="icon-list-text" />
                                </li>
                                <li className="mt-20 flex justify-center text-green-500 icon-list-item">
                                  <span className="icon-list-icon flex">
                                    <i
                                      aria-hidden="true"
                                      className="lqd-icn-ess icon-ion-ios-checkmark text-15"
                                    />
                                  </span>
                                  <span className="icon-list-text" />
                                </li>
                              </ul>
                            </div>
                            <div className="mt-55 mb-40 hidden lg:block">
                              <ul className="icon-list-items reset-ul">
                                <li className="pb-20 text-black icon-list-item">
                                  <span className="icon-list-text">
                                    Unlimited Photos and Videos
                                  </span>
                                </li>
                                <li className="pb-20 mt-20 text-black icon-list-item">
                                  <span className="icon-list-text">
                                    99.9 Uptime Guarantee
                                  </span>
                                </li>
                                <li className="pb-20 mt-20 text-black icon-list-item">
                                  <span className="icon-list-text">
                                    1TB Storage
                                  </span>
                                </li>
                                <li className="pb-20 mt-20 text-black icon-list-item">
                                  <span className="icon-list-text">
                                    Extended Documentation
                                  </span>
                                </li>
                                <li className="pb-20 mt-20 text-black icon-list-item">
                                  <span className="icon-list-text">
                                    Full Service Payroll
                                  </span>
                                </li>
                                <li className="pb-20 mt-20 text-black icon-list-item">
                                  <span className="icon-list-text">
                                    Self Service Support
                                  </span>
                                </li>
                                <li className="mt-20 text-black icon-list-item">
                                  <span className="icon-list-text">
                                    Encryption for messages
                                  </span>
                                </li>
                              </ul>
                            </div>
                            <a
                              href="./page-start-hub-3-pricing.html"
                              className="btn btn-underlined border-thin text-gradient-start text-13 module-btn-2"
                              target="_blank"
                            >
                              <span
                                className="btn-txt"
                                data-text="Select Popular Plan"
                              >
                                Select Popular Plan
                              </span>
                            </a>
                          </div>
                        </div>
                        <div className="col col-12 col-md-4 col-xl-3 p-0 animation-element">
                          <div className="flex flex-col items-center w-full py-50 px-20">
                            <div className="mb-20 rounded-100 bg-black-10 w-auto ld-fancy-heading relative">
                              <p className="text-10 font-semibold tracking-1 m-0 px-15 text-black uppercase ld-fh-element relative">
                                Premium plan
                              </p>
                            </div>
                            <div className="ld-fancy-heading relative">
                              <h2 className="m-0 ld-fh-element relative">
                                €23
                                <span
                                  style={{ fontSize: 15, fontWeight: "normal" }}
                                >
                                  /yr
                                </span>
                              </h2>
                            </div>
                            <div className="py-15 w-full divider">
                              <span className="divider-separator" />
                            </div>
                            <div className="ld-fancy-heading relative">
                              <p className="text-14 ld-fh-element mb-0/5em inline-block relative">
                                Use customer data to build great and solid product
                                experiences that convert.
                              </p>
                            </div>
                            <div className="w-full text-center mt-55 mb-40 lg:hidden">
                              <ul className="icon-list-items reset-ul">
                                <li className="pb-20 flex justify-center text-green-500 icon-list-item">
                                  <span className="icon-list-icon flex">
                                    <i
                                      aria-hidden="true"
                                      className="lqd-icn-ess icon-ion-ios-checkmark text-15"
                                    />
                                  </span>
                                  <span className="icon-list-text" />
                                </li>
                                <li className="pb-20 mt-20 flex justify-center text-green-500 icon-list-item">
                                  <span className="icon-list-icon flex">
                                    <i
                                      aria-hidden="true"
                                      className="lqd-icn-ess icon-ion-ios-checkmark text-15"
                                    />
                                  </span>
                                  <span className="icon-list-text" />
                                </li>
                                <li className="pb-20 mt-20 text-black icon-list-item">
                                  <span className="icon-list-text">
                                    Unlimited Storage
                                  </span>
                                </li>
                                <li className="pb-20 mt-20 flex justify-center text-green-500 icon-list-item">
                                  <span className="icon-list-icon flex">
                                    <i
                                      aria-hidden="true"
                                      className="lqd-icn-ess icon-ion-ios-checkmark text-15"
                                    />
                                  </span>
                                  <span className="icon-list-text" />
                                </li>
                                <li className="pb-20 mt-20 flex justify-center text-green-500 icon-list-item">
                                  <span className="icon-list-icon flex">
                                    <i
                                      aria-hidden="true"
                                      className="lqd-icn-ess icon-ion-ios-checkmark text-15"
                                    />
                                  </span>
                                  <span className="icon-list-text" />
                                </li>
                                <li className="pb-20 mt-20 flex justify-center text-green-500 icon-list-item">
                                  <span className="icon-list-icon flex">
                                    <i
                                      aria-hidden="true"
                                      className="lqd-icn-ess icon-ion-ios-checkmark text-15"
                                    />
                                  </span>
                                  <span className="icon-list-text" />
                                </li>
                                <li className="mt-20 flex justify-center text-green-500 icon-list-item">
                                  <span className="icon-list-icon flex">
                                    <i
                                      aria-hidden="true"
                                      className="lqd-icn-ess icon-ion-ios-checkmark text-15"
                                    />
                                  </span>
                                  <span className="icon-list-text" />
                                </li>
                              </ul>
                            </div>
                            <div className="mt-55 mb-40 hidden lg:block">
                              <ul className="icon-list-items reset-ul">
                                <li className="pb-20 text-black icon-list-item">
                                  <span className="icon-list-text">
                                    Unlimited Photos and Videos
                                  </span>
                                </li>
                                <li className="pb-20 mt-20 text-black icon-list-item">
                                  <span className="icon-list-text">
                                    99.9 Uptime Guarantee
                                  </span>
                                </li>
                                <li className="pb-20 mt-20 text-black icon-list-item">
                                  <span className="icon-list-text">
                                    Unlimited Storage
                                  </span>
                                </li>
                                <li className="pb-20 mt-20 text-black icon-list-item">
                                  <span className="icon-list-text">
                                    Extended Documentation
                                  </span>
                                </li>
                                <li className="pb-20 mt-20 text-black icon-list-item">
                                  <span className="icon-list-text">
                                    Full Service Payroll
                                  </span>
                                </li>
                                <li className="pb-20 mt-20 text-black icon-list-item">
                                  <span className="icon-list-text">
                                    Self Service Support
                                  </span>
                                </li>
                                <li className="mt-20 text-black icon-list-item">
                                  <span className="icon-list-text">
                                    Encryption for messages
                                  </span>
                                </li>
                              </ul>
                            </div>
                            <a
                              href="./page-start-hub-3-pricing.html"
                              className="btn btn-underlined border-thin text-darkpink text-13 module-btn-3"
                              target="_blank"
                            >
                              <span
                                className="btn-txt"
                                data-text="Select Premium Plan"
                              >
                                Select Premium Plan
                              </span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col col-12 pt-50">
              <div className="flex flex-wrap items-center justify-center">
                <div className="mr-25 px-15 rounded-100 bg-purple-100 ld-fancy-heading relative">
                  <p className="m-0 text-14 font-normal text-purple-500 ld-fh-element relative">
                    Contact
                  </p>
                </div>
                <div className="ld-fancy-heading relative">
                  <p className="m-0 text-15 ld-fh-element relative">
                    Looking for a corporate solution?{" "}
                    <a href="#" className="underline">
                      Contact us.
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
import bannerImg from "../../assets/images/banner-image.png";
import bannerShape from "../../assets/images/shape-Image-764.png";
import bannerLines from "../../assets/images/shape-Lines.svg";
const Hero = () => {
  return (
    <section
      className="lqd-section banner pt-100"
      id="banner"
      style={{ backgroundImage: "linear-gradient(180deg, #fff 0%, #F1EAFF 100%)" }}
    >
      <div
        className="background-overlay bg-no-repeat bg-cover opacity-100 transition-all"
        style={{
          backgroundImage: 'url("./assets/images/banner-image.png")'
        }}
      />
      <div className="lqd-particles-bg-wrap lqd-overlay flex pointer-events-none" />
      <div className="ld-particles-container lqd-particles-as-bg lqd-overlay flex">
        <div
          className="ld-particles-inner lqd-overlay flex pointer-events-none"
          id="lqd-particle-banner"
          data-particles="true"
          data-particles-options='{"particles": {"number": {"value" : 4} , "color": {"value" : ["#FDA44C", "#604CFD", "#F85976", "#0FBBB4"]} , "shape": {"type" : ["circle"]} , "opacity": {"value" : 1} , "size": {"value" : 4}} , "interactivity" : [], "retina_detect": true}'
        />
      </div>
      <div className="container">
        <div className="row">
          <div
            className="col col-12 col-xl-6 text-center xxl:text-start"
            data-custom-animations="true"
            data-ca-options='{"animationTarget": ".btn, h1, p", "ease": "power4.out", "initValues": {"y": "40px", "rotationY" : 53, "opacity" : 0} , "animations": {"y": "0px", "rotationY" : 0, "opacity" : 1}}'
          >
            <a
              href="https://www.youtube.com/watch?v=WaSvMAl4zPw"
              className="btn btn-naked btn-icon-left btn-icon-circle btn-icon-custom-size btn-icon-bordered btn-icon-border-thicker text-15 leading-3em text-primary fresco"
            >
              <span className="btn-txt" data-text="See how it works">
                See how it works
              </span>
              <span className="btn-icon mr-10 w-45 h-45 border-2">
                <i aria-hidden="true" className="lqd-icn-ess icon-ion-ios-play" />
              </span>
            </a>
            <div className="spacer w-full">
              <div className="h-30" />
            </div>
            <div className="ld-fancy-heading relative">
              <h1 className="mb-25 ld-fh-element relative">
                <span>Don' Just Adapt to the Future</span>
                <br />
                <span
                  className="gradient-word"
                  style={{
                    backgroundImage:
                      "linear-gradient(to left, #E6A042, #FA6E30, #E7BABB, #AC99E3, #8296D6)"
                  }}
                >
                  Create It
                </span>
              </h1>
            </div>
            <div className="ld-fancy-heading relative">
              <p className="font-medium mb-25 pr-30percent ld-fh-element relative">
              Crack the Social Media Code. The Ultimate Cheat Sheet Inside.
              </p>
            </div>
            <div className="flex flex-wrap justify-start lg:justify-center">
              <a
                href="#download"
                className="btn btn-solid btn-hover-txt-liquid-x btn-icon-left text-15 leading-1em text-white rounded-6 bg-gray-900 hover:bg-white hover:text-primary"
                data-localscroll="true"
              >
                <span
                  className="btn-txt text-start"
                  data-text="Available on the Apple Store"
                  data-split-text="true"
                  data-split-options='{"type":  "chars, words"}'
                >
                  {" "}
                  <small>Available on the</small>
                  <span>Apple Store</span>
                </span>
                <span className="btn-icon text-24">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                    <path d="M318.7 268.7c-.2-36.7 16.4-64.4 50-84.8-18.8-26.9-47.2-41.7-84.7-44.6-35.5-2.8-74.3 20.7-88.5 20.7-15 0-49.4-19.7-76.4-19.7C63.3 141.2 4 184.8 4 273.5q0 39.3 14.4 81.2c12.8 36.7 59 126.7 107.2 125.2 25.2-.6 43-17.9 75.8-17.9 31.8 0 48.3 17.9 76.4 17.9 48.6-.7 90.4-82.5 102.6-119.3-65.2-30.7-61.7-90-61.7-91.9zm-56.6-164.2c27.3-32.4 24.8-61.9 24-72.5-24.1 1.4-52 16.4-67.9 34.9-17.5 19.8-27.8 44.3-25.6 71.9 26.1 2 49.9-11.4 69.5-34.3z" />
                  </svg>
                </span>
              </a>
              <a
                href="#contact-modal"
                className="btn btn-naked btn-icon-right btn-hover-swp ml-30 text-14 text-slate-400"
                data-lity="#contact-modal"
              >
                <span className="btn-txt" data-text="Sign up — It's Free">
                  Sign up — It's Free
                </span>
                <span className="btn-icon">
                  <i
                    aria-hidden="true"
                    className="lqd-icn-ess icon-ion-ios-arrow-forward"
                  />
                </span>
                <span className="btn-icon">
                  <i
                    aria-hidden="true"
                    className="lqd-icn-ess icon-ion-ios-arrow-forward"
                  />
                </span>
              </a>
            </div>
          </div>
          <div className="col col-12 col-xl-6 sm:hidden">
            <div className="relative flex w-full h-full transition-bg justify-start lg:justify-center module-img">
              <div className="relative w-400 px-0">
                <div className="lqd-imggrp-img-container inline-flex relative items-center justify-center transform-style-3d">
                  <figure className="w-full relative" data-stacking-factor={1}>
                    <img
                      width={795}
                      height={1182}
                      src= {bannerImg}
                      alt="3D shape"
                    />
                  </figure>
                </div>
              </div>
              <div className="absolute w-auto max-w-100 bottom-100 right-120">
                <div className="lqd-imggrp-single block relative">
                  <div className="lqd-imggrp-img-container inline-flex relative items-center justify-center">
                    <figure className="w-full relative">
                      <img
                        width={163}
                        height={114}
                        src={bannerLines}
                        alt="3D shape"
                      />
                    </figure>
                  </div>
                </div>
              </div>
              <div className="absolute w-auto top-15percent -right-80percent">
                <div className="lqd-imggrp-single block relative" data-float="ease">
                  <div className="lqd-imggrp-img-container inline-flex relative items-center justify-center">
                    <figure className="w-full relative">
                      <img
                        width={467}
                        height={467}
                        src={bannerShape}
                        alt="3D shape"
                      />
                    </figure>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Hero;
const Header = () => {
  return (
    <header
      id="site-header"
      className="main-header"
      data-sticky-header="true"
      data-sticky-values-measured="false"
    >
      <section
        className="lqd-section lqd-hide-onstuck bg-no-repeat bg-top-right bg-darkpurple transition-all pt-10 md:hidden"
        style={{
          backgroundImage:
            'url("/images/shape-header-ball1.png")'
        }}
      >
        <div className="flex items-center justify-center">
          <div className="rounded-4 mr-20 ld-fancy-heading relative bg-pink-100">
            <p className="ld-fh-element relative text-black text-11 px-10 font-medium m-0">
              Limited Offer
            </p>
          </div>
          <div className="ld-fancy-heading relative">
            <p className="ld-fh-element relative text-13 font-medium leading-1em mr-10 mb-0 text-white-60">
              Sign up and receive 20% bonus discount on checkout.{" "}
            </p>
          </div>
          <a
            href="#"
            className="btn-underlined border-thin text-13 font-medium leading-1em text-white"
          >
            <span className="btn-txt" data-text="Join Hub">
              Join Hub
            </span>
          </a>
        </div>
        <div className="bg-white mt-10 border-bottom border-black-10 rounded-25 rounded-bottom-0 transition-all">
          <div className="w-full flex justify-between items-center">
            <div className="w-50percent flex items-center justify-start p-10">
              <div className="lqd-fancy-menu lqd-custom-menu relative lqd-menu-td-none ml-30 link-13 transition-opacity link-black">
                <ul className="reset-ul inline-ul">
                  <li className="mr-10 mb-10">
                    <a href="/privacy-policy" className="tracking-0/1 leading-16">
                      <span className="link-icon inline-flex hide-if-empty left-icon icon-next-to-label" />{" "}
                      Privacy Policy{" "}
                    </a>
                  </li>
                  <li>
                    <a href="#" className="tracking-0/1 leading-16">
                      <span className="link-icon inline-flex hide-if-empty left-icon icon-next-to-label" />{" "}
                      Contact{" "}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="w-50percent flex items-center justify-end p-10">
              <div className="flex justify-end">
                <div className="flex gap-25 mr-30 text-center w-auto">
                  <span className="grid-item flex">
                    <a
                      href="#"
                      className="rounded-10 bg-transparent icon leading-20 text-20 text-slate-300 transition-text hover:text-black"
                      target="_blank"
                    >
                      <span className="sr-only">Facebook-square</span>
                      <svg
                        className="text-slate-300 w-1em h-1em text-19"
                        xmlns="http://www.w3.org/2000/svg"
                        height="1em"
                        viewBox="0 0 448 512"
                      >
                        <path
                          d="M400 32H48A48 48 0 0 0 0 80v352a48 48 0 0 0 48 48h137.25V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.27c-30.81 0-40.42 19.12-40.42 38.73V256h68.78l-11 71.69h-57.78V480H400a48 48 0 0 0 48-48V80a48 48 0 0 0-48-48z"
                          fill="currentColor"
                        />
                      </svg>
                    </a>
                  </span>
                  <span className="grid-item flex">
                    <a
                      href="#"
                      className="rounded-10 bg-transparent icon leading-20 text-20 text-slate-300 transition-text hover:text-black"
                      target="_blank"
                    >
                      <span className="sr-only">Twitter</span>
                      <svg
                        className="text-slate-300 w-1em h-1em text-19"
                        xmlns="http://www.w3.org/2000/svg"
                        height="1em"
                        viewBox="0 0 512 512"
                      >
                        <path
                          d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"
                          fill="currentColor"
                        />
                      </svg>
                    </a>
                  </span>
                  <span className="grid-item flex">
                    <a
                      href="#"
                      className="rounded-10 bg-transparent icon leading-20 text-20 text-slate-300 transition-text hover:text-black"
                      target="_blank"
                    >
                      <span className="sr-only">Github</span>
                      <svg
                        className="text-slate-300 w-1em h-1em text-19"
                        xmlns="http://www.w3.org/2000/svg"
                        height="1em"
                        viewBox="0 0 496 512"
                      >
                        <path
                          d="M165.9 397.4c0 2-2.3 3.6-5.2 3.6-3.3.3-5.6-1.3-5.6-3.6 0-2 2.3-3.6 5.2-3.6 3-.3 5.6 1.3 5.6 3.6zm-31.1-4.5c-.7 2 1.3 4.3 4.3 4.9 2.6 1 5.6 0 6.2-2s-1.3-4.3-4.3-5.2c-2.6-.7-5.5.3-6.2 2.3zm44.2-1.7c-2.9.7-4.9 2.6-4.6 4.9.3 2 2.9 3.3 5.9 2.6 2.9-.7 4.9-2.6 4.6-4.6-.3-1.9-3-3.2-5.9-2.9zM244.8 8C106.1 8 0 113.3 0 252c0 110.9 69.8 205.8 169.5 239.2 12.8 2.3 17.3-5.6 17.3-12.1 0-6.2-.3-40.4-.3-61.4 0 0-70 15-84.7-29.8 0 0-11.4-29.1-27.8-36.6 0 0-22.9-15.7 1.6-15.4 0 0 24.9 2 38.6 25.8 21.9 38.6 58.6 27.5 72.9 20.9 2.3-16 8.8-27.1 16-33.7-55.9-6.2-112.3-14.3-112.3-110.5 0-27.5 7.6-41.3 23.6-58.9-2.6-6.5-11.1-33.3 2.6-67.9 20.9-6.5 69 27 69 27 20-5.6 41.5-8.5 62.8-8.5s42.8 2.9 62.8 8.5c0 0 48.1-33.6 69-27 13.7 34.7 5.2 61.4 2.6 67.9 16 17.7 25.8 31.5 25.8 58.9 0 96.5-58.9 104.2-114.8 110.5 9.2 7.9 17 22.9 17 46.4 0 33.7-.3 75.4-.3 83.6 0 6.5 4.6 14.4 17.3 12.1C428.2 457.8 496 362.9 496 252 496 113.3 383.5 8 244.8 8zM97.2 352.9c-1.3 1-1 3.3.7 5.2 1.6 1.6 3.9 2.3 5.2 1 1.3-1 1-3.3-.7-5.2-1.6-1.6-3.9-2.3-5.2-1zm-10.8-8.1c-.7 1.3.3 2.9 2.3 3.9 1.6 1 3.6.7 4.3-.7.7-1.3-.3-2.9-2.3-3.9-2-.6-3.6-.3-4.3.7zm32.4 35.6c-1.6 1.3-1 4.3 1.3 6.2 2.3 2.3 5.2 2.6 6.5 1 1.3-1.3.7-4.3-1.3-6.2-2.2-2.3-5.2-2.6-6.5-1zm-11.4-14.7c-1.6 1-1.6 3.6 0 5.9 1.6 2.3 4.3 3.3 5.6 2.3 1.6-1.3 1.6-3.9 0-6.2-1.4-2.3-4-3.3-5.6-2z"
                          fill="currentColor"
                        />
                      </svg>
                    </a>
                  </span>
                </div>
                <div
                  className="ld-dropdown-menu flex relative mr-30 link-15 link-black"
                  role="menubar"
                >
                  <span
                    className="ld-module-trigger text-black"
                    role="button"
                    data-ld-toggle="true"
                    data-bs-toggle="collapse"
                    data-bs-target="#dropdown-language"
                    aria-controls="dropdown-language"
                    aria-expanded="false"
                    data-toggle-options='{ "type":  "hoverFade"}'
                  >
                    <span className="text-13 ld-module-trigger-txt">
                      🇺🇸 English{" "}
                      <i className="lqd-icn-ess icon-ion-ios-arrow-down" />
                    </span>
                  </span>
                  <div
                    className="link-16 ld-module-dropdown left collapse absolute"
                    id="dropdown-language"
                    aria-expanded="false"
                    role="menuitem"
                  >
                    <div className="ld-dropdown-menu-content">
                      <ul>
                        <li>
                          <a href="#" target="_blank">
                            Dutch
                          </a>
                        </li>
                        <li>
                          <a href="#" target="_blank">
                            French
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="lqd-section bg-white border-bottom border-black-10 transition-all px-20 md:hidden">
        <div className="container-fluid p-0">
          <div className="flex flex-wrap justify-between link-medium">
            <div className="pl-15 py-25 module-logo flex navbar-brand-plain w-20percent">
              <a
                className="navbar-brand flex p-0 relative"
                href="/"
                rel="home"
              >
                <span className="navbar-brand-inner post-rel">
                  <img
                    width={171}
                    className="logo-default"
                    src="/images/Logo.png"
                    alt="Starthub Three"
                  />
                </span>
              </a>
            </div>
            <div className="flex items-center justify-end w-80percent">
              <div className="module-primary-nav flex">
                <div
                  className="navbar-collapse inline-flex p-0 lqd-submenu-default-style link-14 mr-110 link-medium"
                  id="main-header-collapse"
                  aria-expanded="false"
                  role="navigation"
                >
                  <ul
                    className="main-nav flex reset-ul inline-ul lqd-menu-counter-right lqd-menu-items-inline main-nav-hover-fade-inactive"
                    data-submenu-options='{"toggleType": "fade", "handler": "mouse-in-out"}'
                    data-localscroll="true"
                    data-localscroll-options='{"itemsSelector":"> li > a", "trackWindowScroll": true, "includeParentAsOffset": true}'
                  >
                    <li className="menu-item-has-children">
                      <a className="text-blue-600" href="/#features">
                        <span>Features</span>
                        <span className="submenu-expander bg-transparent">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width={21}
                            height={32}
                            viewBox="0 0 21 32"
                            style={{ width: "1em", height: "1em" }}
                          >
                            <path
                              fill="currentColor"
                              d="M10.5 18.375l7.938-7.938c.562-.562 1.562-.562 2.125 0s.562 1.563 0 2.126l-9 9c-.563.562-1.5.625-2.063.062L.437 12.562C.126 12.25 0 11.876 0 11.5s.125-.75.438-1.063c.562-.562 1.562-.562 2.124 0z"
                            />
                          </svg>
                        </span>
                        <span className="link-icon inline-flex hide-if-empty right-icon">
                          <i className="lqd-icn-ess icon-ion-ios-arrow-down" />
                        </span>
                      </a>
                    </li>
                    <li>
                      <a className="text-blue-600" href="/#payments">
                        For Business
                        <span className="link-badge text-badge">Hot</span>
                      </a>
                    </li>
                    <li>
                      <a
                        className="text-blue-600"
                        href="/about"
                      >
                        About
                        <span className="link-icon inline-flex hide-if-empty right-icon">
                          <i className="lqd-icn-ess icon-ion-ios-arrow-down" />
                        </span>
                      </a>
                    </li>
                    <li>
                      <a className="text-blue-600" href="/#testimonials">
                        Testimonials
                      </a>
                    </li>
                    <li>
                      <a className="text-blue-600" href="/#faq">
                        FAQ
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <a
                href="#download"
                className="btn btn-solid btn-sm btn-hover-txt-switch-change btn-hover-txt-switch btn-hover-txt-switch-y btn-icon-right text-13 rounded-4 text-white bg-primary"
                data-localscroll="true"
              >
                <span
                  className="btn-txt"
                  data-text="Download"
                  data-split-text="true"
                  data-split-options='{"type": "chars, words"}'
                >
                  Get the App
                </span>
                <span className="btn-icon text-1/5em -rotate-45">
                  <i
                    aria-hidden="true"
                    className="lqd-icn-ess icon-ion-ios-arrow-round-forward"
                  />
                </span>
              </a>
            </div>
          </div>
        </div>
      </section>
      <section className="lqd-section lqd-stickybar-wrap lqd-stickybar-left pointer-events-none md:hidden">
        <div className="w-full h-full static flex flex-grow-1 vertical-lr">
          <div className="w-full static flex flex-grow-1">
            <div className="w-full static flex flex-grow-1 flex-col p-10">
              <div className="w-full static flex items-center justify-center -rotate-180">
                <div className="w-auto">
                  <a
                    href="/#download"
                    className="btn btn-underlined border-thin box-shadow-none pointer-events-auto horizontal-tb rotate-90 font-medium text-14 leading-1/6em text-black"
                    data-lqd-interactive-color="true"
                    data-localscroll="true"
                  >
                    <span className="btn-txt" data-text="Download Hub App">
                      Download Hub App
                    </span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="lqd-section lqd-stickybar-wrap lqd-stickybar-right pointer-events-none md:hidden">
        <div className="flex items-center justify-center w-full h-full flex-grow-1 vertical-lr -rotate-180">
          <div
            className="lqd-scrl-indc flex whitespace-nowrap lqd-scrl-indc-style-dot"
            data-lqd-scroll-indicator="true"
          >
            <a
              className="pointer-events-auto text-black"
              href="/#wrap"
              data-localscroll="true"
              data-lqd-interactive-color="true"
            >
              <span className="lqd-scrl-indc-inner flex items-center text-inherit">
                <span className="lqd-scrl-indc-txt">scroll</span>
                <span className="lqd-scrl-indc-line w-1 h-60 relative text-inherit bg-black-30">
                  <span className="lqd-scrl-indc-el absolute -top-5 -left-5 rounded-8 inline-block w-10 h-10 bg-current-color" />
                </span>
              </span>
            </a>
          </div>
        </div>
      </section>
      <div className="lqd-mobile-sec relative">
        <div className="bg-white lqd-mobile-sec-inner navbar-header flex items-stretch w-full">
          <div className="lqd-mobile-modules-container empty" />
          <button
            type="button"
            className="navbar-toggle collapsed nav-trigger style-mobile flex relative items-center justify-end p-0 bg-transparent border-none text-13"
            data-ld-toggle="true"
            data-bs-toggle="collapse"
            data-bs-target="#lqd-mobile-sec-nav"
            aria-expanded="false"
            data-toggle-options='{ "changeClassnames":  {"html":  "mobile-nav-activated"} }'
          >
            <span className="sr-only">Menu</span>
            <span className="bars inline-block relative z-1">
              <span className="bars-inner flex flex-col w-full h-full">
                <span className="bar inline-block" />
                <span className="bar inline-block" />
                <span className="bar inline-block" />
              </span>
            </span>
          </button>
          <a className="navbar-brand flex relative" href="/">
            <span className="navbar-brand-inner">
              <img
                width={171}
                className="logo-default"
                src="/images/Logo.png"
                alt="Starthub Three"
              />
            </span>
          </a>
        </div>
        <div className="lqd-mobile-sec-nav w-full absolute z-10">
          <div
            className="mobile-navbar-collapse navbar-collapse collapse w-full"
            id="lqd-mobile-sec-nav"
            aria-expanded="false"
            role="navigation"
          >
            <ul
              id="mobile-primary-nav"
              className="reset-ul lqd-mobile-main-nav main-nav nav"
              data-localscroll="true"
              data-localscroll-options='{"itemsSelector":"> li > a", "trackWindowScroll": true, "includeParentAsOffset": true}'
            >
              <li className="menu-item-has-children">
                <a href="/#features">
                  <span>Features</span>
                  <span className="submenu-expander absolute" />
                </a>
              </li>
              <li>
                <a href="/#payments">
                  For Business<span className="link-badge text-badge">Hot</span>
                </a>
              </li>
              <li>
                <a href="/">
                  Solutions
                  <span className="link-icon inline-flex hide-if-empty right-icon">
                    <i className="lqd-icn-ess icon-ion-ios-arrow-down" />
                  </span>
                </a>
              </li>
              <li>
                <a href="/#testimonials">Testimonials</a>
              </li>
              <li>
                <a href="/#faq">FAQ</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
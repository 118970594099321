export default function ClientSection() {
  return (
    <section className="lqd-section clients transition-all z-0 bg-white pb-50 pt-110">
      <div className="container">
        <div className="row">
          <div className="col col-12">
            <div className="row items-center text-start lg:text-center m-0">
              <div className="col col-12 text-center xxl:text-start p-0">
                <div className="ld-fancy-heading relative">
                  <h6 className="text-14 m-0 text-text ld-fh-element relative">
                    Join the 10.000+ companies trusting LandingHub
                  </h6>
                </div>
              </div>
              <div className="col col-12 p-0">
                <div className="spacer w-full">
                  <div className="w-full h-50" />
                </div>
              </div>
              <div className="col col-6 col-md-4 col-xl-2 p-0">
                <div className="lqd-imggrp-single block relative opacity-100">
                  <div className="lqd-imggrp-img-container inline-flex relative items-center justify-center">
                    <figure className="w-full relative">
                      <img
                        width={111}
                        height={33}
                        src="/images/client-spotify-1.svg"
                        alt="clients"
                      />
                    </figure>
                  </div>
                </div>
              </div>
              <div className="col col-6 col-md-4 col-xl-2 p-0">
                <div className="lqd-imggrp-single block relative opacity-100 module-img">
                  <div className="lqd-imggrp-img-container inline-flex relative items-center justify-center">
                    <figure className="w-full relative">
                      <img
                        width={65}
                        height={23}
                        src="/images/client-nike-1.svg"
                        alt="clients"
                      />
                    </figure>
                  </div>
                </div>
              </div>
              <div className="col col-6 col-md-4 col-xl-2 p-0">
                <div className="lqd-imggrp-single block relative opacity-100">
                  <div className="lqd-imggrp-img-container inline-flex relative items-center justify-center">
                    <figure className="w-full relative">
                      <img
                        width={75}
                        height={17}
                        src="/images/client-amd-logo-1.svg"
                        alt="clients"
                      />
                    </figure>
                  </div>
                </div>
              </div>
              <div className="col col-6 col-md-4 col-xl-2 p-0">
                <div className="lqd-imggrp-single block relative opacity-100 module-img">
                  <div className="lqd-imggrp-img-container inline-flex relative items-center justify-center">
                    <figure className="w-full relative">
                      <img
                        width={88}
                        height={23}
                        src="/images/client-netflix.svg"
                        alt="clients"
                      />
                    </figure>
                  </div>
                </div>
              </div>
              <div className="col col-6 col-md-4 col-xl-2 p-0">
                <div className="lqd-imggrp-single block relative opacity-100">
                  <div className="lqd-imggrp-img-container inline-flex relative items-center justify-center">
                    <figure className="w-full relative">
                      <img
                        width={96}
                        height={37}
                        src="/images/client-amd-logo-black.svg"
                        alt="clients"
                      />
                    </figure>
                  </div>
                </div>
              </div>
              <div className="col col-6 col-md-4 col-xl-2 p-0">
                <div className="lqd-imggrp-single block relative opacity-100 module-img">
                  <div className="lqd-imggrp-img-container inline-flex relative items-center justify-center">
                    <figure className="w-full relative">
                      <img
                        width={85}
                        height={25}
                        src="/images/client-logitech-1.svg"
                        alt="clients"
                      />
                    </figure>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
export default function FAQ() {
  return (
    <section
      className="lqd-section faq bg-white transition-all pt-50 pb-90"
      id="faq"
    >
      <div className="container">
        <div className="row">
          <div className="col col-12 text-center">
            <div className="mb-25 lqd-imggrp-single block relative">
              <div className="lqd-imggrp-img-container inline-flex relative items-center justify-center">
                <figure className="w-full relative">
                  <img
                    width={54}
                    height={54}
                    src="/images/shape-Message-1.svg"
                    alt="message icon"
                  />
                </figure>
              </div>
            </div>
            <div className="ld-fancy-heading relative">
              <h2 className="ld-fh-element mb-0/5em inline-block relative text-slate-600">
                Frequently Asked Questions
              </h2>
            </div>
            <div className="ld-fancy-heading relative">
              <p className="ld-fh-element mb-0/5em inline-block relative text-18 text-text">
                Use customer data to build great and solid product experiences that
                convert.
              </p>
            </div>
          </div>
          <div className="col col-12 p-0">
            <section className="lqd-section mt-60">
              <div className="container">
                <div className="row">
                  <div className="col col-12 col-md-6 p-30">
                    <div
                      className="accordion accordion-md accordion-side-spacing accordion-title-circle accordion-expander-lg accordion-active-has-shadow accordion-active-has-fill"
                      id="accordion-question-parent-1"
                      role="tablist"
                      aria-multiselectable="true"
                    >
                      <div className="accordion-item panel mb-20 active">
                        <div
                          className="accordion-heading"
                          role="tab"
                          id="heading-tab-1"
                        >
                          <h4 className="accordion-title">
                            <a
                              className="collapsed text-16 leading-2em bg-gray-100 "
                              role="button"
                              data-bs-toggle="collapse"
                              href="#collapse-question-tab-1"
                              aria-expanded="true"
                              aria-controls="collapse-question-tab-1"
                            >
                              <span className="accordion-title-txt">
                              What is Analytics Hunter?
                              </span>
                              <span className="text-22 text-brown-500 accordion-expander">
                                <i className="lqd-icn-ess icon-ion-ios-add" />
                                <i className="lqd-icn-ess icon-ion-ios-remove" />
                              </span>
                            </a>
                          </h4>
                        </div>
                        <div
                          id="collapse-question-tab-1"
                          className="accordion-collapse collapse show"
                          role="tabpanel"
                          aria-labelledby="heading-tab-1"
                          data-bs-parent="#accordion-question-parent-1"
                        >
                          <div className="text-15 accordion-content">
                          Analytics Hunter is a comprehensive social media and analytics tool designed to help businesses, influencers, brands, and entrepreneurs monitor and enhance their digital presence. It offers capabilities like identifying users, tracking posts, and analyzing e-commerce data.{" "}
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item panel mb-20">
                        <div
                          className="accordion-heading"
                          role="tab"
                          id="heading-tab-2"
                        >
                          <h4 className="accordion-title">
                            <a
                              className="collapsed text-16 leading-2em bg-gray-100 "
                              role="button"
                              data-bs-toggle="collapse"
                              href="#collapse-question-tab-2"
                              aria-expanded="false"
                              aria-controls="collapse-question-tab-2"
                            >
                              <span className="accordion-title-txt">
                              How does the real-time tracking feature work?

                              </span>
                              <span className="text-22 text-brown-500 accordion-expander">
                                <i className="lqd-icn-ess icon-ion-ios-add" />
                                <i className="lqd-icn-ess icon-ion-ios-remove" />
                              </span>
                            </a>
                          </h4>
                        </div>
                        <div
                          id="collapse-question-tab-2"
                          className="accordion-collapse collapse "
                          role="tabpanel"
                          aria-labelledby="heading-tab-2"
                          data-bs-parent="#accordion-question-parent-1"
                        >
                          <div className="text-15 accordion-content">
                          With Analytics Hunter, you can track data in real-time, whether it's social media engagement or e-commerce sales. This way, you can make immediate adjustments to your strategies.{" "}
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item panel mb-20">
                        <div
                          className="accordion-heading"
                          role="tab"
                          id="heading-tab-3"
                        >
                          <h4 className="accordion-title">
                            <a
                              className="collapsed text-16 leading-2em bg-gray-100 "
                              role="button"
                              data-bs-toggle="collapse"
                              href="#collapse-question-tab-3"
                              aria-expanded="false"
                              aria-controls="collapse-question-tab-3"
                            >
                              <span className="accordion-title-txt">
                              What makes your user identification unique?

                              </span>
                              <span className="text-22 text-brown-500 accordion-expander">
                                <i className="lqd-icn-ess icon-ion-ios-add" />
                                <i className="lqd-icn-ess icon-ion-ios-remove" />
                              </span>
                            </a>
                          </h4>
                        </div>
                        <div
                          id="collapse-question-tab-3"
                          className="accordion-collapse collapse "
                          role="tabpanel"
                          aria-labelledby="heading-tab-3"
                          data-bs-parent="#accordion-question-parent-1"
                        >
                          <div className="text-15 accordion-content">
                          Our patented user identification technology allows us to provide insights that are not only accurate but also deeply granular. From tracking user behavior to understanding engagement patterns, our technology ensures that you have all the data you need to make informed decisions.{" "}
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item panel">
                        <div
                          className="accordion-heading"
                          role="tab"
                          id="heading-tab-4"
                        >
                          <h4 className="accordion-title">
                            <a
                              className="collapsed text-16 leading-2em bg-gray-100 "
                              role="button"
                              data-bs-toggle="collapse"
                              href="#collapse-question-tab-4"
                              aria-expanded="false"
                              aria-controls="collapse-question-tab-4"
                            >
                              <span className="accordion-title-txt">
                              Do you offer custom-based plans, and how can they benefit me?

                              </span>
                              <span className="text-22 text-brown-500 accordion-expander">
                                <i className="lqd-icn-ess icon-ion-ios-add" />
                                <i className="lqd-icn-ess icon-ion-ios-remove" />
                              </span>
                            </a>
                          </h4>
                        </div>
                        <div
                          id="collapse-question-tab-4"
                          className="accordion-collapse collapse "
                          role="tabpanel"
                          aria-labelledby="heading-tab-4"
                          data-bs-parent="#accordion-question-parent-1"
                        >
                          <div className="text-15 accordion-content">
                          At AnalyticsHunter, we understand that every business is unique, which is why we offer custom-based plans tailored to meet your specific needs. Whether you require advanced analytics, additional user accounts, or specialized customer support, our team can create a plan that perfectly aligns with your objectives.{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col col-12 col-md-6 p-30">
                    <div
                      className="accordion accordion-md accordion-side-spacing accordion-title-circle accordion-expander-lg accordion-active-has-shadow accordion-active-has-fill"
                      id="accordion-question-parent-2"
                      role="tablist"
                      aria-multiselectable="true"
                    >
                      <div className="accordion-item panel mb-20">
                        <div
                          className="accordion-heading"
                          role="tab"
                          id="heading-tab-5"
                        >
                          <h4 className="accordion-title">
                            <a
                              className="collapsed text-16 leading-2em bg-gray-100 "
                              role="button"
                              data-bs-toggle="collapse"
                              href="#collapse-question-tab-5"
                              aria-expanded="false"
                              aria-controls="collapse-question-tab-5"
                            >
                              <span className="accordion-title-txt">
                              Can Analytics Hunter help me track e-commerce data?

                              </span>
                              <span className="text-22 text-brown-500 accordion-expander">
                                <i className="lqd-icn-ess icon-ion-ios-add" />
                                <i className="lqd-icn-ess icon-ion-ios-remove" />
                              </span>
                            </a>
                          </h4>
                        </div>
                        <div
                          id="collapse-question-tab-5"
                          className="accordion-collapse collapse "
                          role="tabpanel"
                          aria-labelledby="heading-tab-5"
                          data-bs-parent="#accordion-question-parent-2"
                        >
                          <div className="text-15 accordion-content">
                          Absolutely! Our tool integrates with popular e-commerce platforms like WooCommerce, Shopify, Bigcommerce or custom stores allowing you to closely monitor the impact of your social media posts on product sales.{" "}
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item panel mb-20">
                        <div
                          className="accordion-heading"
                          role="tab"
                          id="heading-tab-6"
                        >
                          <h4 className="accordion-title">
                            <a
                              className="collapsed text-16 leading-2em bg-gray-100 "
                              role="button"
                              data-bs-toggle="collapse"
                              href="#collapse-question-tab-6"
                              aria-expanded="false"
                              aria-controls="collapse-question-tab-6"
                            >
                              <span className="accordion-title-txt">
                              Can I schedule posts on multiple social media platforms?

                              </span>
                              <span className="text-22 text-brown-500 accordion-expander">
                                <i className="lqd-icn-ess icon-ion-ios-add" />
                                <i className="lqd-icn-ess icon-ion-ios-remove" />
                              </span>
                            </a>
                          </h4>
                        </div>
                        <div
                          id="collapse-question-tab-6"
                          className="accordion-collapse collapse "
                          role="tabpanel"
                          aria-labelledby="heading-tab-6"
                          data-bs-parent="#accordion-question-parent-2"
                        >
                          <div className="text-15 accordion-content">
                          Yes, Analytics Hunter allows you to schedule posts across different social media platforms. You can also monitor how each post performs, helping you to optimize future content.{" "}
                          </div>
                        </div>
                      </div>
                      <div className="accordion-item panel">
                        <div
                          className="accordion-heading"
                          role="tab"
                          id="heading-tab-7"
                        >
                          <h4 className="accordion-title">
                            <a
                              className="collapsed text-16 leading-2em bg-gray-100 "
                              role="button"
                              data-bs-toggle="collapse"
                              href="#collapse-question-tab-7"
                              aria-expanded="false"
                              aria-controls="collapse-question-tab-7"
                            >
                              <span className="accordion-title-txt">
                              Can I track performance for different accounts?

                              </span>
                              <span className="text-22 text-brown-500 accordion-expander">
                                <i className="lqd-icn-ess icon-ion-ios-add" />
                                <i className="lqd-icn-ess icon-ion-ios-remove" />
                              </span>
                            </a>
                          </h4>
                        </div>
                        <div
                          id="collapse-question-tab-7"
                          className="accordion-collapse collapse "
                          role="tabpanel"
                          aria-labelledby="heading-tab-7"
                          data-bs-parent="#accordion-question-parent-2"
                        >
                          <div className="text-15 accordion-content">
                          Yes, Analytics Hunter allows you to manage and track performance for multiple accounts. You can even compare how the same or different content performs across these accounts.{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
          <div className="col col-12">
            <div className="flex flex-wrap justify-center items-center pt-30">
              <div className="mr-25 px-15 rounded-100 bg-brown-300 ld-fancy-heading relative">
                <p className="text-15 font-normal text-brown-500 m-0 ld-fh-element relative">
                  Contact
                </p>
              </div>
              <div className="ld-fancy-heading relative">
                <p className="text-15 m-0 ld-fh-element relative">
                  Looking for a corporate solution?{" "}
                  <a href="#" className="underline">
                    Contact us.
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
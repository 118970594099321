import { useEffect, useRef } from "react";
import Hero from "./components/hero/hero";
import Footer from "./components/layout/footer";
import Header from "./components/layout/header";
import FixedNavigation from "./components/layout/fixedNavigation";
import ClientSection from "./components/home/clientSection";
import FeaturesSection from "./components/home/feauturesSection";
import PayementsSection from "./components/home/PayementsSection";
import CarousselLG from "./components/home/carousselLg";
import Solutions from "./components/home/solution";
import Subscribe from "./components/home/subscribe";
import TabBox from "./components/home/tabBox";
import Pricing from "./components/home/pricing";
import Testimonials from "./components/home/testimonials";
import FAQ from "./components/home/faq";
import Download from "./components/home/download";
import Blog from "./components/home/blog";
import CustomCursor from "./components/layout/customCursor";

function App() {
  const body = useRef(null);

  useEffect(() => {
    // List of script sources you want to load
    const scriptSources = [
      './js/theme.js'
    ];

    const scripts = scriptSources.map(src => {
      const script = document.createElement('script');
      script.src = src;
      return script;
    });

    if (body.current) {
      scripts.forEach(script => document.querySelector('#script').appendChild(script));
    }

    return () => {
      scripts.forEach(script => document.querySelector('#script').removeChild(script));
    };
  }, [body]);

  return (
    <div id="wrap" className="bg-white">
      <div className="lqd-sticky-placeholder hidden" />
      {/* <Header /> */}

      <main class="content bg-white" id="lqd-site-content" ref={body}>
				<div id="qd-contents-wrap">
          <Hero />
          <FixedNavigation />
          <ClientSection />
          <FeaturesSection />
          <PayementsSection />
          <CarousselLG />
          <Solutions />
          <Subscribe />
          <TabBox />
          <Pricing />
          <Testimonials />
          <FAQ />
          <Blog />
          <Download />
        </div>
        <template id="lqd-temp-sticky-header-sentinel">
          <div className="lqd-sticky-sentinel invisible absolute pointer-events-none" />
        </template>
      </main>



      {/* <Footer /> */}
      <div id="script">

      </div>
      {/* <CustomCursor /> */}
    </div>
  );
}

export default App;

const Footer = () => {
  return (
    <footer
      id="site-footer"
      className="main-footer bg-transparent text-white"
      style={{
        backgroundImage: "linear-gradient(120deg, #323945 78%, #514A65 100%)"
      }}
    >
      <div
        className="transition-all bg-cover pt-120 pb-70 module-bg"
        style={{
          backgroundImage:
            'url("/images/footer-bg-shapes.svg")'
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col col-12 p-0">
              <section className="lqd-section footer-content flex flex-wrap items-start">
                <div
                  className="w-50percent flex p-10 lg:w-full"
                  data-custom-animations="true"
                  data-ca-options='{"animationTarget": ".animation-element", "ease": "power4.out", "initValues": {"y": "35px", "opacity" : 0} , "animations": {"y": "0px", "opacity" : 1}}'
                >
                  <div className="w-full flex flex-col mr-60 sm:mr-0">
                    <div className="ld-fancy-heading relative animation-element">
                      <h2
                        className="text-46 leading-1em mb-60 tracking-0 text-white ld-fh-element relative lqd-highlight-custom lqd-highlight-custom-2"
                        data-inview="true"
                        data-transition-delay="true"
                        data-delay-options=' {"elements": ".lqd-highlight-inner", "delayType": "transition"}'
                      >
                        <span>Join Analytics Hunter now and go</span>
                        <mark className="lqd-highlight">
                          <span className="lqd-highlight-txt">beyond.</span>
                          <span className="left-0 -bottom-20 lqd-highlight-inner">
                            <svg
                              className="lqd-highlight-pen"
                              width={51}
                              height={51}
                              viewBox="0 0 51 51"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M36.204 1.044C32.02 2.814 5.66 31.155 4.514 35.116c-.632 2.182-1.75 5.516-2.483 7.409-3.024 7.805-1.54 9.29 6.265 6.265 1.893-.733 5.227-1.848 7.41-2.477 3.834-1.105 4.473-1.647 19.175-16.27 0 0 10.63-10.546 15.21-15.125C53 8.997 42.021-1.418 36.203 1.044Zm7.263 5.369c3.56 3.28 4.114 4.749 2.643 6.995l-1.115 1.7-4.586-4.543-4.585-4.544 1.42-1.157C39.311 3.18 40.2 3.4 43.467 6.413ZM37.863 13.3l4.266 4.304-11.547 11.561-11.547 11.561-4.48-4.446-4.481-4.447 11.404-11.418c6.273-6.28 11.566-11.42 11.762-11.42.197 0 2.277 1.938 4.623 4.305ZM12.016 39.03l3.54 3.584-3.562 1.098-5.316 1.641c-1.665.516-1.727.455-1.211-1.21l1.614-5.226c1.289-4.177.685-4.191 4.935.113Z" />
                            </svg>
                            <svg
                              className="lqd-highlight-brush-svg lqd-highlight-brush-svg-2"
                              width={233}
                              height={13}
                              viewBox="0 0 233 13"
                              xmlns="http://www.w3.org/2000/svg"
                              aria-hidden="true"
                              preserveAspectRatio="none"
                            >
                              <path
                                d="m.624 9.414-.312-2.48C0 4.454.001 4.454.002 4.454l.035-.005.102-.013.398-.047c.351-.042.872-.102 1.557-.179 1.37-.152 3.401-.368 6.05-.622C13.44 3.081 21.212 2.42 31.13 1.804 50.966.572 79.394-.48 113.797.24c34.387.717 63.927 2.663 84.874 4.429a1048.61 1048.61 0 0 1 24.513 2.34 641.605 641.605 0 0 1 8.243.944l.432.054.149.02-.318 2.479-.319 2.48-.137-.018c-.094-.012-.234-.03-.421-.052a634.593 634.593 0 0 0-8.167-.936 1043.26 1043.26 0 0 0-24.395-2.329c-20.864-1.76-50.296-3.697-84.558-4.413-34.246-.714-62.535.332-82.253 1.556-9.859.612-17.574 1.269-22.82 1.772-2.622.251-4.627.464-5.973.614a213.493 213.493 0 0 0-1.901.22l-.094.01-.028.004Z"
                                fill="#49C193"
                              />
                            </svg>
                          </span>
                        </mark>
                      </h2>
                    </div>
                    <a
                      href="#contact-modal"
                      className="btn btn-solid btn-hover-txt-liquid-y btn-custom-size btn-icon-right btn-hover-swp bg-white text-accent rounded-6 text-13 w-240 h-55 animation-element"
                      data-lity="#contact-modal"
                    >
                      <span
                        className="btn-txt"
                        data-text="Join the Community"
                        data-split-text="true"
                        data-split-options='{"type":  "chars, words"}'
                      >
                        Get Started
                      </span>
                      <span className="btn-icon tex-18">
                        <i
                          aria-hidden="true"
                          className="lqd-icn-ess icon-md-arrow-forward"
                        />
                      </span>
                      <span className="btn-icon mr-10 tex-18">
                        <i
                          aria-hidden="true"
                          className="lqd-icn-ess icon-md-arrow-forward"
                        />
                      </span>
                    </a>
                    <div className="w-full flex items-center justify-between mt-40">
                      <div className="lqd-imggrp-single flex flex-grow-1 relative w-33percent sm:justify-start">
                        <div className="lqd-imggrp-img-container inline-flex relative items-center justify-center">
                          <figure className="w-full relative animation-element">
                            <img
                              width={101}
                              height={30}
                              src="/images/client-spotify-2.svg"
                              alt="footer clients"
                            />
                          </figure>
                        </div>
                      </div>
                      <div className="lqd-imggrp-single flex flex-grow-1 relative w-33percent sm:justify-center">
                        <div className="lqd-imggrp-img-container inline-flex relative items-center justify-center">
                          <figure className="w-full relative animation-element">
                            <img
                              width={60}
                              height={21}
                              src="/images/client-nike-3.svg"
                              alt="footer clients"
                            />
                          </figure>
                        </div>
                      </div>
                      <div className="lqd-imggrp-single flex flex-grow-1 relative w-33percent sm:justify-end">
                        <div className="lqd-imggrp-img-container inline-flex relative items-center justify-center">
                          <figure className="w-full relative animation-element">
                            <img
                              width={69}
                              height={16}
                              src="/images/client-amd-logo-3.svg"
                              alt="footer clients"
                            />
                          </figure>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-50percent flex flex-col p-10 lg:w-full module-last">
                  <div className="flex flex-wrap flex-col ml-200 lg:ml-0">
                    <div
                      data-custom-animations="true"
                      data-ca-options='{"animationTarget": ".iconbox", "startDelay" : 1000, "ease": "power4.out", "initValues": {"opacity" : 0} , "animations": {"opacity" : 1}}'
                    >
                      <div className="iconbox flex flex-grow-1 relative flex-wrap items-center iconbox-inline iconbox-square mb-10 text-start">
                        <div className="iconbox-icon-wrap mr-15">
                          <div className="iconbox-icon-container inline-flex relative z-1 w-45 h-45 text-18 rounded-6 text-white module-icon-bg-1">
                            <i
                              aria-hidden="true"
                              className="text-white-20 lqd-icn-ess icon-ion-ios-checkmark"
                            />
                          </div>
                        </div>
                        <h3 className="lqd-iconbox-heading text-17 font-medium leading-30 tracking-0 m-0 text-white-20">
                        Predict Your Next Viral Hit.{" "}
                        </h3>
                      </div>
                    </div>
                    <div
                      data-custom-animations="true"
                      data-ca-options='{"animationTarget": ".iconbox", "startDelay" : 700, "ease": "power4.out", "initValues": {"opacity" : 0} , "animations": {"opacity" : 1}}'
                    >
                      <div className="iconbox flex flex-grow-1 relative flex-wrap items-center iconbox-inline iconbox-square mb-10 text-start">
                        <div className="iconbox-icon-wrap mr-15">
                          <div className="iconbox-icon-container inline-flex relative z-1 w-45 h-45 text-18 rounded-6 text-white module-icon-bg-2">
                            <i
                              aria-hidden="true"
                              className="text-white-50 lqd-icn-ess icon-ion-ios-checkmark"
                            />
                          </div>
                        </div>
                        <h3 className="lqd-iconbox-heading text-17 font-medium leading-30 tracking-0 m-0 text-white-50">
                        Real-time Data Analytics.{" "}
                        </h3>
                      </div>
                    </div>
                    <div
                      data-custom-animations="true"
                      data-ca-options='{"animationTarget": ".iconbox", "startDelay" : 300, "ease": "power4.out", "initValues": {"opacity" : 0} , "animations": {"opacity" : 1}}'
                    >
                      <div className="iconbox flex flex-grow-1 relative flex-wrap items-center iconbox-inline iconbox-square mb-10 text-start">
                        <div className="iconbox-icon-wrap mr-15">
                          <div className="iconbox-icon-container inline-flex relative z-1 w-45 h-45 text-18 rounded-6 text-white module-icon-bg-3">
                            <i
                              aria-hidden="true"
                              className="text-white lqd-icn-ess icon-ion-ios-checkmark"
                            />
                          </div>
                        </div>
                        <h3 className="lqd-iconbox-heading text-17 font-medium leading-30 tracking-0 m-0 text-white">
                        Target Where Competitors Don't.{" "}
                        </h3>
                      </div>
                    </div>
                    <div
                      data-custom-animations="true"
                      data-ca-options='{"animationTarget": ".iconbox", "startDelay" : 700, "ease": "power4.out", "initValues": {"opacity" : 0} , "animations": {"opacity" : 1}}'
                    >
                      <div className="iconbox flex flex-grow-1 relative flex-wrap items-center iconbox-inline iconbox-square mb-10 text-start">
                        <div className="iconbox-icon-wrap mr-15">
                          <div className="iconbox-icon-container inline-flex relative z-1 w-45 h-45 text-18 rounded-6 text-white module-icon-bg-4">
                            <i
                              aria-hidden="true"
                              className="text-white-50 lqd-icn-ess icon-ion-ios-checkmark"
                            />
                          </div>
                        </div>
                        <h3 className="lqd-iconbox-heading text-17 font-medium leading-30 tracking-0 m-0 text-white-50">
                        Schedule, Track, and Analyze.{" "}
                        </h3>
                      </div>
                    </div>
                    <div
                      data-custom-animations="true"
                      data-ca-options='{"animationTarget": ".iconbox", "startDelay" : 1000, "ease": "power4.out", "initValues": {"opacity" : 0} , "animations": {"opacity" : 1}}'
                    >
                      <div className="iconbox flex flex-grow-1 relative flex-wrap items-center iconbox-inline iconbox-square mb-10 text-start">
                        <div className="iconbox-icon-wrap mr-15">
                          <div className="iconbox-icon-container inline-flex relative z-1 w-45 h-45 text-18 rounded-6 text-white module-icon-bg-5">
                            <i
                              aria-hidden="true"
                              className="text-white-20 lqd-icn-ess icon-ion-ios-checkmark"
                            />
                          </div>
                        </div>
                        <h3 className="lqd-iconbox-heading text-17 font-medium leading-30 tracking-0 m-0 text-white-20">
                        Personal Social Media Consultant.{" "}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section className="lqd-section footer-line flex flex-wrap items-center border-bottom border-white-10 transition-all pt-30 pb-10">
                <div className="w-full flex flex-col p-10">
                  <div className="ld-fancy-heading relative">
                    <p className="text-14 font-semibold text-text ld-fh-element mb-0/5em inline-block relative">
                      Trusted by{" "}
                    </p>
                  </div>
                </div>
              </section>
              <section
                className="lqd-section footer-menu flex flex-wrap items-start pt-50"
                data-custom-animations="true"
                data-ca-options='{"animationTarget": ".animation-element", "ease": "power4.out", "initValues": {"y": "35px", "opacity" : 0} , "animations": {"y": "0px", "opacity" : 1}}'
              >
                <div className="w-20percent flex flex-col p-10 lg:w-33percent sm:w-50percent animation-element">
                  <div className="ld-fancy-heading relative">
                    <h5 className="text-10 uppercase tracking-1 text-white-50 mb-4em ld-fh-element relative">
                      COMPANY
                    </h5>
                  </div>
                  <div className="link-15 link-white-80 lqd-fancy-menu lqd-custom-menu relative lqd-menu-td-none">
                    <ul className="reset-ul" id="3e8baa6">
                      <li className="mb-10">
                        <a href="#">
                          <span className="link-icon inline-flex hide-if-empty left-icon icon-next-to-label" />
                          <span>Contact Us</span>
                        </a>
                      </li>
                      <li className="mb-10">
                        <a href="#">
                          <span className="link-icon inline-flex hide-if-empty left-icon icon-next-to-label" />
                          <span>FAQ</span>
                        </a>
                      </li>
                      <li className="mb-10">
                        <a href="#">
                          <span className="link-icon inline-flex hide-if-empty left-icon icon-next-to-label" />
                          <span>Report A Bug</span>
                        </a>
                      </li>
                      <li className="mb-10">
                        <a href="#">
                          <span className="link-icon inline-flex hide-if-empty left-icon icon-next-to-label" />
                          <span>Careers</span>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <span className="link-icon inline-flex hide-if-empty left-icon icon-next-to-label" />
                          <span>About Us</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="w-15percent flex flex-col p-10 lg:w-33percent sm:w-50percent animation-element">
                  <div className="ld-fancy-heading relative">
                    <h5 className="text-10 uppercase tracking-1 text-white-50 mb-4em ld-fh-element relative">
                      SUPPORT
                    </h5>
                  </div>
                  <div className="link-15 link-white-80 lqd-fancy-menu lqd-custom-menu relative lqd-menu-td-none">
                    <ul className="reset-ul">
                      <li className="mb-10">
                        <a href="/about">
                          <span className="link-icon inline-flex hide-if-empty left-icon icon-next-to-label" />
                          <span>About</span>
                        </a>
                      </li>
                      <li className="mb-10">
                        <a href="/privacy-policy">
                          <span className="link-icon inline-flex hide-if-empty left-icon icon-next-to-label" />
                          <span>Privacy Policy</span>
                        </a>
                      </li>
                      <li className="mb-10">
                        <a href="/refund-policy">
                          <span className="link-icon inline-flex hide-if-empty left-icon icon-next-to-label" />
                          <span>Refund Policy</span>
                        </a>
                      </li>
                      <li className="mb-10">
                        <a href="/terms-of-service">
                          <span className="link-icon inline-flex hide-if-empty left-icon icon-next-to-label" />
                          <span>Terms of Service</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="w-15percent flex flex-col p-10 lg:w-33percent sm:w-50percent animation-element">
                  <div className="ld-fancy-heading relative">
                    <h5 className="text-10 uppercase tracking-1 text-white-50 mb-4em ld-fh-element relative">
                      SUPPORT
                    </h5>
                  </div>
                  <div className="link-15 link-white-80 lqd-fancy-menu lqd-custom-menu relative lqd-menu-td-none">
                    <ul className="reset-ul">
                      <li className="mb-10">
                        <a href="#">
                          <span className="link-icon inline-flex hide-if-empty left-icon icon-next-to-label" />
                          <span>In-Store Shop</span>
                        </a>
                      </li>
                      <li className="mb-10">
                        <a href="#">
                          <span className="link-icon inline-flex hide-if-empty left-icon icon-next-to-label" />
                          <span>Brands</span>
                        </a>
                      </li>
                      <li className="mb-10">
                        <a href="#">
                          <span className="link-icon inline-flex hide-if-empty left-icon icon-next-to-label" />
                          <span>Gift Cards</span>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <span className="link-icon inline-flex hide-if-empty left-icon icon-next-to-label" />
                          <span>Careers</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="w-15percent flex flex-col p-10 lg:w-33percent sm:w-full module-third animation-element">
                  <div className="ld-fancy-heading relative">
                    <h5 className="text-10 uppercase tracking-1 text-white-50 mb-4em ld-fh-element relative">
                      NEED HELP?
                    </h5>
                  </div>
                  <div className="ld-fancy-heading relative">
                    <h6 className="text-9 uppercase tracking-1 ld-fh-element mb-0/5em inline-block relative text-slate-200">
                      CALL US DIRECTLY
                    </h6>
                  </div>
                  <div className="ld-fancy-heading relative">
                    <p className="text-18 font-medium mb-1/75em text-white ld-fh-element relative">
                      (123) 567 8901
                    </p>
                  </div>
                  <div className="ld-fancy-heading relative">
                    <h6 className="text-9 uppercase tracking-1 ld-fh-element mb-0/5em inline-block relative text-slate-200">
                      CALL US DIRECTLY
                    </h6>
                  </div>
                  <div className="ld-fancy-heading relative">
                    <p className="text-18 font-medium mb-1/75em ld-fh-element relative">
                      info@liquid.com
                    </p>
                  </div>
                </div>
                <div className="w-35percent flex flex-col p-10 lg:w-full module-last animation-element">
                  <div className="ld-fancy-heading relative">
                    <h5 className="text-10 uppercase tracking-1 text-white-50 mb-4em ld-fh-element relative">
                      KEEP IN TOUCH
                    </h5>
                  </div>
                  <div className="ld-sf relative ld-sf--input-solid ld-sf--button-solid ld-sf--button-eql ld-sf--size-sm ld-sf--circle ld-sf--border-none ld-sf--button-show ld-sf--button-inside button-shrinked">
                    <form className="relative" method="post" action="#">
                      <p className="relative">
                        <input
                          type="email"
                          className="text-14 w-full bg-black-20 text-white"
                          name="email"
                          placeholder="Enter your email address"
                        />
                      </p>
                      <button
                        type="submit"
                        className="inline-flex items-center justify-center m-0 relative text-13 bg-blue-500"
                      >
                        <span className="submit-icon text-24">
                          <i className="lqd-icn-ess icon-ion-ios-arrow-round-forward text-24" />
                        </span>
                        <span className="ld-sf-spinner rounded-full absolute overflow-hidden">
                          <span className="block lqd-overlay flex rounded-full">
                            Sending{" "}
                          </span>
                        </span>
                      </button>
                    </form>
                  </div>
                </div>
              </section>
              <section
                className="lqd-section footer-info flex flex-wrap items-center justify-between pt-70"
                data-custom-animations="true"
                data-ca-options='{"animationTarget": ".animation-element", "ease": "power4.out", "initValues": {"y": "35px", "opacity" : 0} , "animations": {"y": "0px", "opacity" : 1}}'
              >
                <div className="w-20percent flex justify-start p-10 lg:w-full lg:justify-start animation-element">
                  <div className="mr-35 lqd-imggrp-single block relative">
                    <div className="lqd-imggrp-img-container inline-flex relative items-center justify-center">
                      <figure className="w-full relative">
                        <img
                          width={75}
                          height={21}
                          src="/images/hub-logo-orange.svg"
                          alt="starthub three"
                        />
                      </figure>
                    </div>
                  </div>
                  <div className="lqd-fancy-menu lqd-custom-menu relative lqd-menu-td-none">
                    <ul className="reset-ul inline-ul">
                      <li>
                        <a href="#" className="text-12 leading-1em text-white">
                          <span className="link-icon inline-flex hide-if-empty left-icon icon-next-to-label" />{" "}
                          English / USD{" "}
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="w-80percent flex flex-wrap p-10 lg:w-full animation-element">
                  <div className="ld-fancy-heading relative text-center w-80percent lg:w-full lg:text-start module-copyright">
                    <p className="text-14 leading-1em font-normal text-white-70 ld-fh-element mb-0/5em inline-block relative">
                      © 2022 StartupHub. All images are for demo purposes.
                    </p>
                  </div>
                  <div className="flex gap-40 justify-end module-social w-20percent lg:justify-start lg:order-first">
                    <span className="grid-item">
                      <a
                        href="#"
                        className="icon social-icon social-icon-facebook-square text-22 hover:svg-white"
                        target="_blank"
                      >
                        <span className="sr-only">Facebook-square</span>
                        <svg
                          className="w-1em h-1em"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 448 512"
                        >
                          <path
                            d="M400 32H48A48 48 0 0 0 0 80v352a48 48 0 0 0 48 48h137.25V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 4.84v61h-31.27c-30.81 0-40.42 19.12-40.42 38.73V256h68.78l-11 71.69h-57.78V480H400a48 48 0 0 0 48-48V80a48 48 0 0 0-48-48z"
                            fill="#FFFFFFA6"
                          />
                        </svg>
                      </a>
                    </span>
                    <span className="grid-item">
                      <a
                        href="#"
                        className="icon social-icon social-icon-twitter text-22 hover:svg-white"
                        target="_blank"
                      >
                        <span className="sr-only">Twitter</span>
                        <svg
                          className="w-1em h-1em"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 512 512"
                        >
                          <path
                            d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"
                            fill="#FFFFFFA6"
                          />
                        </svg>
                      </a>
                    </span>
                    <span className="grid-item">
                      <a
                        href="#"
                        className="icon social-icon social-icon-instagram text-22 hover:svg-white"
                        target="_blank"
                      >
                        <span className="sr-only">Instagram</span>
                        <svg
                          className="w-1em h-1em"
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 448 512"
                        >
                          <path
                            d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"
                            fill="#FFFFFFA6"
                          />
                        </svg>
                      </a>
                    </span>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
import { useEffect, useRef } from "react";
import Hero from "./components/hero/hero";
import Footer from "./components/layout/footer";
import Header from "./components/layout/header";
import FixedNavigation from "./components/layout/fixedNavigation";
import ClientSection from "./components/home/clientSection";
import FeaturesSection from "./components/home/feauturesSection";
import PayementsSection from "./components/home/PayementsSection";
import CarousselLG from "./components/home/carousselLg";
import Solutions from "./components/home/solution";
import Subscribe from "./components/home/subscribe";
import TabBox from "./components/home/tabBox";
import Pricing from "./components/home/pricing";
import Testimonials from "./components/home/testimonials";
import FAQ from "./components/home/faq";
import Download from "./components/home/download";
import Blog from "./components/home/blog";
import CustomCursor from "./components/layout/customCursor";

function About() {
  const body = useRef(null);

  useEffect(() => {
    // List of script sources you want to load
    const scriptSources = ["./js/theme.js"];

    const scripts = scriptSources.map((src) => {
      const script = document.createElement("script");
      script.src = src;
      script.async = true;
      return script;
    });

    if (body.current) {
      scripts.forEach((script) => document.body.appendChild(script));
    }

    return () => {
      scripts.forEach((script) => document.body.removeChild(script));
    };
  }, [body]);

  return (
    <div id="wrap" className="bg-white">
      <div
        className="titlebar border-bottom border-black-10"
        id="banner"
        style={{
          backgroundImage:
            "linear-gradient(180deg, #FF00C414 0%, #FFFFFF 100%)",
        }}
      >
        <div className="lqd-sticky-placeholder hidden" />
        {/* <Header /> */}
        <div className="titlebar-inner">
          <div className="container titlebar-container">
            <div className="row titlebar-container justify-center">
              <div className="col titlebar-col col-xs-12 col-sm-8 col-lg-8 text-center text-gray-400">
                <h1>About</h1>
       
                <div className="d-flex justify-content-center my-5">
                  <div
                    className=""
                  >
                    <p className="leading-20 lqd-color-accent">
                      Analytics Hunter is a leading social media analysis
                      platform that helps businesses and individuals optimize
                      their online presence and achieve their social media
                      marketing goals. With our comprehensive suite of tools and
                      features, users can track and analyze their social media
                      performance, import products from popular e-commerce
                      platforms such as Shopify, WooCommerce, and Amazon, and
                      track the performance of competitors.
                    </p>
                    <p className="leading-20 text-gray-400">
                      Our platform is not only useful for small businesses and
                      individuals looking to improve their social media presence
                      but also for digital agencies looking to provide
                      comprehensive social media analysis services to their
                      clients. With Analytics Hunter, digital agencies can
                      easily track and measure the success of their clients'
                      social media campaigns and provide valuable insights and
                      recommendations for improvement.
                    </p>
                    <p className="leading-20 text-gray-400">
                      At Analytics Hunter, we understand that social media can
                      be a daunting and time-consuming task for businesses and
                      individuals. That's why we are dedicated to providing a
                      user-friendly and intuitive platform that makes it easy to
                      track and analyze your social media performance. Our
                      platform provides a range of valuable insights and data,
                      including metrics on engagement, followers, reach, and
                      more. With this information, you can identify areas for
                      improvement and make informed decisions about your social
                      media strategy.
                    </p>
                    <p className="leading-20 text-gray-400">
                      In addition to our social media analysis tools, we also
                      offer a range of educational resources, including
                      tutorials, webinars, and one-on-one support from our team
                      of experts. Our goal is to help businesses and individuals
                      succeed online, and we are dedicated to providing the
                      tools, resources, and support needed to achieve that
                      success.
                    </p>
                    <p className="leading-20 text-gray-400">
                      Thank you for choosing Analytics Hunter for your social
                      media analysis needs. We look forward to helping you
                      succeed online.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <main className="content bg-white" id="lqd-site-content" ref={body}>
				{/* <div id="qd-contents-wrap">
          <FeaturesSection />
          <CarousselLG />
          <TabBox />
          <Testimonials />
          <FAQ />
        </div> */}
        <template id="lqd-temp-sticky-header-sentinel">
          <div className="lqd-sticky-sentinel invisible absolute pointer-events-none" />
        </template>
      </main>


      {/* <CustomCursor />

      <Footer /> */}
    </div>
  );
}

export default About;

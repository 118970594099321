import scrollImg1 from "../../assets/images/tab-scroll-1.jpg";
import scrollImg2 from "../../assets/images/tab-scroll-2.jpg";
import scrollImg3 from "../../assets/images/tab-scroll-3.jpg";

export default function Solutions() {
  return (
    <section
      className="lqd-section solutions bg-white transition-all lqd-section-scroll-activated"
      id="solutions"
      data-lqd-section-scroll="true"
      data-section-scroll-options='{"itemsSelector":  ".lqd-section-scroll-inner"}'
    >
      <div className="container p-0">
        <div className="lqd-section-scroll-inner module-img-1">
          <div className="container">
            <div className="row min-h-100vh items-center">
              <div className="col col-12 col-xl-6">
                <div
                  className="lqd-imggrp-single block relative perspective"
                  data-hover3d="true"
                >
                  <div className="lqd-imggrp-img-container inline-flex relative items-center justify-center transform-style-3d">
                    <figure
                      className="w-full relative"
                      data-stacking-factor={1}
                      data-reveal="true"
                      data-reveal-options='{"direction": "tb"}'
                    >
                      <img
                        width={1048}
                        height={1006}
                        src={scrollImg1}
                        alt="social media"
                      />
                    </figure>
                  </div>
                </div>
              </div>
              <div
                className="col col-12 col-xl-6 p-0"
                data-custom-animations="true"
                data-ca-options='{"animationTarget": ".animation-element", "ease": "power4.out", "initValues": {"y": "35px", "rotationY" : -35, "opacity" : 0} , "animations": {"y": "0px", "rotationY" : 0, "opacity" : 1}}'
              >
                <div className="flex flex-wrap py-10 pr-160 pl-80 module-col">
                  {/* <div className="ld-fancy-heading relative animation-element">
                    <p className="text-18 mb-15 text-dark ld-fh-element relative">
                      <strong>— 87.6</strong>&nbsp;Customer Satisfaction
                    </p>
                  </div> */}
                  <div className="ld-fancy-heading relative animation-element">
                    <h2
                      className="text-48 tracking-1 ld-fh-element mb-0/5em inline-block relative"
                      data-inview="true"
                      data-transition-delay="true"
                      data-delay-options=' {"elements": ".lqd-highlight-inner", "delayType": "transition"}'
                    >
                      <mark className="lqd-highlight">
                        <span className="lqd-highlight-txt">The Missing Puzzle Piece</span>
                        <span className="bottom-0 left-0 lqd-highlight-inner bg-purple-200" />
                      </mark>
                       to Your Social Media Strategy
                    </h2>
                  </div>
                  <div className="ld-fancy-heading relative animation-element">
                    <p className="text-16 tracking-0 ld-fh-element mb-0/5em inline-block relative">
                    Feel like your social media strategy is incomplete? Analytics Hunter is the missing piece you've been searching for. Our advanced analytics allow you to understand your audience like never before, offering you the full picture of your online presence.{" "}
                    </p>
                  </div>
                  <div className="spacer w-full animation-element">
                    <div className="w-full h-50" />
                  </div>
                  <div className="w-full animation-element">
                    <a
                      href="#pricing"
                      className="btn btn-solid btn-hover-txt-switch-change btn-hover-txt-switch btn-hover-txt-switch-y btn-custom-size btn-icon-right text-13 w-240 h-55 rounded-6 bg-purple-200 text-purple-500 hover:text-white hover:bg-primary"
                      data-localscroll="true"
                    >
                      <span
                        className="btn-txt"
                        data-text="Limited Time Offer"
                        data-split-text="true"
                        data-split-options='{"type":  "chars, words"}'
                      >
                        Select Premium Plan
                      </span>
                      <span className="btn-icon text-1em">
                        <i
                          aria-hidden="true"
                          className="lqd-icn-ess icon-ion-ios-arrow-forward"
                        />
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="lqd-section-scroll-inner module-img-2">
          <div className="container">
            <div className="row min-h-100vh items-center">
              <div className="col col-12 col-xl-6">
                <div
                  className="lqd-imggrp-single block relative perspective"
                  data-hover3d="true"
                >
                  <div className="lqd-imggrp-img-container inline-flex relative items-center justify-center transform-style-3d">
                    <figure
                      className="w-full relative"
                      data-stacking-factor={1}
                      data-reveal="true"
                      data-reveal-options='{"direction": "tb"}'
                    >
                      <img
                        width={1048}
                        height={1006}
                        src={scrollImg2}
                        alt="mobile Development"
                      />
                    </figure>
                  </div>
                </div>
              </div>
              <div
                className="col col-12 col-xl-6 p-0"
                data-custom-animations="true"
                data-ca-options='{"animationTarget": ".animation-element", "ease": "power4.out", "initValues": {"y": "35px", "rotationY" : -35, "opacity" : 0} , "animations": {"y": "0px", "rotationY" : 0, "opacity" : 1}}'
              >
                <div className="flex flex-wrap py-10 pr-160 pl-80 module-col">
                  {/* <div className="ld-fancy-heading relative animation-element">
                    <p className="text-18 mb-15 ld-fh-element mb-0/5em inline-block relative">
                      <strong>— 87.6</strong>&nbsp;Customer Satisfaction
                    </p>
                  </div> */}
                  <div className="ld-fancy-heading relative animation-element">
                    <h2
                      className="text-48 tracking-1  ld-fh-element mb-0/5em inline-block relative"
                      data-inview="true"
                      data-transition-delay="true"
                      data-delay-options=' {"elements": ".lqd-highlight-inner", "delayType": "transition"}'
                    >
                      <mark className="lqd-highlight">
                        <span className="lqd-highlight-txt">Skyrocket</span>
                        <span className="bottom-0 left-0 lqd-highlight-inner bg-blue-200" />
                      </mark>{" "}
                       Your Sales with One Simple Dashboard
                    </h2>
                  </div>
                  <div className="ld-fancy-heading relative animation-element">
                    <p className="text-16 tracking-0 ld-fh-element mb-0/5em inline-block relative">
                    AnalyticsHunter makes it easy for you to monitor all your social media and e- commerce metrics in one place. With a single glance, get the pulse of your digital empire and make decisions that will send your sales through the roof.{" "}
                    </p>
                  </div>
                  <div className="spacer w-full animation-element">
                    <div className="w-full h-50" />
                  </div>
                  <div className="w-full animation-element">
                    <a
                      href="#pricing"
                      className="btn btn-solid btn-hover-txt-switch-change btn-hover-txt-switch btn-hover-txt-switch-y btn-custom-size btn-icon-right text-13 w-240 h-55 rounded-6 bg-blue-200 text-blue-500 hover:text-white hover:bg-primary"
                      data-localscroll="true"
                    >
                      <span
                        className="btn-txt"
                        data-text="Limited Time Offer"
                        data-split-text="true"
                        data-split-options='{"type":  "chars, words"}'
                      >
                        Select Premium Plan
                      </span>
                      <span className="btn-icon text-1em">
                        <i
                          aria-hidden="true"
                          className="lqd-icn-ess icon-ion-ios-arrow-forward"
                        />
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="lqd-section-scroll-inner module-img-3">
          <div className="container">
            <div className="row min-h-100vh items-center">
              <div className="col col-12 col-xl-6">
                <div
                  className="lqd-imggrp-single block relative perspective"
                  data-hover3d="true"
                >
                  <div className="lqd-imggrp-img-container inline-flex relative items-center justify-center transform-style-3d">
                    <figure
                      className="w-full relative"
                      data-stacking-factor={1}
                      data-reveal="true"
                      data-reveal-options='{"direction": "tb"}'
                    >
                      <img
                        width={1048}
                        height={1006}
                        src={scrollImg3}
                        alt="ecommerce solutions"
                      />
                    </figure>
                  </div>
                </div>
              </div>
              <div
                className="col col-12 col-xl-6 p-0"
                data-custom-animations="true"
                data-ca-options='{"animationTarget": ".animation-element", "ease": "power4.out", "initValues": {"y": "35px", "rotationY" : -35, "opacity" : 0} , "animations": {"y": "0px", "rotationY" : 0, "opacity" : 1}}'
              >
                <div className="flex flex-wrap py-10 pr-160 pl-80 module-col">
                  {/* <div className="ld-fancy-heading relative animation-element">
                    <p className="text-18 mb-15 text-dark ld-fh-element relative">
                      <strong>— 87.6</strong>&nbsp;Customer Satisfaction
                    </p>
                  </div> */}
                  <div className="ld-fancy-heading relative animation-element">
                    <h2
                      className="text-48 tracking-1  ld-fh-element mb-0/5em inline-block relative"
                      data-inview="true"
                      data-transition-delay="true"
                      data-delay-options=' {"elements": ".lqd-highlight-inner", "delayType": "transition"}'
                    >
                      <mark className="lqd-highlight">
                        <span className="lqd-highlight-txt">Unlock</span>
                        <span className="bottom-0 left-0 lqd-highlight-inner bg-red-200" />
                      </mark>{" "}
                       Your eCommerce Potential
                    </h2>
                  </div>
                  <div className="ld-fancy-heading relative animation-element">
                    <p className="text-16 tracking-0 ld-fh-element mb-0/5em inline-block relative">
                    Whether you're a Shopify aficionado or a WooCommerce wizard, AnalyticsHunter has got you covered. Seamlessly integrate your preferred platform and discover new ways to grow your online store.{" "}
                    </p>
                  </div>
                  <div className="spacer w-full animation-element">
                    <div className="w-full h-50" />
                  </div>
                  <div className="w-full animation-element">
                    <a
                      href="#pricing"
                      className="btn btn-solid btn-hover-txt-switch-change btn-hover-txt-switch btn-hover-txt-switch-y btn-custom-size btn-icon-right text-13 w-240 h-55 rounded-6 bg-red-200 text-red-500 hover:text-white hover:bg-primary"
                      data-localscroll="true"
                    >
                      <span
                        className="btn-txt"
                        data-text="Limited Time Offer"
                        data-split-text="true"
                        data-split-options='{"type":  "chars, words"}'
                      >
                        Select Premium Plan
                      </span>
                      <span className="btn-icon text-1em">
                        <i
                          aria-hidden="true"
                          className="lqd-icn-ess icon-ion-ios-arrow-forward"
                        />
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
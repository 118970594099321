export default function Download() {
  return (
    <section
      className="lqd-section download transition-all box-shadow-bottom text-center bg-gray-700"
      id="download"
    >
      <div className="w-full flex flex-wrap transition-all box-shadow-bottom rounded-25 rounded-top-0 border-top border-black-10 bg-white">
        <div className="container flex flex-wrap p-0">
          <div className="w-25percent flex p-10 border-right border-black-10 lg:w-50percent lg:border-bottom">
            <div className="py-30 iconbox flex items-center transition-all flex-grow-1 relative flex-col iconbox-default">
              <div className="iconbox-icon-wrap">
                <div className="iconbox-icon-container inline-flex mb-25 text-74 text-slate-400">
                  <svg
                    className="w-1em"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 496 512"
                  >
                    <path d="M165.9 397.4c0 2-2.3 3.6-5.2 3.6-3.3.3-5.6-1.3-5.6-3.6 0-2 2.3-3.6 5.2-3.6 3-.3 5.6 1.3 5.6 3.6zm-31.1-4.5c-.7 2 1.3 4.3 4.3 4.9 2.6 1 5.6 0 6.2-2s-1.3-4.3-4.3-5.2c-2.6-.7-5.5.3-6.2 2.3zm44.2-1.7c-2.9.7-4.9 2.6-4.6 4.9.3 2 2.9 3.3 5.9 2.6 2.9-.7 4.9-2.6 4.6-4.6-.3-1.9-3-3.2-5.9-2.9zM244.8 8C106.1 8 0 113.3 0 252c0 110.9 69.8 205.8 169.5 239.2 12.8 2.3 17.3-5.6 17.3-12.1 0-6.2-.3-40.4-.3-61.4 0 0-70 15-84.7-29.8 0 0-11.4-29.1-27.8-36.6 0 0-22.9-15.7 1.6-15.4 0 0 24.9 2 38.6 25.8 21.9 38.6 58.6 27.5 72.9 20.9 2.3-16 8.8-27.1 16-33.7-55.9-6.2-112.3-14.3-112.3-110.5 0-27.5 7.6-41.3 23.6-58.9-2.6-6.5-11.1-33.3 2.6-67.9 20.9-6.5 69 27 69 27 20-5.6 41.5-8.5 62.8-8.5s42.8 2.9 62.8 8.5c0 0 48.1-33.6 69-27 13.7 34.7 5.2 61.4 2.6 67.9 16 17.7 25.8 31.5 25.8 58.9 0 96.5-58.9 104.2-114.8 110.5 9.2 7.9 17 22.9 17 46.4 0 33.7-.3 75.4-.3 83.6 0 6.5 4.6 14.4 17.3 12.1C428.2 457.8 496 362.9 496 252 496 113.3 383.5 8 244.8 8zM97.2 352.9c-1.3 1-1 3.3.7 5.2 1.6 1.6 3.9 2.3 5.2 1 1.3-1 1-3.3-.7-5.2-1.6-1.6-3.9-2.3-5.2-1zm-10.8-8.1c-.7 1.3.3 2.9 2.3 3.9 1.6 1 3.6.7 4.3-.7.7-1.3-.3-2.9-2.3-3.9-2-.6-3.6-.3-4.3.7zm32.4 35.6c-1.6 1.3-1 4.3 1.3 6.2 2.3 2.3 5.2 2.6 6.5 1 1.3-1.3.7-4.3-1.3-6.2-2.2-2.3-5.2-2.6-6.5-1zm-11.4-14.7c-1.6 1-1.6 3.6 0 5.9 1.6 2.3 4.3 3.3 5.6 2.3 1.6-1.3 1.6-3.9 0-6.2-1.4-2.3-4-3.3-5.6-2z" />
                  </svg>
                </div>
              </div>
              <div className="contents">
                <h3 className="text-15 font-medium leading-20 mb-10 lqd-iconbox-heading">
                  Open Source{" "}
                </h3>
                <p className="text-14">GitHub Repo</p>
              </div>
            </div>
          </div>
          <div className="w-25percent flex p-10 border-right border-black-10 lg:w-50percent lg:border-bottom lg:border-right-0">
            <div className="py-30 iconbox flex items-center transition-all flex-grow-1 relative flex-col iconbox-default">
              <div className="iconbox-icon-wrap">
                <div className="iconbox-icon-container inline-flex mb-25 text-74 text-slate-400">
                  <svg
                    className="w-1em"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                  >
                    <path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z" />
                  </svg>
                </div>
              </div>
              <div className="contents">
                <h3 className="text-15 font-medium leading-20 mb-10 lqd-iconbox-heading">
                  Follow Us on Twitter{" "}
                </h3>
                <p className="text-14">Latest news and updates</p>
              </div>
            </div>
          </div>
          <div className="w-25percent flex p-10 border-right border-black-10 lg:border-bottom lg:w-50percent">
            <div className="py-30 iconbox flex items-center transition-all flex-grow-1 relative flex-col iconbox-default">
              <div className="iconbox-icon-wrap">
                <div className="iconbox-icon-container inline-flex mb-25 text-74 text-slate-400">
                  <svg
                    className="w-1em"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 512 512"
                  >
                    <path d="M255.9 120.9l9.1-15.7c5.6-9.8 18.1-13.1 27.9-7.5 9.8 5.6 13.1 18.1 7.5 27.9l-87.5 151.5h63.3c20.5 0 32 24.1 23.1 40.8H113.8c-11.3 0-20.4-9.1-20.4-20.4 0-11.3 9.1-20.4 20.4-20.4h52l66.6-115.4-20.8-36.1c-5.6-9.8-2.3-22.2 7.5-27.9 9.8-5.6 22.2-2.3 27.9 7.5l8.9 15.7zm-78.7 218l-19.6 34c-5.6 9.8-18.1 13.1-27.9 7.5-9.8-5.6-13.1-18.1-7.5-27.9l14.6-25.2c16.4-5.1 29.8-1.2 40.4 11.6zm168.9-61.7h53.1c11.3 0 20.4 9.1 20.4 20.4 0 11.3-9.1 20.4-20.4 20.4h-29.5l19.9 34.5c5.6 9.8 2.3 22.2-7.5 27.9-9.8 5.6-22.2 2.3-27.9-7.5-33.5-58.1-58.7-101.6-75.4-130.6-17.1-29.5-4.9-59.1 7.2-69.1 13.4 23 33.4 57.7 60.1 104zM256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm216 248c0 118.7-96.1 216-216 216-118.7 0-216-96.1-216-216 0-118.7 96.1-216 216-216 118.7 0 216 96.1 216 216z" />
                  </svg>
                </div>
              </div>
              <div className="contents">
                <h3 className="text-15 font-medium leading-20 mb-10 lqd-iconbox-heading">
                  Visit App Store{" "}
                </h3>
                <p className="text-14">Download for iOS Devices</p>
              </div>
            </div>
          </div>
          <div className="w-25percent flex p-10 lg:w-50percent">
            <div className="py-30 iconbox flex items-center transition-all flex-grow-1 relative flex-col iconbox-default">
              <div className="iconbox-icon-wrap">
                <div className="iconbox-icon-container inline-flex mb-25 text-74 text-slate-400">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="1em"
                    viewBox="0 0 512 512"
                    fill="currentColor"
                  >
                    <path d="M498.1 5.6c10.1 7 15.4 19.1 13.5 31.2l-64 416c-1.5 9.7-7.4 18.2-16 23s-18.9 5.4-28 1.6L284 427.7l-68.5 74.1c-8.9 9.7-22.9 12.9-35.2 8.1S160 493.2 160 480V396.4c0-4 1.5-7.8 4.2-10.7L331.8 202.8c5.8-6.3 5.6-16-.4-22s-15.7-6.4-22-.7L106 360.8 17.7 316.6C7.1 311.3 .3 300.7 0 288.9s5.9-22.8 16.1-28.7l448-256c10.7-6.1 23.9-5.5 34 1.4z" />
                  </svg>
                </div>
              </div>
              <div className="contents">
                <h3 className="text-15 font-medium leading-20 mb-10 lqd-iconbox-heading">
                  Telegram Channel{" "}
                </h3>
                <p className="text-14">Join the Community</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
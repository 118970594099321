export default function CarousselLG() {
  return (
    <section className="lqd-section carousel-text-lg bg-white transition-all">
      <div className="ld-particles-container w-full lqd-particles-as-bg lqd-overlay flex">
        <div
          className="ld-particles-inner lqd-overlay flex pointer-events-none"
          id="lqd-particle-text-carousel"
          data-particles="true"
          data-particles-options='{"particles": {"number": {"value" : 4} , "color": {"value" : ["#604CFD", "#FDA44C", "#0FBBB4", "#F85976"]} , "shape": {"type" : ["circle"]} , "opacity": {"value" : 1} , "size": {"value" : 4, "random": true} , "move": {"enable": true, "direction": "none", "random": true, "out_mode": "out"}} , "interactivity" : [], "retina_detect": true}'
        />
      </div>
      <div className="container-fluid p-0">
        <div className="row m-0">
          <div className="col col-12 p-0">
            <div
              className="carousel-container relative carousel-nav-shaped"
              id="carousel-text"
            >
              <div
                className="carousel-items relative -mr-30 -ml-30"
                data-lqd-flickity='{"percentPosition": false, "columnsAutoWidth": true, "marquee": true, "pauseAutoPlayOnHover": false}'
              >
                <div className="carousel-item flex flex-col justify-center px-30">
                  <div className="carousel-item-inner relative w-full">
                    <div className="carousel-item-content relative w-full font-bold leading-1/2em module-title-top">
                      <h2 className="m-0 text-inherit leading-inherit pb-0/1em text-size-inherit">
                        <strong>Cloud System - Hight Performance </strong>
                      </h2>
                    </div>
                  </div>
                </div>
                <div className="carousel-item flex flex-col justify-center px-30">
                  <div className="carousel-item-inner relative w-full">
                    <div className="carousel-item-content relative w-full font-bold leading-1/2em module-title-top">
                      <h2 className="m-0 text-inherit leading-inherit pb-0/1em text-size-inherit">
                        <strong>Cloud System - Hight Performance </strong>
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col col-12 p-0">
            <div className="carousel-container relative carousel-nav-shaped">
              <div
                className="carousel-items relative -mr-30 -ml-30 "
                data-lqd-flickity='{"percentPosition": false, "columnsAutoWidth": true, "marquee": true, "rightToLeft": true, "pauseAutoPlayOnHover": false}'
              >
                <div className="carousel-item flex flex-col justify-center px-30">
                  <div className="carousel-item-inner relative w-full">
                    <div className="carousel-item-content relative w-full font-bold leading-1/2em  module-title-bottom">
                      <h2 className="m-0 leading-inherit pb-0/1em text-transparent text-size-inherit">
                        <strong> Art Direction - Strategy - Branding -</strong>
                      </h2>
                    </div>
                  </div>
                </div>
                <div className="carousel-item flex flex-col justify-center px-30">
                  <div className="carousel-item-inner relative w-full">
                    <div className="carousel-item-content relative w-full font-bold leading-1/2em  module-title-bottom">
                      <h2 className="m-0 leading-inherit pb-0/1em text-transparent text-size-inherit">
                        <strong> Art Direction - Strategy - Branding -</strong>
                      </h2>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  )
}
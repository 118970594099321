import { useEffect, useRef } from "react";
import Footer from "../layout/footer";
import Header from "../layout/header";
import CustomCursor from "../layout/customCursor";

function PrivacyPolicy() {
  const body = useRef(null);

  useEffect(() => {
    // List of script sources you want to load
    const scriptSources = ["./js/theme.js"];

    const scripts = scriptSources.map((src) => {
      const script = document.createElement("script");
      script.src = src;
      script.async = true;
      return script;
    });

    if (body.current) {
      scripts.forEach((script) => document.body.appendChild(script));
    }

    return () => {
      scripts.forEach((script) => document.body.removeChild(script));
    };
  }, [body]);

  return (
    <div id="wrap" className="bg-white">
       <div
        className="titlebar border-bottom border-black-10"
        id="banner"
        style={{
          backgroundImage:
            "linear-gradient(180deg, #FF00C414 0%, #FFFFFF 100%)",
        }}
      >
        <div className="lqd-sticky-placeholder hidden" />
        {/* <Header /> */}
        <div className="titlebar-inner">
          <div className="container titlebar-container">
            <div className="row titlebar-container justify-center">
              <div className="col titlebar-col col-12  text-gray-400">
                <h1>Privacy Policy</h1>
       
                <div className="d-flex justify-content-center my-5">
                  <div
                    className=""
                  >
                     <p className="font-weight-400  font-size-15">
                     Thank you for choosing Analytics Hunter (the 'Website'). Your privacy is important to us, and we are committed to protecting it. This privacy policy ('Privacy Policy') explains how we collect, use, and share information about you when you use the Website.
          </p>
          <p>
            <span className="text-dark-gray font-weight-600 ">
            NOTE TO ALL USERS, INCLUDING INTERNATIONAL USERS:{" "}
            </span>
            <span className=" ">
            If you use our Site or Applications (as defined below) by visiting them or placing orders through them, whether from outside of the United States or not, you understand that you are voluntarily providing information to us and are accepting the practices described in this Privacy Policy. For those using the Site or Applications from outside the United States, you understand and agree that information we collect from you may be stored within or outside the United States or your home country, or may be transferred to the United States. The Site is subject to United States laws, which may not afford the same level of protection as those in your country.
            </span>
          </p>
          <p className=" ">
          Analytics Hunter. (“Analytics Hunter”, “our”, “us” or “we”) provides this Privacy Policy to inform you of our policies and procedures regarding the collection, use and disclosure of personal information we receive from users of analyticshunter.com (this “Site”) and our corresponding applications for mobile devices (the “Applications”) (“Site” and “Applications” are collectively referred to in this Policy as the “Service” or “Services”). This Privacy Policy applies only to information that you provide to us through the Site and Applications. This Privacy Policy may be updated from time to time.
          </p>
          <p className=" ">
          By using the Website, you agree to the collection, use, and sharing of your information as described in this Privacy Policy. If you do not agree with our policies and practices, do not use the Website.
          </p>
          <p className=" ">
          We may change our Privacy Policy from time to time. We encourage you to review the Privacy Policy whenever you access the Website to stay informed about our information practices and the choices available to you.
          </p>
          <div
            className="d-flex flex-wrap"
            style={{ justifyContent: "space-evenly" }}
          >
            <ul className=" mt-4">
              <span
                className="text-dark-gray font-weight-600 font-size-18 "
                style={{ textDecoration: "underline" }}
              >
               Table of Contents
              </span>
              <li>Information We Collect</li>
              <li>How We Use Your Information</li>
              <li>How We Share Your Information</li>
              <li>Your Choices</li>
              <li>Data Security</li>
              <li>Children's Privacy</li>
              <li>Third-Party Links</li>
              <li>International Transfer</li>
              <li>Changes to Our Privacy Policy</li>
              <li>Contact Us</li>
            </ul>
            
          </div>
          <h5 className="font-size-20 mt-4 text-dark-gray">
          Information We Collect
          </h5>
          <p className=" ">
          We collect information about you in several ways when you use the Website.
          </p>
          <ul className=" mt-3 font-size-15">
            <li className=" ">
            Information You Provide to Us: We collect information you provide directly to us. For example, when you create an account, place an order, or contact us, you may be asked to provide certain information such as your name, email address, phone number, and payment information.
            </li>
            <li className="  mt-3">
            Information We Collect Automatically: We also collect information about you automatically when you use the Website. This includes information such as your IP address, browser type, device type, and referring website. We may also collect information about your location, the pages you view, and the actions you take on the Website. We may collect this information using cookies, web beacons, and other tracking technologies.
            </li>
            <li className="  mt-3">
            Information We Collect from Third Parties: We may also collect information about you from third parties such as social media platforms, e-commerce websites, and analytics providers. For example, if you connect your social media account to the Website, we may collect information from that account, including your name, profile picture, and list of friends.
            </li>
          </ul>
          <h5 className="font-size-20 mt-4 text-dark-gray ">
          How We Use Your Information
          </h5>
          <p className=" ">
          We use the information we collect about you for a variety of purposes, including
            :
          </p>
          <ul className=" mt-3 font-size-15">
            <li className=" ">
            To provide and improve the Website: We use the information we collect to operate and maintain the Website, including to personalize your experience, process transactions, and provide customer support.
            </li>
            <li className="  mt-3">
            To analyze user performance: We use the information we collect to analyze the performance of our users and to understand how people use the Website. This includes analyzing data about page views, traffic sources, and other metrics.
            </li>
            <li className="  mt-3">
            To track social media performance: We use the information we collect to track the performance of your social media accounts and to understand how you are using social media. This includes analyzing data about your followers, engagement, and other metrics.
            </li>
            <li className="  mt-3">
            To track competitors: We use the information we collect to track the performance of your competitors and to understand how they are using social media.
            </li>
            <li className="  mt-3">
            To communicate with you: We may use the information we collect to communicate with you about your account, orders, and other updates.
            </li>
          </ul>
          <h5 className="font-size-20 mt-4 text-dark-gray">
          How We Share Your Information
          </h5>
          <p className=" ">
          We may share your information in the following ways:
          </p>
          <ul className=" mt-3 font-size-15">
            <li className=" ">
            With Service Providers: We may share your information with third-party service providers who perform services on our behalf, such as payment processing, data analysis, and customer service.
            </li>
            <li className="  mt-3">
            With Business Partners: We may share your information with our business partners for marketing and advertising purposes. This includes sharing your information with third-party advertisers who may use it to show you ads that are more relevant to you.
            </li>
            <li className="  mt-3">
            With Social Media Platforms: If you connect your social media account to the Website, we may share your information with that social media platform.
            </li>
            <li className="  mt-3">
            With Legal Requests: We may share your information with law enforcement, government agencies, or other third parties when required by law or to protect the rights and safety of ourselves and others.
            </li>
          </ul>
          <h5 className="font-size-20 mt-4 text-dark-gray">
          Your Choices
          </h5>
          <p className=" ">
          You have several choices when it comes to the information we collect and how we use it
            :
          </p>
          <ul className=" mt-3 font-size-15">
            <li className=" ">
            Opt Out of Marketing Communications: You can opt out of receiving marketing communications from us by following the unsubscribe instructions in those communications or by contacting us directly.
            </li>
            <li className="  mt-3">
            Manage Your Cookies: You can manage your cookies and other tracking technologies by adjusting your browser settings. Please note that if you disable cookies, some features of the Website may not work properly.
            </li>
            <li className="  mt-3">
            Access and Update Your Information: You can access and update your account information by logging into your account and making the necessary changes.
            </li>
          </ul>
          <h5 className="font-size-20 mt-4 text-dark-gray">
          Data Security
          </h5>
          <p className=" ">
          We take reasonable steps to protect the information we collect from you from unauthorized access, use, or disclosure. However, no security measures are perfect and we cannot guarantee the security of your information.
          </p>
          <h6 className="font-size-16 font-weight-600 text-dark-gray">
          Data Breaches
          </h6>
          <p className=" ">
          In the event of a data breach that affects your personal information, we will take steps to promptly notify you and any relevant authorities. We will also implement our data breach response plan, which includes identifying the scope of the breach, determining the cause of the breach, and taking steps to prevent future breaches.
          </p>
          <p className=" ">
          If you are a user in California, you have the right to receive notice of a data breach involving your personal information. We will provide this notice as required by law.
          </p>
          <h6 className="font-size-16 font-weight-600 text-dark-gray">
          California Privacy Rights
          </h6>
          <p className=" ">
          If you are a California consumer, you have the following rights under the California Consumer Privacy Act (CCPA)
            :
          </p>
          <ul className=" mt-3 font-size-15">
            <li className="  mt-3">
            The right to request that we disclose the categories of personal information we have collected about you, the categories of sources from which the personal information was collected, the business purposes for collecting or selling the personal information, and the categories of third parties with whom the personal information is shared.
            </li>
            <li className="  mt-3">
            The right to request that we delete your personal information, subject to certain exceptions.
            </li>
            <li className=" ">
            The right to opt out of the sale of your personal information by clicking on the 'Do Not Sell My Personal Information' link on our homepage.
            </li>
          </ul>
          <p className=" ">
          We will not discriminate against you for exercising any of your rights under the CCPA.
            :
          </p>
          <h5 className="font-size-20 mt-4 text-dark-gray">
          Children's Privacy
          </h5>
          <p className=" ">
          The Website is not intended for children under the age of 13. We do not knowingly collect personal information from children under 13. If you are a parent or guardian and you believe that your child under 13 has provided us with personal information, please contact us.
          </p>
          <h5 className="font-size-20 mt-4 text-dark-gray">
          Third-Party Links
          </h5>
          <p className=" ">
          The Website may contain links to third-party websites. We are not responsible for the privacy practices of those websites. We encourage you to read the privacy policies of each website you visit.
          </p>
          <h5 className="font-size-20 mt-4 text-dark-gray">
          International Transfer
          </h5>
          <p className=" ">
          Your information may be transferred to and processed in countries other than the country in which you reside. These countries may have data protection laws that are different from the laws of your country. By using the Website, you consent to the transfer and processing of your information in these countries.
          </p>
          <h5 className="font-size-20 mt-4 text-dark-gray">
          Changes to Our Privacy Policy
          </h5>
          <p className=" ">
          We reserve the right to modify this privacy policy at any time, so please review it frequently. Changes and clarifications will take effect immediately upon their posting on the website. If we make material changes to this policy, we will notify you here that it has been updated, so that you are aware of what information we collect, how we use it, and under what circumstances, if any, we use and/or disclose it.
          </p>
          <p className=" ">
          If our website is acquired or merged with another company, your information may be transferred to the new owners so that we may continue to sell products to you.
          </p>
          <p className=" ">
          If you have any questions or concerns about a data breach, please contact us at hello@analyticshunter.com
          </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <main className="content bg-white" id="lqd-site-content" ref={body}>
        {/* <div id="qd-contents-wrap">
          <FeaturesSection />
          <CarousselLG />
          <TabBox />
          <Testimonials />
          <FAQ />
        </div> */}
        <template id="lqd-temp-sticky-header-sentinel">
          <div className="lqd-sticky-sentinel invisible absolute pointer-events-none" />
        </template>
      </main>

      {/* <CustomCursor /> */}

      {/* <Footer /> */}
    </div>
  );
}

export default PrivacyPolicy;

import { useEffect, useRef } from "react";
import Footer from "../layout/footer";
import Header from "../layout/header";
import CustomCursor from "../layout/customCursor";

function RefundPolicy() {
  const body = useRef(null);

  useEffect(() => {
    // List of script sources you want to load
    const scriptSources = ["./js/theme.js"];

    const scripts = scriptSources.map((src) => {
      const script = document.createElement("script");
      script.src = src;
      script.async = true;
      return script;
    });

    if (body.current) {
      scripts.forEach((script) => document.body.appendChild(script));
    }

    return () => {
      scripts.forEach((script) => document.body.removeChild(script));
    };
  }, [body]);

  return (
    <div id="wrap" className="bg-white">
      <div
        className="titlebar border-bottom border-black-10"
        id="banner"
        style={{
          backgroundImage:
            "linear-gradient(180deg, #FF00C414 0%, #FFFFFF 100%)",
        }}
      >
        <div className="lqd-sticky-placeholder hidden" />
        {/* <Header /> */}
        <div className="titlebar-inner">
          <div className="container titlebar-container">
            <div className="row titlebar-container justify-center">
              <div className="col titlebar-col col-12 text-center text-gray-400">
                <h1>Refund Policy</h1>
       
                <div className="d-flex justify-content-center my-5">
                  <div
                    className=""
                  >
                           <p className='font-weight-400  font-size-15'>At Analytics Hunter, we strive to provide high-quality web services to our customers. If you are not satisfied with your purchase, we offer a 7-day refund policy from the date of purchase. To request a refund, please contact us via email or phone within 7 days of your purchase.</p>
        <p className='font-weight-400  font-size-15'>Please note that after 7 days, we are unable to grant any refunds for any reason. This includes, but is not limited to, situations where you have changed your mind about your purchase or are frustrated with the service for any reason.</p>
        <p className='font-weight-400  font-size-15'>At Analytics Hunter, we strive to provide high-quality web services to our customers. If you are not satisfied with your purchase, we offer a 7-day refund policy from the date of purchase. To request a refund, please contact us via email or phone within 7 days of your purchase.</p>
        <p className='font-weight-400  font-size-15'>We also reserve the right to modify or discontinue our refund policy at any time without notice.</p>
        <p className='font-weight-400  font-size-15'>Contact us for more info at hello@analyticshunter.com</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <main className="content bg-white" id="lqd-site-content" ref={body}>
				{/* <div id="qd-contents-wrap">
          <FeaturesSection />
          <CarousselLG />
          <TabBox />
          <Testimonials />
          <FAQ />
        </div> */}
        <template id="lqd-temp-sticky-header-sentinel">
          <div className="lqd-sticky-sentinel invisible absolute pointer-events-none" />
        </template>
      </main>


      {/* <CustomCursor />

      <Footer /> */}
    </div>
  );
}

export default RefundPolicy;

import blogImg from "../../assets/images/blog-image.png";
export default  function Blog() {
  return (
    <section
      className="lqd-section blog pb-120"
      style={{ backgroundImage: "linear-gradient(180deg, #DBE4FE 0%, #fff 20%)" }}
    >
      <div className="lqd-shape lqd-shape-top" data-negative="false">
        <svg
          className="h-60"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1000 100"
          preserveAspectRatio="none"
          fill="#fff"
        >
          <path className="lqd-shape-fill" d="M738,99l262-93V0H0v5.6L738,99z" />
        </svg>
      </div>
      <div className="container p-0">
        <div className="row m-0">
          <div className="col col-12 p-0">
            <div className="container pt-80">
              <div className="row items-center">
                <div className="col col-12 col-md-6 p-0">
                  <div className="lqd-imggrp-single block relative">
                    <div className="lqd-imggrp-img-container inline-flex relative items-center justify-center">
                      <figure className="w-full relative">
                        <img
                          width={38}
                          height={6}
                          src="/images/shape-dash.svg"
                          alt="dash shape"
                        />
                      </figure>
                    </div>
                  </div>
                  <div className="ld-fancy-heading relative">
                    <h2 className="ld-fh-element mb-0/5em inline-block relative">
                      Latest Posts
                    </h2>
                  </div>
                </div>
                <div className="col col-12 col-md-6 p-0">
                  <div className="flex flex-wrap justify-center">
                    <div className="iconbox flex flex-grow-0 relative items-center justify-center text-center">
                      <div className="iconbox-icon-wrap mr-25">
                        <div className="text-40 w-40 iconbox-icon-container inline-flex">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            id="Airplane"
                            width={47}
                            height={47}
                            viewBox="0 0 47 47"
                          >
                            <circle
                              id="Oval-6"
                              cx="23.5"
                              cy="23.5"
                              r="23.5"
                              transform="translate(0 0)"
                              fill="#bbc3ff"
                            />
                            <path
                              id="Shape-1"
                              d="M30.754,0,.679,13.467c-.947.424-.9.974.111,1.23L7.724,16.45l9.51,4.319L24.121,24.5a1,1,0,0,0,1.539-.707Z"
                              transform="translate(6.376 10.81)"
                              fill="#4451b1"
                            />
                            <path
                              id="Shape-2"
                              data-name="Shape-2"
                              d="M23.03,0,0,16.45l3.2,8.713a.841.841,0,0,0,1.494.333L9.51,20.769,5.985,18.788Z"
                              transform="translate(14.1 10.81)"
                              fill="#929bdb"
                            />
                          </svg>
                        </div>
                      </div>
                      <h3 className="text-18 font-normal m-0 lqd-iconbox-heading flex-unset">
                        <strong>— Subscribe</strong>&nbsp;to the Newsletter
                      </h3>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="container mt-30">
              <div className="row">
                <div className="col col-12 col-xl-6 p-0 mb-30">
                  <article className="lqd-lp relative lqd-lp-hover-img-zoom text-start post type-post status-publish format-standard has-post-thumbnail hentry category-blog-single">
                    <div className="lqd-lp-img overflow-hidden rounded-6 mb-2/5em">
                      <figure>
                        <img
                          width={1104}
                          height={648}
                          src={blogImg}
                          alt="blog"
                        />
                      </figure>
                    </div>
                    <header className="lqd-lp-header">
                      <div className="lqd-lp-meta lqd-lp-meta-dot-between flex flex-wrap items-center font-bold uppercase tracking-0/1em">
                        <span className="screen-reader-text">Tags </span>
                        <ul className="lqd-lp-cat lqd-lp-cat lqd-lp-cat-shaped lqd-lp-cat-solid lqd-lp-cat-solid-colored reset-ul inline-ul relative z-3">
                          <li>
                            <a className="module-color" href="#" rel="tag">
                              Metrics
                            </a>
                          </li>
                        </ul>
                        <time
                          className="lqd-lp-date"
                          dateTime="2021-06-02T13:40:12+00:00"
                        >
                          1 year ago
                        </time>
                      </div>
                      <h5 className="font-semibold tracking-0 entry-title lqd-lp-title mt-0/75em mb-4 text-24 font-semibold leading-26 text-black">
                        Use customer data to build great products
                      </h5>
                    </header>
                    <div className="lqd-lp-excerpt mb-2rem">
                      <p>
                        Passionate about solving problems through creative
                        communications. Offering affordable goods.
                      </p>
                    </div>
                    <footer className="lqd-lp-footer">
                      <div className="lqd-lp-meta">
                        <div className="lqd-lp-author inline-flex flex-wrap items-center relative z-3">
                          <a
                            href="https://starthubthree.liquid-themes.com/author/starthub/"
                            className="lqd-overlay flex"
                          />
                          <figure className="rounded-full overflow-hidden">
                            <img
                              alt="starthub"
                              src="/images/avatar.png"
                              className="avatar avatar-50 photo w-full"
                              height={50}
                              width={50}
                            />
                          </figure>
                          <div className="lqd-lp-author-info ml-1/5em">
                            <h3 className="mt-0 mb-0 uppercase tracking-0/1em font-bold">
                              starthub
                            </h3>
                          </div>
                        </div>
                      </div>
                    </footer>
                  </article>
                </div>
                <div className="col col-12 col-xl-6 p-0">
                  <div className="relative flex flex-wrap flex-col pl-90 lg:pl-0">
                    <article className="mb-30 border-bottom-dashed border-lightgray lqd-lp relative lqd-lp-hover-img-zoom text-start post type-post status-publish format-standard has-post-thumbnail hentry category-blog-single tag-product">
                      <div className="hidden lqd-lp-img overflow-hidden rounded-6 mb-2/5em">
                        <figure>
                          <img
                            width={1250}
                            height={700}
                            src="/images/blog-image.png"
                            alt="blog"
                          />
                        </figure>
                      </div>
                      <header className="lqd-lp-header">
                        <div className="lqd-lp-meta lqd-lp-meta-dot-between flex flex-wrap items-center font-bold uppercase tracking-0/1em">
                          <span className="screen-reader-text">Tags </span>
                          <ul className="lqd-lp-cat lqd-lp-cat lqd-lp-cat-shaped lqd-lp-cat-solid lqd-lp-cat-solid-colored reset-ul inline-ul relative z-3">
                            <li>
                              <a className="module-color-1" href="#" rel="tag">
                                Product
                              </a>
                            </li>
                          </ul>
                          <time
                            className="lqd-lp-date"
                            dateTime="2020-09-24T06:00:29+00:00"
                          >
                            2 years ago
                          </time>
                        </div>
                        <h5 className="font-semibold tracking-0 entry-title lqd-lp-title mt-0/75em mb-1/15em text-18 font-semibold leading-26 text-black">
                          How to create magical digital experiences for customers
                        </h5>
                      </header>
                      <footer className="hidden lqd-lp-footer">
                        <div className="lqd-lp-meta">
                          <div className="lqd-lp-author inline-flex flex-wrap items-center relative z-3">
                            <a href="#" className="lqd-overlay flex" />
                            <figure className="rounded-full overflow-hidden">
                              <img
                                alt="starthub"
                                src="/images/avatar.png"
                                className="avatar avatar-50 photo w-full"
                                height={50}
                                width={50}
                              />
                            </figure>
                            <div className="lqd-lp-author-info ml-1/5em">
                              <h3 className="mt-0 mb-0 uppercase tracking-0/1em font-bold">
                                starthub
                              </h3>
                            </div>
                          </div>
                        </div>
                      </footer>
                    </article>
                    <article className="mb-30 border-bottom-dashed border-lightgray lqd-lp relative lqd-lp-hover-img-zoom text-start post type-post status-publish format-standard has-post-thumbnail hentry category-blog-single tag-office">
                      <div className="hidden lqd-lp-img overflow-hidden rounded-6 mb-2/5em">
                        <figure>
                          <img
                            width={1250}
                            height={700}
                            src="/images/blog-image.png"
                            alt="blog"
                          />
                        </figure>
                      </div>
                      <header className="lqd-lp-header">
                        <div className="lqd-lp-meta lqd-lp-meta-dot-between flex flex-wrap items-center font-bold uppercase tracking-0/1em">
                          <span className="screen-reader-text">Tags </span>
                          <ul className="lqd-lp-cat lqd-lp-cat lqd-lp-cat-shaped lqd-lp-cat-solid lqd-lp-cat-solid-colored reset-ul inline-ul relative z-3">
                            <li>
                              <a className="module-color-2" href="#" rel="tag">
                                Office
                              </a>
                            </li>
                          </ul>
                          <time
                            className="lqd-lp-date"
                            dateTime="2020-09-23T12:14:11+00:00"
                          >
                            2 years ago
                          </time>
                        </div>
                        <h5 className="font-semibold tracking-0 entry-title lqd-lp-title mt-0/75em mb-1/15em text-18 font-semibold leading-26 text-black">
                          Free advertising for your online business and store
                        </h5>
                      </header>
                      <footer className="hidden lqd-lp-footer">
                        <div className="lqd-lp-meta">
                          <div className="lqd-lp-author inline-flex flex-wrap items-center relative z-3">
                            <a href="#" className="lqd-overlay flex" />
                            <figure className="rounded-full overflow-hidden">
                              <img
                                alt="starthub"
                                src="/images/avatar.png"
                                className="avatar avatar-50 photo w-full"
                                height={50}
                                width={50}
                              />
                            </figure>
                            <div className="lqd-lp-author-info ml-1/5em">
                              <h3 className="mt-0 mb-0 uppercase tracking-0/1em font-bold">
                                starthub
                              </h3>
                            </div>
                          </div>
                        </div>
                      </footer>
                    </article>
                    <article className="mb-30 border-bottom-dashed border-lightgray lqd-lp relative lqd-lp-hover-img-zoom text-start post type-post status-publish format-standard has-post-thumbnail hentry category-blog-single tag-travel">
                      <div className="hidden lqd-lp-img overflow-hidden rounded-6 mb-2/5em">
                        <figure>
                          <img
                            width={1250}
                            height={700}
                            src="/images/blog-image.png"
                            alt="blog"
                          />
                        </figure>
                      </div>
                      <header className="lqd-lp-header">
                        <div className="lqd-lp-meta lqd-lp-meta-dot-between flex flex-wrap items-center font-bold uppercase tracking-0/1em">
                          <span className="screen-reader-text">Tags </span>
                          <ul className="lqd-lp-cat lqd-lp-cat lqd-lp-cat-shaped lqd-lp-cat-solid lqd-lp-cat-solid-colored reset-ul inline-ul relative z-3">
                            <li>
                              <a className="module-color-3" href="#" rel="tag">
                                Travel
                              </a>
                            </li>
                          </ul>
                          <time
                            className="lqd-lp-date"
                            dateTime="2020-09-23T12:13:55+00:00"
                          >
                            2 years ago
                          </time>
                        </div>
                        <h5 className="font-semibold tracking-0 entry-title lqd-lp-title mt-0/75em mb-1/15em text-18 font-semibold leading-26 text-black">
                          Secure transactions and payments for eCommerce Platforms
                        </h5>
                      </header>
                      <footer className="hidden lqd-lp-footer">
                        <div className="lqd-lp-meta">
                          <div className="lqd-lp-author inline-flex flex-wrap items-center relative z-3">
                            <a href="#" className="lqd-overlay flex" />
                            <figure className="rounded-full overflow-hidden">
                              <img
                                alt="starthub"
                                src="/images/avatar.png"
                                className="avatar avatar-50 photo w-full"
                                height={50}
                                width={50}
                              />
                            </figure>
                            <div className="lqd-lp-author-info ml-1/5em">
                              <h3 className="mt-0 mb-0 uppercase tracking-0/1em font-bold">
                                starthub
                              </h3>
                            </div>
                          </div>
                        </div>
                      </footer>
                    </article>
                    <article className="mb-30 lqd-lp relative lqd-lp-hover-img-zoom text-start post type-post status-publish format-standard has-post-thumbnail hentry category-blog-single tag-global">
                      <div className="hidden lqd-lp-img overflow-hidden rounded-6 mb-2/5em">
                        <figure>
                          <img
                            width={1250}
                            height={700}
                            src="/images/blog-image.png"
                            alt="blog"
                          />
                        </figure>
                      </div>
                      <header className="lqd-lp-header">
                        <div className="lqd-lp-meta lqd-lp-meta-dot-between flex flex-wrap items-center font-bold uppercase tracking-0/1em">
                          <span className="screen-reader-text">Tags </span>
                          <ul className="lqd-lp-cat lqd-lp-cat lqd-lp-cat-shaped lqd-lp-cat-solid lqd-lp-cat-solid-colored reset-ul inline-ul relative z-3">
                            <li>
                              <a className="module-color-4" href="#" rel="tag">
                                Global
                              </a>
                            </li>
                          </ul>
                          <time
                            className="lqd-lp-date"
                            dateTime="2020-09-22T12:12:36+00:00"
                          >
                            2 years ago
                          </time>
                        </div>
                        <h5 className="font-semibold tracking-0 entry-title lqd-lp-title mt-0/75em mb-1/15em text-18 font-semibold leading-26 text-black">
                          Chip startup says sensors may replace buttons on phones
                        </h5>
                      </header>
                      <footer className="hidden lqd-lp-footer">
                        <div className="lqd-lp-meta">
                          <div className="lqd-lp-author inline-flex flex-wrap items-center relative z-3">
                            <a href="#" className="lqd-overlay flex" />
                            <figure className="rounded-full overflow-hidden">
                              <img
                                alt="starthub"
                                src="/images/avatar.png"
                                className="avatar avatar-50 photo w-full"
                                height={50}
                                width={50}
                              />
                            </figure>
                            <div className="lqd-lp-author-info ml-1/5em">
                              <h3 className="mt-0 mb-0 uppercase tracking-0/1em font-bold">
                                starthub
                              </h3>
                            </div>
                          </div>
                        </div>
                      </footer>
                    </article>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  )
}
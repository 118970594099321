import tabBoxImg from "../../assets/images/tab-box-1.png";

export default function TabBox() {
  return (
    <section
      className="lqd-section tab-box bg-white transition-all py-50"
      data-custom-animations="true"
      data-ca-options='{"animationTarget": ".animation-element", "ease": "power4.out", "initValues": {"y": "35px", "opacity" : 0} , "animations": {"y": "0px", "opacity" : 1}}'
    >
      <div className="container animation-element">
        <div className="row justify-center">
          <div className="col col-12 col-md-10 col-lg-8 text-center pb-50 pt-20">
            <div className="ld-fancy-heading relative">
              <h2
                className="ld-fh-element mb-0/5em inline-block relative ld-gradient-heading lqd-highlight-classic lqd-highlight-grow-left lqd-highlight-reset-onhover text-44 bg-transparent"
                data-inview="true"
                data-transition-delay="true"
                data-delay-options=' {"elements": ".lqd-highlight-inner", "delayType": "transition"}'
                style={{
                  backgroundImage:
                    "linear-gradient(250deg, #4452F2 0%, #749FEF 100%)"
                }}
              >
                <span>Built-in froud</span>
                <mark className="lqd-highlight">
                  <span className="lqd-highlight-txt">protection.</span>
                  <span className="lqd-highlight-inner h-20 -bottom-5 left-0 bg-slate-100" />
                </mark>
              </h2>
            </div>
            <div className="px-10percent ld-fancy-heading relative">
              <p className="ld-fh-element mb-0/5em inline-block relative text-18">
                Use customer data to build great and solid product experiences that
                convert.
              </p>
            </div>
          </div>
          <div className="col col-12 py-0">
            <div className="flex">
              <div
                className="lqd-tabs lqd-tabs-style-13 flex lqd-tabs-nav-items-not-expanded flex-row-reverse lqd-nav-underline-"
                data-tabs-options='{"trigger": "click"}'
              >
                <nav className="lqd-tabs-nav-wrap">
                  <ul
                    className="reset-ul lqd-tabs-nav flex flex-col relative"
                    role="tablist"
                  >
                    <li role="presentation">
                      <a
                        className="bg-transparent rounded-12 block mb-0/5em active"
                        href="#lqd-tab-box-one"
                        aria-expanded="true"
                        aria-controls="lqd-tab-box-one"
                        role="tab"
                        data-bs-toggle="tab"
                      >
                        <span className="block lqd-tabs-nav-txt">
                        Blindspot Sniper: Target Where Competitors Don't
                        </span>
                        <span className="lqd-tabs-nav-ext">
                        Blindspot Sniper identifies market segments and demographics ignored by your competitors, giving you a fresh playing fito dominate. {" "}
                        </span>
                      </a>
                    </li>
                   
                    <li role="presentation">
                      <a
                        className="bg-transparent rounded-12 block mb-0/5em"
                        href="#lqd-tab-box-three"
                        aria-expanded="false"
                        aria-controls="lqd-tab-box-three"
                        role="tab"
                        data-bs-toggle="tab"
                      >
                        <span className="block lqd-tabs-nav-txt">
                        Role-Based Dashboards
                        </span>
                        <span className="lqd-tabs-nav-ext">
                        Customize dashboards based on roles, from marketing and sales to customer service.{" "}
                        </span>
                      </a>
                    </li>
                    <li role="presentation">
                      <a
                        className="bg-transparent rounded-12 block mb-0/5em"
                        href="#lqd-tab-box-four"
                        aria-expanded="false"
                        aria-controls="lqd-tab-box-four"
                        role="tab"
                        data-bs-toggle="tab"
                      >
                        <span className="block lqd-tabs-nav-txt">
                        Predict Your Next Viral Hit
                        </span>
                        <span className="lqd-tabs-nav-ext">
                        The Tool taps into data points from trending topics to the time users are most active, giving you a predictive edge for your next big hit. {" "}
                        </span>
                      </a>
                    </li>
                    <li role="presentation">
                      <a
                        className="bg-transparent rounded-12 block mb-0/5em"
                        href="#lqd-tab-five"
                        aria-expanded="false"
                        aria-controls="lqd-tab-five"
                        role="tab"
                        data-bs-toggle="tab"
                      >
                        <span className="block lqd-tabs-nav-txt">
                        Multi-Platform Analysis: One Dashboard, Every Channel
                        </span>
                        <span className="lqd-tabs-nav-ext">
                        Pull in data from all your social media platforms, eCommerce sites, and even offline channels into a single dashboard. Compare performance metrics across channels and focus your efforts where they count. {" "}
                        </span>
                      </a>
                    </li>
                    <li role="presentation">
                      <a
                        className="bg-transparent rounded-12 block mb-0/5em"
                        href="#lqd-tab-box-two"
                        aria-expanded="false"
                        aria-controls="lqd-tab-box-two"
                        role="tab"
                        data-bs-toggle="tab"
                      >
                        <span className="block lqd-tabs-nav-txt">
                        Schedule smarter, not harder
                        </span>
                        <span className="lqd-tabs-nav-ext">
                        Use AnalyticsHunter to schedule your social media posts at optimal times based on historical engagement data. The tool could analyze the times when your audience is most active and automatically schedule posts for those periods, ensuring maximum visibility and interaction. {" "}
                        </span>
                      </a>
                    </li>
                  </ul>
                </nav>
                <div className="lqd-tabs-content pr-2em relative">
                  <div
                    id="lqd-tab-box-one"
                    role="tabpanel"
                    className="lqd-tabs-pane fade active in"
                  >
                    <img
                      src={tabBoxImg}
                      alt="tab box"
                      width={1773}
                      height={1121}
                    />
                  </div>
                  <div
                    id="lqd-tab-box-two"
                    role="tabpanel"
                    className="lqd-tabs-pane fade"
                  >
                    <img
                      src={tabBoxImg}
                      alt="tab box"
                      width={1773}
                      height={1121}
                    />
                  </div>
                  <div
                    id="lqd-tab-box-three"
                    role="tabpanel"
                    className="lqd-tabs-pane fade"
                  >
                    <img
                      src={tabBoxImg}
                      alt="tab box"
                      width={1773}
                      height={1121}
                    />
                  </div>
                  <div
                    id="lqd-tab-box-four"
                    role="tabpanel"
                    className="lqd-tabs-pane fade"
                  >
                    <p>
                      <img
                        src={tabBoxImg}
                        alt="tab box"
                        width={1773}
                        height={1121}
                      />
                    </p>
                  </div>
                  <div
                    id="lqd-tab-five"
                    role="tabpanel"
                    className="lqd-tabs-pane fade"
                  >
                    <p>
                      <img
                        src={tabBoxImg}
                        alt="tab box"
                        width={1773}
                        height={1121}
                      />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col col-12 pt-0">
            <div className="w-full relative flex flex-wrap items-center">
              <div className="w-25percent flex flex-col lg:w-50percent">
                <div className="text-primary lqd-counter relative left lqd-counter-default">
                  <div
                    className="lqd-counter-element relative h1"
                    data-enable-counter="true"
                    data-counter-options='{"targetNumber": "4m+"}'
                  >
                    <span className="block">4m+</span>
                  </div>
                </div>
                <div className="mb-15 lqd-imggrp-single block relative">
                  <div className="lqd-imggrp-img-container inline-flex relative items-center justify-center">
                    <figure className="w-full relative">
                      <img
                        width={107}
                        height={15}
                        src="/images/shape-stars.svg"
                        alt="stars"
                      />
                    </figure>
                  </div>
                </div>
                <div className="mb-10 ld-fancy-heading relative">
                  <p className="m-0 text-accent ld-fh-element relative">
                    Installations
                  </p>
                </div>
                <div className="ld-fancy-heading relative">
                  <p className="m-0 text-gradient-start ld-fh-element relative">
                    Application Store
                  </p>
                </div>
              </div>
              <div className="w-25percent flex flex-col lg:w-50percent">
                <div className="text-primary lqd-counter relative left lqd-counter-default">
                  <div
                    className="lqd-counter-element relative h1"
                    data-enable-counter="true"
                    data-counter-options='{"targetNumber": "4.88"}'
                  >
                    <span className="block">4.88</span>
                  </div>
                </div>
                <div className="mb-15 lqd-imggrp-single block relative">
                  <div className="lqd-imggrp-img-container inline-flex relative items-center justify-center">
                    <figure className="w-full relative">
                      <img
                        width={107}
                        height={15}
                        src="/images/shape-stars.svg"
                        alt="stars"
                      />
                    </figure>
                  </div>
                </div>
                <div className="mb-10 ld-fancy-heading relative">
                  <p className="m-0 text-accent ld-fh-element relative">
                    Installations
                  </p>
                </div>
                <div className="ld-fancy-heading relative">
                  <p className="m-0 text-gradient-start ld-fh-element relative">
                    Application Store
                  </p>
                </div>
              </div>
              <div className="w-50percent relative flex flex-wrap justify-between pr-100 module-clients lg:w-full lg:pr-0">
                <div className="w-auto lqd-imggrp-single block relative">
                  <div className="lqd-imggrp-img-container inline-flex relative items-center justify-center">
                    <figure className="w-full relative">
                      <img
                        width={101}
                        height={30}
                        src="/images/client-spotify-2.svg"
                        alt="clients"
                      />
                    </figure>
                  </div>
                </div>
                <div className="w-auto lqd-imggrp-single block relative">
                  <div className="lqd-imggrp-img-container inline-flex relative items-center justify-center">
                    <figure className="w-full relative">
                      <img
                        width={66}
                        height={21}
                        src="/images/client-hulu.svg"
                        alt="clients"
                      />
                    </figure>
                  </div>
                </div>
                <div className="w-auto lqd-imggrp-single block relative">
                  <div className="lqd-imggrp-img-container inline-flex relative items-center justify-center">
                    <figure className="w-full relative">
                      <img
                        width={96}
                        height={37}
                        src="/images/client-amd-logo-black.svg"
                        alt="clients"
                      />
                    </figure>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

import logo from "../../assets/images/mavifinds-logo.png";
import instagram from "../../assets/images/instagram-logo.png";
import tiktok from "../../assets/images/tiktok.png";
import twitter from "../../assets/images/twitter.png";
import facebook from "../../assets/images/facebook-2.png";
import youtube from "../../assets/images/youtube.svg";

export default function PayementsSection() {
  return (
    <section className="lqd-section payments pt-100 pb-80" id="payments">
      <div className="container">
        <div className="row">
          <div className="col col-12 col-xl-6 sm:hidden">
            <div className="sticky top-130">
              <div
                className="-mb-150 lqd-imggrp-single block relative"
                data-float="ease-in-out"
              >
                <div className="lqd-imggrp-img-container inline-flex relative items-center justify-center">
                  <figure className="w-full relative">
                    <img
                      width={566}
                      height={553}
                      src="/images/payment-bg.svg"
                      alt="payments background circle lines"
                    />
                  </figure>
                </div>
              </div>
              <div
                className="absolute top-20 w-110 module-icon-1"
                data-parallax="true"
                data-parallax-options=' {"start": "top bottom", "end": "top center"}'
                data-parallax-from='{"x": "305px", "y": "250px"}'
                data-parallax-to='{"x": "0px", "y": "0px"}'
              >
                <div
                  className="lqd-imggrp-single block relative perspective"
                  data-hover3d="true"
                >
                  <div className="lqd-imggrp-img-container inline-flex relative items-center justify-center transform-style-3d">
                    <figure className="w-full relative" data-stacking-factor={1}>
                      <img
                        width={384}
                        height={384}
                        src={youtube}
                        alt="lyft payment"
                      />
                    </figure>
                  </div>
                </div>
              </div>
              <div
                className="absolute top-40 w-150 module-icon-2"
                data-parallax="true"
                data-parallax-options='{"start": "top bottom", "end": "bottom center"}'
                data-parallax-from='{"y": "200px"}'
                data-parallax-to='{"y": "0px"}'
              >
                <div
                  className="lqd-imggrp-single block relative perspective"
                  data-hover3d="true"
                >
                  <div className="lqd-imggrp-img-container inline-flex relative items-center justify-center transform-style-3d">
                    <figure className="w-full relative" data-stacking-factor={1}>
                      <img
                        width={232}
                        height={216}
                        src={instagram}
                        alt="intercom payment"
                      />
                    </figure>
                  </div>
                </div>
              </div>
              <div
                className="absolute w-130 top-230 module-icon-3"
                data-parallax="true"
                data-parallax-options='{"start": "top bottom", "end": "top center"}'
                data-parallax-from='{"x": "220px", "y": "-30px"}'
                data-parallax-to='{"x": "0px", "y": "0px"}'
              >
                <div
                  className="lqd-imggrp-single block relative perspective"
                  data-hover3d="true"
                >
                  <div className="lqd-imggrp-img-container inline-flex relative items-center justify-center transform-style-3d">
                    <figure className="w-full relative" data-stacking-factor={1}>
                      <img
                        width={232}
                        height={216}
                        src={twitter}
                        alt="twitter payment"
                      />
                    </figure>
                  </div>
                </div>
              </div>
              <div
                className="absolute w-110 top-230 module-icon-4"
                data-parallax="true"
                data-parallax-options='{"start": "top bottom", "end": "top center"}'
                data-parallax-from='{"x": "-194px", "y": "-30px"}'
                data-parallax-to='{"x": "0px", "y": "0px"}'
              >
                <div
                  className="lqd-imggrp-single block relative perspective"
                  data-hover3d="true"
                >
                  <div className="lqd-imggrp-img-container inline-flex relative items-center justify-center transform-style-3d">
                    <figure className="w-full relative" data-stacking-factor={1}>
                      <img
                        width={228}
                        height={216}
                        src={tiktok}
                        alt="apple payment"
                      />
                    </figure>
                  </div>
                </div>
              </div>
              <div className="absolute w-190 top-180 z-4 module-icon-5">
                <div
                  className="lqd-imggrp-single block relative perspective"
                  data-hover3d="true"
                >
                  <div className="lqd-imggrp-img-container inline-flex relative items-center justify-center transform-style-3d">
                    <figure className="w-full relative" data-stacking-factor={1}>
                      <img
                        width={470}
                        height={470}
                        src={logo}
                        alt="tiktok payment"
                      />
                    </figure>
                  </div>
                </div>
              </div>
              <div
                className="absolute w-130 top-20 module-icon-6"
                data-parallax="true"
                data-parallax-options='{"start": "top bottom", "end": "top center"}'
                data-parallax-from='{"x": "-270px", "y": "270px"}'
                data-parallax-to='{"x": "0px", "y": "0px"}'
              >
                <div
                  className="lqd-imggrp-single block relative perspective"
                  data-hover3d="true"
                >
                  <div className="lqd-imggrp-img-container inline-flex relative items-center justify-center transform-style-3d">
                    <figure className="w-full relative" data-stacking-factor={1}>
                      <img
                         width={228}
                         height={216}
                        src={facebook}
                        alt="zendesk payment"
                      />
                    </figure>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="col col-12 col-xl-6 lg:order-first"
            data-custom-animations="true"
            data-ca-options='{"animationTarget": ".animation-element, .iconbox", "startDelay" : 300, "ease": "power4.out", "initValues": {"y": "40px", "opacity" : 0} , "animations": {"y": "0px", "opacity" : 1}}'
          >
            <div className="mr-40 ml-130 flex flex-col lg:m-0">
              <div className="mb-40 iconbox flex flex-grow-1 relative items-center animation-element">
                <div className="iconbox-icon-wrap relative mr-25">
                  <div className="w-40 text-40 iconbox-icon-container inline-flex">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={40}
                      height={40}
                      viewBox="0 0 40 40"
                    >
                      <g id="Message" transform="translate(0 -0.386)">
                        <circle
                          id="Oval-5"
                          cx={20}
                          cy={20}
                          r={20}
                          transform="translate(0 0.386)"
                          fill="#b7eef5"
                        />
                        <path
                          id="Shape-6"
                          d="M9.071,17.759l-.19,0a8.839,8.839,0,0,1-4.936-1.5L1.849,17.521c-.766.46-1.261.122-1.106-.757l.57-3.233A8.881,8.881,0,0,1,14.6,2.088,9.285,9.285,0,0,0,9.071,17.759Z"
                          transform="translate(7.266 9.706)"
                          fill="#6abbd7"
                        />
                        <path
                          id="Shape-7"
                          data-name="Shape-7"
                          d="M0,9a9,9,0,1,1,16.669,4.713l.591,3.348c.149.847-.329,1.179-1.068.735L14,16.483A9,9,0,0,1,0,9Z"
                          transform="translate(14 11.386)"
                          fill="#008aba"
                        />
                      </g>
                    </svg>
                  </div>
                </div>
                <h3 className="text-18 font-normal mb-0 lqd-iconbox-heading">
                Unbeatable Data Insights
                </h3>
              </div>
              <div className="ld-fancy-heading relative animation-element">
                <h2 className="ld-fh-element mb-0/5em inline-block relative">
                Ultimate Analytics Command Center
                </h2>
              </div>
              <div className="ld-fancy-heading relative animation-element">
                <p className="text-18 font-normal mr-2em mb-0/5em ld-fh-element relative">
                  {/* <span className="text-black">
                    Bring your ideas to life with an intuitive visual editor.
                  </span> */}
                  <span>
                  It's not just about gathering data; it's about understanding what to do with it.Get real-time updates on key metrics, so you can make timely and informed decisions.Tailor your data views to suit your specific needs, whether you're a marketer needing campaign performance data or a CEO wanting a high-level overview.
                  </span>
                </p>
              </div>
              <div className="spacer w-full animation-element">
                <div className="w-full h-60" />
              </div>
              <div className="iconbox flex flex-grow-1 relative z-2 iconbox-circle iconbox-icon-linked mb-45 text-start">
                <div className="iconbox-icon-wrap relative mr-20">
                  <div className="iconbox-icon-container relative z-1 text-15 w-50 h-50 text-blue-300 bg-blue-100 rounded-full shadow-lg">
                    <i aria-hidden="true" className="lqd-icn-ess icon-num-1" />
                  </div>
                </div>
                <div className="contents">
                  <h3 className="text-20 mb-15 lqd-iconbox-heading">
                  Comprehensive Tracking{" "}
                  </h3>
                  <p className="text-16 leading-1/6em text-text">
                  One tool, multiple capabilities. From e-commerce data to social media activity, track it all in one intuitive dashboard.
                  </p>
                </div>
              </div>
              <div className="iconbox flex flex-grow-1 relative z-2 iconbox-circle iconbox-icon-linked mb-45 text-start">
                <div className="iconbox-icon-wrap relative mr-20">
                  <div className="iconbox-icon-container relative z-1 text-15 w-50 h-50 text-blue-300 bg-blue-100 rounded-full shadow-lg">
                    <i aria-hidden="true" className="lqd-icn-ess icon-num-2" />
                  </div>
                </div>
                <div className="contents">
                  <h3 className="text-20 mb-15 lqd-iconbox-heading">
                  Real-Time Trend Spotting{" "}
                  </h3>
                  <p className="text-16 leading-1/6em text-text">
                  Trends have a magnetic pull. By identifying and adopting them early, you can dramatically boost user engagement across your social media channels and website.
                  </p>
                </div>
              </div>
              <div className="iconbox flex flex-grow-1 relative z-2 iconbox-circle iconbox-icon-linked mb-45 text-start">
                <div className="iconbox-icon-wrap relative mr-20">
                  <div className="iconbox-icon-container relative z-1 text-15 w-50 h-50 text-blue-300 bg-blue-100 rounded-full shadow-lg">
                    <i aria-hidden="true" className="lqd-icn-ess icon-num-3" />
                  </div>
                </div>
                <div className="contents">
                  <h3 className="text-20 mb-15 lqd-iconbox-heading">
                  Ready to Go Viral? Your First Step Starts Here{" "}
                  </h3>
                  <p className="text-16 leading-1/6em text-text">
                  With AnalyticsHunter, you'll get real time insights into what's trending in your accounts. Use our tool to craft content that not only resonates with your audience but also has the potential to go viral.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
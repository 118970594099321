import { useEffect, useRef } from "react";
import Footer from "../layout/footer";
import Header from "../layout/header";
import CustomCursor from "../layout/customCursor";

function TermsOfService() {
  const body = useRef(null);

  useEffect(() => {
    // List of script sources you want to load
    const scriptSources = ["./js/theme.js"];

    const scripts = scriptSources.map((src) => {
      const script = document.createElement("script");
      script.src = src;
      script.async = true;
      return script;
    });

    if (body.current) {
      scripts.forEach((script) => document.body.appendChild(script));
    }

    return () => {
      scripts.forEach((script) => document.body.removeChild(script));
    };
  }, [body]);

  return (

<div id="wrap" className="bg-white">
<div
  className="titlebar border-bottom border-black-10"
  id="banner"
  style={{
    backgroundImage:
      "linear-gradient(180deg, #FF00C414 0%, #FFFFFF 100%)",
  }}
>
  <div className="lqd-sticky-placeholder hidden" />
  {/* <Header /> */}
  <div className="titlebar-inner">
          <div className="container titlebar-container">
            <div className="row titlebar-container justify-center">
              <div className="col titlebar-col col-12  text-gray-400">
                <h1>Terms Of Service</h1>

                <div className="d-flex justify-content-center my-5">
                  <div className="">
                    <p className="leading-20 lqd-color-accent">
                      Welcome to Analytics Hunter (the 'Website'). By accessing
                      or using the Website, you agree to be bound by these terms
                      of service ('Terms of Service'). If you do not agree to
                      these Terms of Service, do not use the Website.
                    </p>
                    <p className="leading-20 text-gray-400">
                      We may change these Terms of Service from time to time. We
                      encourage you to review the Terms of Service whenever you
                      access the Website to stay informed about our policies and
                      practices.
                    </p>
                    <div className="text-center">
                      <ul
                        className=""
                        style={{ marginTop: "3rem", listStyle: "none" }}
                      >
                        <h6
                          className="font-size-20 font-weight-600 text-dark-gray"
                          style={{ textDecoration: "underline" }}
                        >
                          Table of Contents
                        </h6>
                        <li>Use of the Website</li>
                        <li>Account Registration</li>
                        <li>User Conduct</li>
                        <li>Intellectual Property</li>
                        <li>Disclaimer of Warranties</li>
                        <li>Limitation of Liability</li>
                        <li>Indemnification</li>
                        <li>Governing Law</li>
                        <li>Dispute Resolution</li>
                        <li>Miscellaneous</li>
                      </ul>
                    </div>
                    <h5 className="text-dark-gray">Use of the Website</h5>
                    <p>
                      The Website is a social media analysis platform that
                      allows you to track the performance of your social media
                      accounts, import products from e-commerce websites, and
                      analyze user performance. You may use the Website only for
                      lawful purposes and in accordance with these Terms of
                      Service.
                    </p>
                    <h5 className="font-size-20 mt-4 text-dark-gray">Account Registration</h5>
                    <p className="font-size-15 font-weight-400 ">
                      To access certain features of the Website, you may be
                      required to create an account. When you create an account,
                      you must provide accurate and complete information. You
                      are responsible for maintaining the confidentiality of
                      your account and password, and you agree to accept
                      responsibility for all activities that occur under your
                      account.
                    </p>
                    <p className="font-size-15 font-weight-400 ">
                      If you believe that your account has been compromised, you
                      should contact us immediately. We will not be liable for
                      any loss or damage arising from your failure to comply
                      with this section.
                    </p>
                    <h5 className="font-size-20 mt-4 text-dark-gray">User Conduct</h5>
                    <p className="font-size-15 font-weight-400 ">
                      You agree to use the Website only for lawful purposes and
                      in a way that does not infringe the rights of, or restrict
                      or inhibit the use and enjoyment of, the Website by any
                      third party. This includes complying with all applicable
                      laws and regulations and refraining from using the Website
                      to transmit or distribute any content that is illegal,
                      defamatory, harassing, or otherwise harmful.
                    </p>
                    <p className="font-size-15 font-weight-400 ">
                      You also agree not to use the Website to:
                    </p>
                    <ul className=" mt-3 font-size-15">
                      <li className="  ">
                        Impersonate any person or entity, or falsely state or
                        otherwise misrepresent your affiliation with a person or
                        entity.
                      </li>
                      <li className="  mt-3">
                        Harvest or collect personal information about other
                        users of the Website.
                      </li>
                      <li className="  mt-3">
                        Interfere with or disrupt the Website or servers or
                        networks connected to the Website.
                      </li>
                      <li className="  mt-3">
                        Attempt to gain unauthorized access to the Website or
                        any other computer or network.
                      </li>
                    </ul>
                    <h5 className="font-size-20 mt-4 text-dark-gray">Waiver of Rights</h5>
                    <p className="font-size-15 font-weight-400 ">
                      By using the Website, you waive any rights you may have
                      under applicable consumer protection laws or other similar
                      laws, including the right to request that we disclose the
                      categories of personal information we have collected about
                      you, the categories of sources from which the personal
                      information was collected, the business purposes for
                      collecting or selling the personal information, and the
                      categories of third parties with whom the personal
                      information is shared.
                    </p>
                    <p className="font-size-15 font-weight-400 ">
                      You also waive any rights you may have to request that we
                      delete your personal information, subject to certain
                      exceptions.
                    </p>
                    <p className="font-size-15 font-weight-400 ">
                      You acknowledge that your use of the Website is voluntary
                      and that you are not required to provide any personal
                      information to us.
                    </p>
                    <h5 className="font-size-20 mt-4 text-dark-gray">
                      Intellectual Property
                    </h5>
                    <p className="font-size-15 font-weight-400 ">
                      The Website and its content, including but not limited to
                      text, graphics, images, and software, are the property of
                      Analytics Hunter and are protected by copyright and other
                      intellectual property laws. You may not use any content
                      from the Website for commercial purposes without the
                      express written consent of Analytics Hunter.
                    </p>
                    <h5 className="font-size-20 mt-4 text-dark-gray">
                      Disclaimer of Warranties
                    </h5>
                    <p className="font-size-15 font-weight-400 ">
                      The Website is provided on an 'as is' and 'as available'
                      basis. We make no representations or warranties of any
                      kind, express or implied, as to the operation of the
                      Website or the information, content, materials, or
                      products included on the Website.
                    </p>
                    <p className="font-size-15 font-weight-400 ">
                      We do not warrant that the Website will be uninterrupted
                      or error-free, and we will not be liable for any
                      interruptions or errors.
                    </p>
                    <h5 className="font-size-20 mt-4 text-dark-gray">
                      Limitation of Liability
                    </h5>
                    <p className="font-size-15 font-weight-400 ">
                      We will not be liable for any damages of any kind arising
                      from the use of the Website, including but not limited to
                      direct, indirect, incidental, punitive, and consequential
                      damages.
                    </p>
                    <h5 className="font-size-20 mt-4 text-dark-gray">Indemnification</h5>
                    <p className="font-size-15 font-weight-400 ">
                      You agree to indemnify and hold Analytics Hunter and its
                      affiliates, officers, agents, and employees harmless from
                      any claim or demand, including reasonable attorneys' fees,
                      made by any third party due to or arising out of your use
                      of the Website, your violation of these Terms of Service,
                      or your violation of any rights of another.
                    </p>
                    <h5 className="font-size-20 mt-4 text-dark-gray">Governing Law</h5>
                    <p className="font-size-15 font-weight-400 ">
                      These Terms of Service and your use of the Website will be
                      governed by and construed in accordance with the laws of
                      the state of [State], without giving effect to any
                      principles of conflicts of law.
                    </p>
                    <h5 className="font-size-20 mt-4 text-dark-gray">Termination</h5>
                    <p className="font-size-15 font-weight-400 ">
                      We reserve the right to terminate or suspend your access
                      to the Website at any time, without notice, for any reason
                      or no reason. We will not be liable to you or any third
                      party for any termination or suspension of your access to
                      the Website.
                    </p>
                    <p className="font-size-15 font-weight-400 ">
                      If you violate these terms of service or engage in any
                      conduct that we determine, in our sole discretion, to be
                      inappropriate or in violation of these terms of service,
                      we may terminate or suspend your access to the Website
                      without notice.
                    </p>
                    <p className="font-size-15 font-weight-400 ">
                      We may also terminate or suspend accounts that have been
                      inactive for an extended period of time.
                    </p>
                    <p className="font-size-15 font-weight-400 ">
                      Upon termination of your access to the Website, your right
                      to use the Website will immediately cease.
                    </p>
                    <h5 className="font-size-20 mt-4 text-dark-gray">Community</h5>
                    <p className="font-size-15 font-weight-400 ">
                      At Analytics Hunter, our goal is to provide a platform for
                      users to connect, share, and communicate with others in
                      the social media community. As such, we offer a variety of
                      tools and features through our Service, including access
                      to a community forum where users can post and share
                      content with each other.
                    </p>
                    <p className="font-size-15 font-weight-400 ">
                      As a user of Analytics Hunter, you are responsible for the
                      content that you post or share in the community forum. We
                      do not control, and are not responsible for, any content
                      posted or shared by other users in the forum. We encourage
                      users to only post and share content that is relevant and
                      appropriate for the community, and to respect the rights
                      and opinions of others.
                    </p>
                    <p className="font-size-15 font-weight-400 ">
                      To ensure that the community forum remains a safe and
                      positive space for all users, we do not allow the posting
                      or sharing of any content that :
                    </p>
                    <ul className=" mt-3 font-size-15">
                      <li className="font-size-15 font-weight-400 ">
                        Defames, abuses, harasses, stalks, threatens, or
                        otherwise violates the legal rights of others;
                      </li>
                      <li className="font-size-15 font-weight-400  mt-3">
                        Infringes on the intellectual or proprietary rights of
                        others, or breaches confidentiality obligations;
                      </li>
                      <li className="font-size-15 font-weight-400  mt-3">
                        Contains unsolicited advertising, spam, surveys,
                        contests, chain letters, or any other form of unwanted
                        solicitations;
                      </li>
                      <li className="font-size-15 font-weight-400  mt-3">
                        Encourages conduct that is unlawful or would constitute
                        a criminal offense, give rise to civil liability, or
                        violate any laws or be objectionable or injurious to
                        third parties; or
                      </li>
                      <li className="font-size-15 font-weight-400  mt-3">
                        Violates any code of conduct or other guidelines that
                        may be applicable to the community forum.
                      </li>
                    </ul>
                    <p className="font-size-15 font-weight-400 ">
                      While we do not have an obligation to monitor the
                      community forum, we reserve the right to review any
                      content posted or shared by users and to remove any
                      content that we determine, in our sole discretion, to be
                      inappropriate or in violation of these terms. We also
                      reserve the right to restrict or terminate a user's access
                      to the community forum at any time, without notice, for
                      any reason. Analytics Hunter will not be held responsible
                      or liable for any removal of content or restriction or
                      termination of access to the community forum, or any
                      failure or delay in removing content or restricting or
                      terminating access.
                    </p>
                    <h5 className="font-size-20 mt-4 text-dark-gray">User Content</h5>
                    <p className="font-size-15 font-weight-400 ">
                      You may be able to post or upload content to the Website,
                      such as reviews, ratings, comments, photos, or videos. By
                      posting or uploading content to the Website, you represent
                      and warrant that you have the right to do so and that the
                      content does not infringe the intellectual property rights
                      or violate the privacy rights of any third party.
                    </p>
                    <p className="font-size-15 font-weight-400 ">
                      You are solely responsible for the content that you post
                      or upload to the Website. We do not endorse or assume any
                      liability for any content posted or uploaded by users.
                    </p>
                    <p className="font-size-15 font-weight-400 ">
                      By posting or uploading content to the Website, you grant
                      us a non-exclusive, royalty-free, perpetual, irrevocable,
                      and fully sublicensable right to use, reproduce, modify,
                      adapt, publish, translate, create derivative works from,
                      distribute, and display the content throughout the world
                      in any media.
                    </p>
                    <p className="font-size-15 font-weight-400 ">
                      We reserve the right to remove any content that we
                      determine, in our sole discretion, to be inappropriate or
                      in violation of these terms of service.
                    </p>
                    <h5 className="font-size-20 mt-4 text-dark-gray">Dispute Resolution</h5>
                    <p className="font-size-15 font-weight-400 ">
                      Any dispute arising out of or relating to these Terms of
                      Service or the Website will be resolved through binding
                      arbitration in accordance with the Commercial Arbitration
                      Rules of the American Arbitration Association. The
                      arbitration will be conducted in [City], [State].
                    </p>
                    <h5 className="font-size-20 mt-4 text-dark-gray">Miscellaneous</h5>
                    <p className="font-size-15 font-weight-400 ">
                      These Terms of Service constitute the entire agreement
                      between you and Analytics Hunter and govern your use of
                      the Website. If any provision of these Terms of Service is
                      found to be invalid or unenforceable, that provision will
                      be enforced to the maximum extent possible, and the
                      remaining provisions will remain in full force and effect.
                    </p>
                    <p className="font-size-15 font-weight-400 ">
                      Our failure to enforce any right or provision of these
                      Terms of Service will not be deemed a waiver of those
                      rights. If any provision of these Terms of Service is
                      found to be invalid or unenforceable, that provision will
                      be enforced to the maximum extent possible, and the
                      remaining provisions will remain in full force and effect.
                    </p>
                    <p className="font-size-15 font-weight-400 ">
                      These Terms of Service will inure to the benefit of
                      Analytics Hunter's successors and assigns. You may not
                      assign or transfer these Terms of Service or your rights
                      or obligations under these Terms of Service without the
                      prior written consent of Analytics Hunter.
                    </p>
                    <p className="font-size-15 font-weight-400 ">
                      Any notices or other communications provided by Analytics
                      Hunter under these Terms of Service, including those
                      regarding modifications to these Terms of Service, will be
                      given by posting to the Website or by email. For notices
                      made by e-mail, the date of receipt will be deemed the
                      date on which such notice is transmitted.
                    </p>
                    <div className="my-5 mx-5">
                      <span className="font-size-13 font-weight-500 ">
                        Last updated:{" "}
                      </span>
                      <b className="text-muted ">1/1/2023</b>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
</div>

<main className="content bg-white" id="lqd-site-content" ref={body}>
          {/* <div id="qd-contents-wrap">
    <FeaturesSection />
    <CarousselLG />
    <TabBox />
    <Testimonials />
    <FAQ />
  </div> */}
  <template id="lqd-temp-sticky-header-sentinel">
    <div className="lqd-sticky-sentinel invisible absolute pointer-events-none" />
  </template>
</main>


{/* <CustomCursor />

<Footer /> */}
</div>
  );
}

export default TermsOfService;

export default function FixedNavigation() {
  return (
    <section className="lqd-section fixed-navigation bg-white shadow-top transition-all">
      <div className="container">
        <div className="row">
          <div className="col col-12">
            <div
              className="lqd-fancy-menu lqd-custom-menu lqd-menu-td-none lqd-sticky-menu lqd-sticky-menu-floating bg-white link-black link-14"
              data-inview="true"
              data-inview-options='{ "toggleBehavior":  "toggleInView"}'
            >
              <ul
                className="reset-ul inline-ul"
                data-localscroll="true"
                data-localscroll-options='{"itemsSelector":">li > a", "trackWindowScroll": true, "includeParentAsOffset": true, "offsetElements": "[data-sticky-header] .lqd-head-sec-wrap:not(.lqd-hide-onstuck), body .main-header[data-sticky-header] >section:not(.lqd-hide-onstuck):not(.lqd-stickybar-wrap), body .main-header[data-sticky-header] section:not(.lqd-hide-onstuck):not(.lqd-stickybar-wrap)"}'
              >
                <li>
                  <a href="#features">Features</a>
                </li>
                <li>
                  <a href="#payments">Payments</a>
                </li>
                <li>
                  <a href="./page-start-hub-3-pricing.html">Pricing</a>
                </li>
                <li>
                  <a href="./page-start-hub-3-about.html">About</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}